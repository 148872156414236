import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getRecipientRegister({ payload }) {
  try {
    yield put({ type: constants.RECIPIENT_REGISTER_LOADING, payload: true })
    yield put({ type: constants.RECIPIENT_REGISTER_ERROR, payload: null })
    const res = yield call(api.getRecipientRegister, payload)

    yield put({
      type: constants.RECIPIENT_REGISTER_DATA,
      payload: res,
    })
    yield put({ type: constants.RECIPIENT_REGISTER_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.RECIPIENT_REGISTER_ERROR, payload: error.message })
    yield put({ type: constants.RECIPIENT_REGISTER_LOADING, payload: false })
  }
}

function* updateRecipientRegister({ payload }) {
  try {
    yield put({ type: constants.UPDATE_RECIPIENT_REGISTER_LOADING, payload: true })
    yield put({ type: constants.UPDATE_RECIPIENT_REGISTER_ERROR, payload: null })
    const res = yield call(api.updateRecipientRegister, payload)
    yield put({
      type: constants.UPDATE_RECIPIENT_REGISTER_DATA,
      payload: res,
    })
    yield put({ type: constants.UPDATE_RECIPIENT_REGISTER_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.UPDATE_RECIPIENT_REGISTER_ERROR, payload: error.message })
    yield put({ type: constants.UPDATE_RECIPIENT_REGISTER_LOADING, payload: false })
  }
}

function* searchZipCodeRegister({ payload }) {
  try {
    yield put({ type: constants.SEARCH_ZIPCODE_REGISTER_LOADING, payload: true })
    yield put({ type: constants.SEARCH_ZIPCODE_REGISTER_ERROR, payload: null })
    const res = yield call(api.searchZipCodeRegister, payload)
    yield put({
      type: constants.SEARCH_ZIPCODE_REGISTER_DATA,
      payload: res,
    })
    yield put({ type: constants.SEARCH_ZIPCODE_REGISTER_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.SEARCH_ZIPCODE_REGISTER_ERROR, payload: error.message })
    yield put({ type: constants.SEARCH_ZIPCODE_REGISTER_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_RECIPIENT_REGISTER_DATA, getRecipientRegister)
  yield takeLatest(constants.GET_UPDATE_RECIPIENT_REGISTER_DATA, updateRecipientRegister)
  yield takeLatest(constants.GET_SEARCH_ZIPCODE_REGISTER_DATA, searchZipCodeRegister)
}
