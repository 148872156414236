import {
    ATOS_LOGIN_DATA,
    ATOS_LOGIN_LOADING,
    ATOS_LOGIN_ERROR,
} from 'store/constants'

export const initialState = {
    atosLoginData: {},
    atosLoginError: false,
    atosLoginLoading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case ATOS_LOGIN_DATA:
            return { ...state, atosLoginData: action.payload.applications.atosUsers.atosUsersResult.dados[0] }
        case ATOS_LOGIN_LOADING:
            return { ...state, atosLoginLoading: action.payload }
        case ATOS_LOGIN_ERROR:
            return { ...state, atosLoginError: action.payload }
        default:
            return state
    }
}