export default `
fragment ParagraphDownloadList on ParagraphDownloadList {
  id
  uuid
  entityBundle
  title
  grouped
  viewPdf
  description {
    value
  }
  theme {
    entity {
      name
      classes
    }
  }  
  downloads {
    entity {
      ... on NodeDownload {
        title
        created
        url {
          path
        }
        media {
          entity {
            ...Media
          }
        }
        absoluteLink {
            url {
              path
            }
        }        
      }
    }
  }  
}
`
