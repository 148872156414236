import {
    GET_BILLING_REPORT,
    BILLING_REPORT_LOADING,
    BILLING_REPORT_DATA,
    BILLING_REPORT_ERROR,
    ORDER_BILLING_REPORT
} from 'store/constants'

export const initialState = {
    billingReportData: {},
    billingReportError: false,
    orderBillingReport: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BILLING_REPORT:
            return { ...state, billingReportData: action.payload }
        case BILLING_REPORT_DATA:
            return { ...state, billingReportData: action.payload }
        case ORDER_BILLING_REPORT:
            return { ...state, orderBillingReport: action.payload }
        case BILLING_REPORT_LOADING:
            return { ...state, loading: action.payload }
        case BILLING_REPORT_ERROR:
            return { ...state, billingReportError: action.payload }
        default:
            return state
    }
}