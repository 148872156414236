import React, { useState } from 'react'
import propTypes from 'prop-types'
import RedirectImg from 'assets/img/redirect.png'
import Form from 'react-bootstrap/Form'
import Typography from 'components/atoms/Typography'

import './ExternalLinkRedirect.scss'

function ExternalLinkRedirect(props) {
  const [dontShowAgain, setDontShowAgain] = useState(false)

  const handleChange = () => {
    setDontShowAgain(!dontShowAgain)
    props.dontShowAgain(!dontShowAgain)
  }

  return (
    <div className="external-link-redirect">
      <div className="external-link-redirect__container d-md-flex flex-column align-items-center">
        <img
          className="external-link-redirect__image d-flex m-auto"
          src={RedirectImg}
          alt="Imagem de representação do redirecionamento para um site externo"
        />
        <Typography color="gray-100" cssClass="external-link-redirect__text" variant="span">
          <strong>Você está sendo redirecionado</strong> para uma página externa do site da Unimed Curitiba.
        </Typography>
        <Form.Check
          type="checkbox"
          label="Não mostrar isso novamente"
          value={dontShowAgain}
          checked={dontShowAgain}
          onChange={handleChange}
          aria-label="Não mostrar isso novamente"
        />
      </div>
    </div>
  )
}

ExternalLinkRedirect.propTypes = {
  dontShowAgain: propTypes.func,
}

export default ExternalLinkRedirect
