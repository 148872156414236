import paragraphLibraryItemFragment from "./paragraphLibraryItemFragment";

export default `
fragment ParagraphFromLibrary on ParagraphFromLibrary {
    uuid
    entityBundle
    entities {        
        entity {
        ...ParagraphLibraryItem
        }
    } 
}
${paragraphLibraryItemFragment}
`