import apiClient from 'api/config/apiClient'

export default {
  getReferencedList(payload) {
    return apiClient.request('cache-disable/referenced_list', payload)
  },
  approveReferencedList(payload) {
    return apiClient.request('cache-disable/approve_referenced_list', payload)
  },
}
