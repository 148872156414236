export default `{
    applications {
        civilAction {
            civilActionsList {
                civil_action {
                        code
                        name
                        address
                        number
                        neighborhood
                        city
                        state
                        bank
                        agency
                        account
                        account_type
                        created_at
                    }
                }
            }
        }
    }`

