import React from "react"
import Breadcrumb from 'components/atoms/Breadcrumb/Breadcrumb'

function Breadcrumbs(props) {
  return props.items.items.map((item, index) => (
    <Breadcrumb
      page={item.page}
      url={item.url}
      key={index}
      color={props.breadColor}
    />
  ))
}

export default Breadcrumbs
