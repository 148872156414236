export default `
fragment ParagraphPhoneTag on ParagraphPhoneTag {
  id
  uuid
  entityBundle
  text
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
  description {
    value
    format
  }
}
`