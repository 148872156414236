export default `
  fragment ParagraphApp on ParagraphApp {
    uuid
    entityBundle
    title
    description {
        value
    }
    application {
      entity {
        tid
        name
        component
      }
    }
  }
`
