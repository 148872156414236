import apiClient from 'api/config/apiClient'
import http from 'http'
import https from'https'

const agents = {
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true })
}

export default {
  generateDocsCopan(payload) {
    return apiClient.request('cache-disable/copan', payload, agents)
  },
  getReportLogs(payload) {
    return apiClient.request('cache-disable/get_report_logs', payload, agents)
  },
}
