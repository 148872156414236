import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import LinkHandler from 'components/atoms/LinkHandler'
import './CardIconLink.scss'

function CardIconLink({ icon, text, url }) {
  return (
    <div className="card-icon-link">
      <SVG src={icon} preProcessor={(code) => code.replace(/filter=".*?"/g, '')} />
      <LinkHandler className="card-icon-link__action" to={url}>
        {text}
      </LinkHandler>
    </div>
  )
}

CardIconLink.propTypes = {
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default CardIconLink
