export default `
fragment ParagraphCronogramaPrestador on ParagraphCronogramaPrestador {
  entityBundle
  id
  uuid
  title
  data {
    date
    value
  }
  documentos {
    entity {
      ...Media
    }
  }
}
`
