import { gql } from 'graphql-request'

export default gql`
  query customApplications(
    $cityCode: Int
    $specialtyCode: Int
    $specialtyType: String
    $providerName: String
    $addressNeighborhood: String
    $page: Int
  ) {
    applications {
      medicalSchedule {
        schedulesList(
          cityCode: $cityCode
          specialtyCode: $specialtyCode
          specialtyType: $specialtyType
          addressNeighborhood: $addressNeighborhood
          providerName: $providerName
          page: $page
        ) {
          total_rows
          page_size
          current_page
          last_page
          last_page
          page_rows
          schedules {
            date
            provider_name
            address_street
            address_number
            address_complement
            address_neighborhood
            address_ddd
            address_phone_1
            address_phone_2
            city_code
            city_name
          }
        }
      }
    }
  }
`
