import {
  GET_OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT,
  OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING,
  OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT_DATA,
  OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR
} from 'store/constants'

export const initialState = {
  outpatientAuthorizationConsultationProviderResultData: {},
  outpatientAuthorizationConsultationProviderResultError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
      case GET_OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT:
          return { ...state, outpatientAuthorizationConsultationProviderResultData: action.payload }
      case OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT_DATA:
          return { ...state, outpatientAuthorizationConsultationProviderResultData: action.payload }
      case OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING:
          return { ...state, loading: action.payload }
      case OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR:
          return { ...state, outpatientAuthorizationConsultationProviderResultError: action.payload }
      default:
          return state
  }
}