export default `
  fragment ParagraphInformationGrid on ParagraphInformationGrid {
    id
    entityBundle
    sectionTitle
    title
    description {
      value
    }
    colorPalette {
      entity {
        name
        colors {
          color
        }
      }
    }
    theme {
      entity {
        name
        classes
      }
    }    
    items {
      entity {
        ...ParagraphInformationCard
      }
    }
    button {
      entity {
        ...ParagraphButton
      }
    }
  }
`