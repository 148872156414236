import { gql } from 'graphql-request'

export default gql`
  query getRecipientsList($username: String!) {
    applications {
      pinSSData {
        recipientsList(username: $username) {
          data {
            code
            name
            description
            handle
          }
        }
      }
    }
  }
`
