export default `
fragment ParagraphHalfBanner on ParagraphHalfBanner {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  icon {
    entity {
      media {
        entity {
          ...Media
        }
      }
    }
  }
  buttons {
    entity {
      ...ParagraphButton
    }
  }
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  imageBackground {
    entity {
      ...Media
    }
  }
}
`