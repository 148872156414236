import React from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

// Style
import './Breadcrumb.scss'

function Breadcrumb({ url, page, color }) {
  const location = useLocation()

  function sameUrl() {
    if (url === location.pathname) {
      location.reload()
    }
  }

  return (
    <p className={`Breadcrumb bread-${color}`}>
      <HashLink to={url} onClick={sameUrl}>
        {page}
      </HashLink>
    </p>
  )
}

export default Breadcrumb
