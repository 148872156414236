import { gql } from 'graphql-request'

export default gql`
query getReadjustmentStatement($contract: String, $st: String) {
    applications {
    readjustmentExtract {
      readjustmentExtractList (contract: $contract, st: $st) {
        extracts {
          file
          file_extension
          contract
          year
        }
      }
    }
  }
}`

