import { gql } from 'graphql-request'

export default gql`
  query {
    applications {
      medicalSchedule {
        citiesList(name: "") {
          cities {
            code
            name
          }
        }
      }
    }
  }
`
