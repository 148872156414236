import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { forceCheck } from 'react-lazyload'
import { Container, Row, Col } from 'react-bootstrap'
import Image from 'components/atoms/Image'
import Typography from 'components/atoms/Typography'
import NavDesktopList from 'components/molecules/NavDesktopList'
import ModalLoggedUser from 'components/molecules/ModalLoggedUser'
import FormSearch from 'components/molecules/FormSearch'
import { get } from 'lodash'
import { checkKeyPress } from 'helpers/tools'

import logo from 'assets/img/logotipo-unimed-curitiba.png'
import image1 from 'assets/img/examples/laboratorios.jpg'
import image2 from 'assets/img/examples/doutora.jpg'
import image3 from 'assets/img/examples/mulher-e-crianca.jpg'
import image4 from 'assets/img/examples/seguros.jpg'
import image5 from 'assets/img/examples/sou.jpg'

function NavDesktop({ links, logged }) {
  const [active, setActive] = useState('')
  const [levelOne, setLevelOne] = useState('')
  const [levelTwo, setLevelTwo] = useState('')
  const [login, setLogin] = useState(false)
  const history = useHistory()
  const searchInputRef = useRef(null)
  const [hasOutside, setHasOutside] = useState(false)

  const searchPress = (e) => checkKeyPress(e, 51, searchInputRef, () => handleClick('form-search'))
  const closeSearch = (e) => e.key === 'Escape' && setActive('')

  const { user: session } = useSelector((state) => state.auth)

  useEffect(() => {
    window.addEventListener('keydown', searchPress)
    window.addEventListener('keydown', closeSearch)
    return () => {
      window.removeEventListener('keydown', searchPress)
      window.removeEventListener('keydown', closeSearch)
    }
  }, [])

  useEffect(() => {
    forceCheck()
    const body = document.querySelector('body')
    if (active === '') {
      body.style.overflowY = 'auto'
    } else {
      body.scrollTo(0, 0)
      body.style.overflowY = 'hidden'
    }
  }, [active])

  const handleClick = (id) => {
    setActive(id === active ? '' : id)
    setLogin(false)

    if (id !== active) {
      setLevelOne(null)
      setLevelTwo(null)
    }
  }

  const handleLoginClick = () => {
    if (!login && !session) {
      if (location.pathname == '/login') {
        history.go(0)
      } else {
        history.push('/login')
      }
    } else {
      setActive('')
      setLogin(!login)
    }
  }
  const handleOutSideMenuUser = () => {
    setHasOutside(true)
    setLogin(!login)
    setTimeout(() => {
      setHasOutside(false)
    }, 500)
  }

  const _getImage = (index) => {
    switch (index) {
      case 0:
        return image1
      case 1:
        return image2
      case 2:
        return image3
      case 3:
        return image4
      case 4:
        return image5
      default:
        return image1
    }
  }

  return (
    <section className={classnames('navbar d-none d-lg-flex', { 'navbar--logged': logged })}>
      <nav id="mainmenu" className="container" aria-label="Menu de navegação">
        <ul className="navbar-items">
          <li className="mr-3">
            <HashLink data_ga_name="mainmenu-logo" data_ga_action="go-to-home" to="/">
              <img src={logo} alt="Unimed curitiba" className="navbar-img" />
            </HashLink>
          </li>

          {Array.isArray(links) &&
            links.map((item, index) => (
              <li key={`${get(item, 'label')}-${index}`}>
                <button
                  id={get(item, 'id')}
                  key={`${get(item, 'label')}-${index}`}
                  className={`navbar-links ${get(item, 'label') === active ? 'active' : ''}`}
                  onClick={() => handleClick(get(item, 'label'))}
                >
                  {get(item, 'label')}
                </button>

                <div className="navbar-overlay" onClick={() => setActive('')} />

                <nav aria-label="Opções do menu" className="navbar-dropdown">
                  {!logged ? (
                    <>
                      <div className="navbar-dropdown-info">
                        <Image
                          src={get(item, 'media.entity.images.medium.url') || _getImage(index)}
                          alt="vantagens"
                          shadow={false}
                          roundedCorners={true}
                        />
                        <Typography variant="h5" color="white">
                          {get(item, 'label')}
                        </Typography>
                        <Typography variant="p" color="white">
                          {get(item, 'description')}
                        </Typography>
                      </div>

                      {item && get(item, 'links') && (
                        <NavDesktopList
                          idMenu={get(item, 'label')}
                          idMenuActive={active}
                          links={get(item, 'links')}
                          cssClass="navbar-dropdown-list-one"
                          onItemClick={(item) => {
                            setLevelOne(item)
                            setLevelTwo(null)
                            if (!get(item, 'links')?.length) setActive('')
                          }}
                        />
                      )}

                      {levelOne && Array.isArray(get(levelOne, 'links')) && get(levelOne, 'links').length > 0 && (
                        <NavDesktopList
                          idMenu={get(item, 'label')}
                          idMenuActive={active}
                          links={get(levelOne, 'links')}
                          cssClass="navbar-dropdown-list-two"
                          onItemClick={(item) => {
                            setActive('')
                            setLevelTwo(item)
                          }}
                        />
                      )}

                      {levelTwo && Array.isArray(get(levelTwo, 'links')) && get(levelTwo, 'links').length > 0 && (
                        <NavDesktopList
                          idMenu={get(item, 'label')}
                          idMenuActive={active}
                          links={get(levelTwo, 'links')}
                          cssClass="navbar-dropdown-list-three"
                        />
                      )}
                    </>
                  ) : (
                    <Container>
                      <Row>
                        <Col lg={3}>
                          {item && get(item, 'links') && (
                            <div className="navbar-dropdown__main">
                              <div className="navbar-dropdown__main-bg" />
                              <NavDesktopList
                                idMenu={get(item, 'label')}
                                idMenuActive={active}
                                links={get(item, 'links')}
                                cssClass="navbar-dropdown-list-one"
                                onItemClick={(item) => {
                                  setLevelOne(item)
                                  setLevelTwo(null)
                                  if (!get(item, 'links')?.length) setActive('')
                                }}
                              />
                            </div>
                          )}
                        </Col>

                        <Col lg={9}>
                          {levelOne && get(levelOne, 'links') && (
                            <div className="navbar-dropdown__second-level">
                              <NavDesktopList
                                idMenu={get(item, 'label')}
                                idMenuActive={active}
                                links={get(levelOne, 'links')}
                                cssClass="navbar-dropdown-list-two"
                                showAllItems
                                onItemClick={(item) => {
                                  setActive('')
                                  setLevelTwo(item)
                                }}
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  )}
                </nav>
              </li>
            ))}

          <li>
            <button
              disabled={hasOutside}
              className={classnames('navbar-login ml-2', { active: login, 'no-text': logged || get(session, 'name') })}
              onClick={handleLoginClick}
            >
              <i className={`icone-${login ? 'close-book' : 'person'}`} role="presentation" />
              {!get(session, 'name') && <span>Entrar</span>}
            </button>

            <div className="navbar-overlay" onClick={() => setActive('')} />

            {session && <ModalLoggedUser active={login} user={session} handleOutSideMenuUser={handleOutSideMenuUser} />}
          </li>

          <li>
            <button
              className={classnames('navbar-search', { active: 'form-search' === active })}
              onClick={() => handleClick('form-search')}
              aria-label="Procurar tópico"
            >
              <i className="icone-search" role="presentation" />
            </button>

            <div className="navbar-overlay" onClick={() => setActive('')} />

            <nav aria-label="Menu de Navegacao" className="navbar-dropdown navbar-dropdown--search">
              <div className="navbar-dropdown-info">
                <Container>
                  <div className="navbar-search-container mx-lg-7">
                    <FormSearch
                      active={(val) => (!val ? setActive('') : '')}
                      inputRef={searchInputRef}
                      placeholder="Procurar por..."
                    />
                  </div>
                </Container>
              </div>
            </nav>
          </li>
        </ul>
      </nav>
    </section>
  )
}

NavDesktop.propTypes = {
  links: PropTypes.array,
}

export default NavDesktop
