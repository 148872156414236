import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getOutpatientAuthorizationsProviderResult({ payload }) {
    try {
        yield put({ type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING, payload: true })

        const res = yield call(api.getOutpatientAuthorizationsProviderResult, payload)

        yield put({
            type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT_DATA,
            payload: res,
        })

        yield put({ type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING, payload: false })
        yield put({ type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_ERROR, payload: e.message })
        yield put({ type: constants.OUTPATIENT_AUTHORIZATION_PROVIDER_LOADING, payload: false })

    }
}

export default function* saga() {
    yield takeLatest(constants.GET_OUTPATIENT_AUTHORIZATION_PROVIDER_RESULT, getOutpatientAuthorizationsProviderResult)
}