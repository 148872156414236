import { gql } from 'graphql-request'

export default gql`
  query getZipCodeList($zipcode: String!) {
    applications {
      cardUnlock {
        zipcodeList(zipcode: $zipcode) {
          addresses {
            street
            locale
            neighborhood
            state
          }
        }
      }
    }
  }
`
