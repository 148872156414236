import React from 'react'
import FontControl from 'components/molecules/FontControl'
import ContrastControl from 'components/molecules/ContrastControl'
import Helmet from 'react-helmet'

import AccessibilityStyle from './Accessibility.scss'

function Accessibility() {
  return (
    <nav aria-label="Menu de acessibilidade" id="accessibility_bar" className="Accessibility accessibility-menu">
      <Helmet>
        <style type="text/css">{AccessibilityStyle.toString()}</style>
      </Helmet>
      <ul>
        <FontControl />
        <ContrastControl />
      </ul>
    </nav>
  )
}

export default Accessibility
