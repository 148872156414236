import dataCardFragment from 'api/queries/fragments/dataCardFragment'

export default `
fragment ParagraphDataCards on ParagraphDataCards {
  id
  uuid
  entityBundle  
  title
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }
  items {
    entity {
      ...ParagraphDataCard
    }
  }
}

${dataCardFragment}
`