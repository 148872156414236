import React, { useEffect, useState, useContext } from 'react'
import Typography from 'components/atoms/Typography'
import SVG from 'react-inlinesvg'
import sadSmile from 'assets/img/sad-smile-chat-box.svg'
import { Base64 } from 'js-base64'
import { Button } from 'react-bootstrap'
import * as authActions from 'store/modules/auth/actions'
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner'
import './AccessSimulation.scss'
import { getMenuName } from 'helpers/auth'
import { profileNamesForLoggedArea } from 'helpers/tools'
import { getApplicationMenus } from 'store/modules/customPage/actions'
import isEqual from 'lodash.isequal'
import { isEmpty } from 'lodash'
import axios from 'axios'

function AccessSimulation({ data, changeStep }) {
  const location = useLocation()
  const token = new URLSearchParams(location.search).get('token')
  const decodedToken = JSON.parse(Base64.decode(token))
  const user = useSelector((state) => state?.auth?.user)
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const history = useHistory()

  const [errorMessage, setErrorMessage] = useState('')

  let menus = useSelector((state) => state.customPage.applicationMenus, isEqual)
  let session = useSelector((state) => state.auth.user, isEqual)

  useEffect(async () => {
    if (!user) {
      setErrorMessage('Você precisa estar logado com a sua conta, para poder simular um acesso.')
    } else {
      if (decodedToken.active) {
        const newAuth = {
          ...user,
          contract: decodedToken.nickname,
          profile_id: decodedToken.id,
          name: decodedToken.name,
          roles: ['authenticated', decodedToken.profile, decodedToken.userProfile],
          crm: decodedToken.crm,
          st: decodedToken.st,
          original_user: decodedToken.original_user,
          menu: decodedToken.userProfile,
          active: true,
          active_benner: true,
          id: decodedToken.user_id,
        }

        const simulatedMenu = (
            newAuth?.profile === 'web_prestador_autorizado' ||
            (newAuth?.menu === 'prestador' && newAuth?.roles?.includes('web_prestador_autorizado'))
          ) ? 
          'webprestadorautorizado' : newAuth.menu

        const updatedNewAuth = { ...newAuth, menu: simulatedMenu }

        // Clear old reports
        localStorage.removeItem('reportsBeingGeneratedCopan')
        localStorage.removeItem('reportsBeingGenerated')

        const res = await axios.post('/set_cookie', {
          name: 'auth',
          value: updatedNewAuth,
        })

        dispatch(authActions.setUser(updatedNewAuth))

        const menuName = getMenuName(updatedNewAuth)
        dispatch(getApplicationMenus({ menu: menuName, store }))
      } else {
        setErrorMessage('O usuário que você tentou simular está inativo.')
      }
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(menus) && session?.profile_id == decodedToken?.id) {
      const profile = menus.menuQuery.entity.name
      history.push(`/app/home-${profileNamesForLoggedArea(profile)}`)
    }
  }, [menus, session])

  return (
    <section className="access-simulation">
      <div className="content">
        <div className="login-header">
          <Typography variant="p" cssClass="title">
            Simulação de acesso
          </Typography>
        </div>
        {!errorMessage && (
          <div className="text-center py-3">
            <Spinner size="md" className="ml-1" animation="border" />
          </div>
        )}
        {errorMessage && (
          <>
            <div className="access-simulation-body">
              <SVG
                className="icon"
                src={sadSmile}
                preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                width="100%"
                height="100%"
              />
              <p className="text mb-4">{errorMessage}</p>

              <div className="cta">
                <Button
                  type="button"
                  variant="primary"
                  size="lg"
                  className="login mx-2"
                  onClick={() => {
                    changeStep('LoginForm')
                  }}
                >
                  <span>Login</span>
                  <span>
                    <i className="icone-arrow-right" />
                  </span>
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default AccessSimulation
