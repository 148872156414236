import tagBannerFragment from 'api/queries/fragments/tagBannerFragment'

export default `
fragment ParagraphTagBanners on ParagraphTagBanners {
  id
  uuid
  title
  entityBundle
  link {
    title
    url {
      path
    }
  }
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  items {
    entity {
      ...ParagraphTagBanner
    }
  }
}

${tagBannerFragment}
`