import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'
import { isObject } from 'lodash'

function* setUserProfile({ payload }) {
  try {
    yield put({ type: constants.LOADING_USER, payload: true })
    const res = yield call(api.setUserProfile, payload)
    yield put({ type: constants.LOADING_USER, payload: false })
    yield put({ type: constants.RESULT_USER, payload: res.setUserProfile })
  } catch (e) {
    const error = isObject(e) ? e : JSON.parse(JSON.stringify(e, undefined, 2))?.response?.errors || e.toString()
    yield put({ type: constants.GET_USER_ERROR, payload: error })
    yield put({ type: constants.LOADING_USER, payload: false })
    console.error(error)
  }
}

function* getUser({ payload }) {
  try {
    if (payload === null) {
      yield put({ type: constants.RESULT_USER, payload: null })
    } else {
      yield put({ type: constants.LOADING_USER, payload: true })
      const res = yield call(api.getUser, payload)
      yield put({ type: constants.LOADING_USER, payload: false })
      yield put({ type: constants.RESULT_USER, payload: res.user })
      yield put({ type: constants.GET_USER_ERROR, payload: false })
    }
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))?.response?.errors || e.toString()
    yield put({ type: constants.GET_USER_ERROR, payload: error })
    yield put({ type: constants.LOADING_USER, payload: false })
    console.error(error)
  }
}

function* setUser({ payload }) {
  try {
    yield put({ type: constants.RESULT_USER, payload: payload })
  } catch (e) {
    yield put({ type: constants.GET_TOKEN_ERROR, payload: e.toString() })
    console.error(e)
  }
}

function* setIpAddress({ payload }) {
  try {
    yield put({ type: constants.RESULT_IP, payload: payload })
  } catch (e) {
    console.error(e)
  }
}
function* getIpAddress({ payload }) {
  try {
    const res = yield call(api.getIpAddress, payload)
    yield put({ type: constants.RESULT_IP, payload: res.ip })
  } catch (e) {
    console.error(e)
  }
}

export default function* saga() {
  yield takeLatest(constants.SET_USER, setUserProfile)
  yield takeLatest(constants.GET_USER, getUser)
  yield takeLatest(constants.SET_USER_DATA, setUser)
  yield takeLatest(constants.SET_IP, setIpAddress)
  yield takeLatest(constants.GET_IP, getIpAddress)
}
