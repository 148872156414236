export default `
fragment ParagraphLinkWithTitleAndSubtitle on ParagraphLinkWithTitleAndSubtitle {
  id
  uuid
  entityBundle
  title
  subtitle
  link {
    url {
      path
    }
  }
  image {
    entity {
      ...Media
    }
  }
}
`