import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getCards({ payload }) {
  try {
    yield put({ type: constants.CARDS_LOADING, payload: true })
    yield put({ type: constants.CARDS_ERROR, payload: null })
    const res = yield call(api.getCards, payload)
    yield put({
      type: constants.CARDS_DATA,
      payload: res,
    })
    yield put({ type: constants.CARDS_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.CARDS_ERROR, payload: error.message })
    yield put({ type: constants.CARDS_LOADING, payload: false })
  }
}

function* getCardRecipient({ payload }) {
  try {
    yield put({ type: constants.CARD_RECIPIENT_LOADING, payload: true })
    yield put({ type: constants.CARD_RECIPIENT_ERROR, payload: null })
    const res = yield call(api.getCardRecipient, payload)
    yield put({
      type: constants.CARD_RECIPIENT_DATA,
      payload: res,
    })
    yield put({ type: constants.CARD_RECIPIENT_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.CARD_RECIPIENT_ERROR, payload: error.message })
    yield put({ type: constants.CARD_RECIPIENT_LOADING, payload: false })
  }
}

function* updateCardRecipient({ payload }) {
  try {
    yield put({ type: constants.UPDATE_CARD_RECIPIENT_LOADING, payload: true })
    yield put({ type: constants.UPDATE_CARD_RECIPIENT_ERROR, payload: null })
    const res = yield call(api.updateCardRecipient, payload)
    yield put({
      type: constants.UPDATE_CARD_RECIPIENT_DATA,
      payload: res,
    })
    yield put({ type: constants.UPDATE_CARD_RECIPIENT_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.UPDATE_CARD_RECIPIENT_ERROR, payload: error.message })
    yield put({ type: constants.UPDATE_CARD_RECIPIENT_LOADING, payload: false })
  }
}

function* searchZipCode({ payload }) {
  try {
    yield put({ type: constants.SEARCH_ZIPCODE_LOADING, payload: true })
    yield put({ type: constants.SEARCH_ZIPCODE_ERROR, payload: null })
    const res = yield call(api.searchZipCode, payload)
    yield put({
      type: constants.SEARCH_ZIPCODE_DATA,
      payload: res,
    })
    yield put({ type: constants.SEARCH_ZIPCODE_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.SEARCH_ZIPCODE_ERROR, payload: error.message })
    yield put({ type: constants.SEARCH_ZIPCODE_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_CARDS_DATA, getCards)
  yield takeLatest(constants.GET_CARD_RECIPIENT_DATA, getCardRecipient)
  yield takeLatest(constants.GET_UPDATE_CARD_RECIPIENT_DATA, updateCardRecipient)
  yield takeLatest(constants.GET_SEARCH_ZIPCODE_DATA, searchZipCode)
}
