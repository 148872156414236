import {
  GET_DATA,
  GET_HEADER_MENUS,
  GET_APPLICATION_MENUS,
  GET_FOOTER_MENUS,
  GET_FOOTER_SUB_MENUS,
  GET_ACCESS_MENUS,
  GET_PLANS,
  GET_PLAN_FILTERS,
  FETCH_SEARCH,
  SEARCH_AUTOCOMPLETE,
  GET_FOOTER_DATA,
} from 'store/constants'

export function getData(values) {
  return {
    type: GET_DATA,
    payload: values,
  }
}

export function getHeaderMenus(values) {
  return {
    type: GET_HEADER_MENUS,
    payload: values,
  }
}

export function getApplicationMenus(values) {
  return {
    type: GET_APPLICATION_MENUS,
    payload: values,
  }
}

export function getFooterMenus(values) {
  return {
    type: GET_FOOTER_MENUS,
    payload: values,
  }
}

export function getFooterSubMenus(values) {
  return {
    type: GET_FOOTER_SUB_MENUS,
    payload: values,
  }
}

export function getAccessMenus(values) {
  return {
    type: GET_ACCESS_MENUS,
    payload: values,
  }
}

export function getPlans(values) {
  return {
    type: GET_PLANS,
    payload: values,
  }
}

export function getPlanFilters(values) {
  return {
    type: GET_PLAN_FILTERS,
    payload: values,
  }
}

export function search(values) {
  return {
    type: FETCH_SEARCH,
    payload: values,
  }
}

export function searchAutocomplete(values) {
  return {
    type: SEARCH_AUTOCOMPLETE,
    payload: values,
  }
}

export function getFooterData() {
  return {
    type: GET_FOOTER_DATA,
  }
}
