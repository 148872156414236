export default `
fragment ParagraphVideo on ParagraphVideo {
  id
  uuid
  entityBundle
  title
  thumb {
    entity {
      ...Media
    }
  }
  media {
    entity {
      ...Media
    }
  }
}

`