import {
  CITIES_DATA,
  CITIES_LOADING,
  CITIES_ERROR,
  SPECIALTIES_DATA,
  SPECIALTIES_LOADING,
  SPECIALTIES_ERROR,
  MEDICAL_SCHEDULE_DATA,
  MEDICAL_SCHEDULE_LOADING,
  MEDICAL_SCHEDULE_ERROR,
} from 'store/constants'

export const initialState = {
  cities: {
    list: [],
    errors: null,
    loading: false,
  },
  specialties: {
    list: [],
    errors: null,
    loading: false,
  },
  schedule: {
    data: {},
    errors: null,
    loading: false,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CITIES_DATA:
      const citiesList = action.payload.applications.medicalSchedule.citiesList.cities
      return { ...state, cities: { ...state.cities, list: citiesList } }
    case CITIES_ERROR:
      return { ...state, cities: { ...state.cities, error: action.payload } }
    case CITIES_LOADING:
      return { ...state, cities: { ...state.cities, loading: action.payload } }

    case SPECIALTIES_DATA:
      const specialtiesList = action.payload.applications.medicalSchedule.specialtiesList.specialties
      return { ...state, specialties: { ...state.specialties, list: specialtiesList } }
    case SPECIALTIES_ERROR:
      return { ...state, specialties: { ...state.specialties, error: action.payload } }
    case SPECIALTIES_LOADING:
      return { ...state, specialties: { ...state.specialties, loading: action.payload } }

    case MEDICAL_SCHEDULE_DATA:
      const medicalScheduleData = action.payload.applications.medicalSchedule.schedulesList
      return { ...state, schedule: { ...state.schedule, data: medicalScheduleData } }
    case MEDICAL_SCHEDULE_ERROR:
      return { ...state, schedule: { ...state.schedule, error: action.payload } }
    case MEDICAL_SCHEDULE_LOADING:
      return { ...state, schedule: { ...state.schedule, loading: action.payload } }
    default:
      return state
  }
}
