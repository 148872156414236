import React from 'react'
import LinkHandler from 'components/atoms/LinkHandler'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import { startsWithHttp, toUpperCase } from 'helpers/strings'

// Style
import './BigButton.scss'

function BigButton({ id, className, fullWidth, footer, title, theme, url, icon, onClick, onCloseRedirectModal }) {
  return (
    <LinkHandler
      className="BigButton"
      id={id}
      onClick={onClick}
      onCloseModal={onCloseRedirectModal}
      to={url}
      title={toUpperCase(title)}
    >
      <div className={`card card-icon card-${theme} ${className} ${fullWidth && 'full-width'} max-line-2`}>
        <SVG src={icon} className="card-icon-img" preProcessor={(code) => code.replace(/filter=".*?"/g, '')} />
        <p className="card-icon-text">{title}</p>
        {footer && (
          <i
            className={startsWithHttp(url) ? 'icone-diagonal-arrow-right-up' : 'icone-arrow-right'}
            role="presentation"
          />
        )}
      </div>
    </LinkHandler>
  )
}

BigButton.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  footer: PropTypes.bool,
  icon: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  theme: PropTypes.string,
}

export default BigButton
