export default `
  fragment ParagraphLoginBox on ParagraphLoginBox {
    uuid
    entityBundle
    title
    media {
      entity {
        ...Media
      }
    }
  }
`
