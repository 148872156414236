import apiClient from 'api/config/apiClient'

export default {
  getRecipientDetails(payload) {
    return apiClient.request('recipient_details', payload)
  },
  getRecipientsList(payload) {
    return apiClient.request('recipient_list', payload)
  },
}
