import React from 'react'
import { CookiesProvider } from 'react-cookie'
import { hydrate } from 'react-dom'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import configureStore from 'store/configureStore'
import { loadableReady } from '@loadable/component'

const root = document.getElementById('root')
window.main = () => {
  render(App)
}

if (module.hot) {
  module.hot.accept('./App', () => {
    const NewApp = require('./App').default
    render(NewApp)
  })
}

function render(Root) {
  const store = configureStore(window.__PRELOADED_STATE__)

  loadableReady().then(() => {
    hydrate(
      <CookiesProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </CookiesProvider>,
      root
    )
  })
}
