export default `
fragment ParagraphMiniBanner on ParagraphMiniBanner {
  id
  uuid
  entityBundle
  sectionTitle
  title
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  fieldLink {
    entity {
      ...ParagraphButton
    }
  }
  media {
    entity {
      ...Media
    }
  }
  hide_mobile_image
  imageAlign
  description {
    value
  }
}
`