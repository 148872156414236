import {
  GET_BILLING_REPORT_FINANCIAL_RESPONSIBLE,
  BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING,
  BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA,
  BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR,
  ORDER_BILLING_REPORT_FINANCIAL_RESPONSIBLE
} from 'store/constants'

export const initialState = {
  billingReportFinancialResponsibleData: {},
  billingReportFinancialResponsibleError: false,
  orderBillingReportFinancialResponsible: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BILLING_REPORT_FINANCIAL_RESPONSIBLE:
      return { ...state, billingReportFinancialResponsibleData: action.payload }
    case BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA:
      return { ...state, billingReportFinancialResponsibleData: action.payload }
    case ORDER_BILLING_REPORT_FINANCIAL_RESPONSIBLE:
      return { ...state, orderBillingReportFinancialResponsible: action.payload }
    case BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING:
      return { ...state, loading: action.payload }
    case BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR:
      return { ...state, billingReportFinancialResponsibleError: action.payload }
    default:
      return state
  }
}