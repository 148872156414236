import apiClient from 'api/config/apiClient'

export default {
  getRecipients(payload) {
    return apiClient.request('recipients', payload)
  },
  updateRecipient(payload) {
    return apiClient.request('update_recipient', payload)
  },
}
