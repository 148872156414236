import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getRecipients({ payload }) {
  try {
    yield put({ type: constants.RECIPIENTS_LOADING, payload: true })

    const res = yield call(api.getRecipients, payload)
    yield put({
      type: constants.RECIPIENTS_DATA,
      payload: res,
    })

    yield put({ type: constants.RECIPIENTS_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.RECIPIENTS_ERROR, payload: e.message })
    yield put({ type: constants.RECIPIENTS_LOADING, payload: false })
  }
}

function* updateRecipient({ payload }) {
  try {
    yield put({ type: constants.UPDATE_RECIPIENT_LOADING, payload: true })

    const res = yield call(api.updateRecipient, payload)

    yield put({
      type: constants.UPDATE_RECIPIENT_DATA,
      payload: res,
    })

    yield put({ type: constants.UPDATE_RECIPIENT_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.UPDATE_RECIPIENT_ERROR, payload: e.message })
    yield put({ type: constants.UPDATE_RECIPIENT_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_UPDATE_RECIPIENT_DATA, updateRecipient)
  yield takeLatest(constants.GET_RECIPIENTS, getRecipients)
}
