import { call, put, takeEvery } from 'redux-saga/effects'
import * as constants from 'store/constants'
import get from 'lodash.get'
import api from './api'
import { capitalize, has } from 'lodash'

function handleErrorMessage(message) {
  let errorMessage = 'Ocorreu um erro ao tentar gerar o relatório, tente novamente e se o erro persistir entre em contato com a Unimed Curitiba.'
  if (message.indexOf('database') >= 0) {
    errorMessage = 'Não foi possível conectar à base de dados, por favor tente novamente em alguns minutos.'
  } else if (message.indexOf('DateNotFoundException') >= 0 || message.indexOf('No data found for the given date') >= 0) {
    errorMessage = 'Nenhum dado encontrado para a data especificada.'
  } else if (message.indexOf('already requested') >= 0) {
    errorMessage = 'O relatório para a data especificada já está em processamento.'
  } else if (message.indexOf('The document is not ready') >= 0) {
    errorMessage = 'Pagamento não baixado. Tente novamente mais tarde.'
  } else if (message.indexOf('You have to wait') >= 0) {
    const parts = message.split('|')
    const minValue = Math.ceil(parts[1])
    const minWord = minValue > 1 ? 'minutos' : 'minuto'
    errorMessage = 'Você deve aguardar ' + minValue + ' ' + minWord + ' para gerar novamente.'
  }

  return errorMessage
}

function* getReportLogs({ payload }) {
  try {
    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOGS_LOADING, payload: true })
    const res = yield call(api.getReportLogs, payload)
    yield put({
        type: constants.PAYMENT_DEMONSTRATIVE_LOGS_DATA,
        payload: res.applications.paymentDemonstrative.reportLogsPerDay,
    })

    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOGS_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_PAYMENT_DEMONSTRATIVE_LOGS_ERROR, payload: e.message })
    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOGS_LOADING, payload: false })
  }
}

function* generateDocs({ payload }) {
  const requisitionType = capitalize(payload.datepickerType)
  const dateReq = get(payload, `date`, '')

  try {
    yield put({ type: constants.GET_PAYMENT_DEMONSTRATIVE_ERROR, payload: '' })
    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOADING, payload: true })

    const params = {
      date: payload.date,
      datepickerType: payload.datepickerType,
      pwbusId: get(payload, `applications.paymentDemonstrative[reportsPer${requisitionType}].pwbus_correlation_id`, ''),
      userCode: window.btoa(payload.uid),
      store: payload?.store,
      reportCode: get(payload, `applications.paymentDemonstrative[reportsPer${requisitionType}].report_code`, ''),
      debugMode: payload.debugMode,
    }

    const res = yield call(api.generateDocs, params)
    yield put({
      type: constants.PAYMENT_DEMONSTRATIVE_LOGS_DATA,
      payload: res.applications.paymentDemonstrative[`reportsPer${requisitionType}`],
    })
    // const statusObj = {
    //   ...res,
    //   date: payload.date,
    //   datepickerType: payload.datepickerType,
    //   uid: payload.uid,
    //   created_at: new Date().toISOString().split('T')[0]
    // }

    // const pwbusId = get(statusObj.applications.paymentDemonstrative[`reportsPer${requisitionType}`], 'pwbus_correlation_id')
    // const success = get(statusObj.applications.paymentDemonstrative[`reportsPer${requisitionType}`], 'success')
    
    // if (success) {
    //   const payloadPwbusId = (payload?.applications?.paymentDemonstrative) ? get(payload?.applications?.paymentDemonstrative[`reportsPer${requisitionType}`], 'pwbus_correlation_id') : ''
    //   statusObj.applications.paymentDemonstrative[`reportsPer${requisitionType}`].pwbus_correlation_id = payloadPwbusId
    //   reportsBeingGenerated.push(statusObj)
    //   yield put({
    //     type: constants.PAYMENT_DEMONSTRATIVE_DATA,
    //     payload: statusObj,
    //   })
    // }

    // if (pwbusId) {
    //   yield put({
    //     type: constants.PAYMENT_DEMONSTRATIVE_DATA,
    //     payload: statusObj,
    //   })
    // }

    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOADING, payload: false })
  } catch (e) {
    let errorMessage = handleErrorMessage(e.message)
    yield put({ type: constants.GET_PAYMENT_DEMONSTRATIVE_ERROR, payload: errorMessage })
    yield put({ type: constants.PAYMENT_DEMONSTRATIVE_LOADING, payload: false })

  }
}

export default function* saga() {
  yield takeEvery(constants.GET_PAYMENT_DEMONSTRATIVE, generateDocs)
  yield takeEvery(constants.GET_PAYMENT_DEMONSTRATIVE_LOGS, getReportLogs)
}
