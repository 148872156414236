import {
  QUICK_ACCESS_DATA,
  QUICK_ACCESS_ERROR,
  QUICK_ACCESS_LOADING,
} from 'store/constants'

export const initialState = {
  quickAccessMenu: null,
  errorQuickAccess: null,
  loadingQuickAccess: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case QUICK_ACCESS_DATA:
      return { ...state, quickAccessMenu: action.payload.quickAccessesQuery }
    case QUICK_ACCESS_ERROR:
      return { ...state, errorQuickAccess: action.payload }
    case QUICK_ACCESS_LOADING:
      return { ...state, loadingQuickAccess: action.payload }

    default:
      return state
  }
}
