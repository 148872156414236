import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getBenefitEligibility({ payload }) {
    try {
        yield put({ type: constants.BENEFIT_ELIGIBILITY_LOADING, payload: true })

        const res = yield call(api.getBenefitEligibility, payload)

        yield put({
            type: constants.BENEFIT_ELIGIBILITY_DATA,
            payload: res,
        })

        yield put({ type: constants.BENEFIT_ELIGIBILITY_LOADING, payload: false })
        yield put({ type: constants.BENEFIT_ELIGIBILITY_ERROR, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.BENEFIT_ELIGIBILITY_ERROR, payload: e.message })
        yield put({ type: constants.BENEFIT_ELIGIBILITY_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_BENEFIT_ELIGIBILITY, getBenefitEligibility)
}