import {
    GET_CODE_CBO,
    CODE_CBO_LOADING,
    CODE_CBO_DATA,
    CODE_CBO_ERROR
} from 'store/constants'

export const initialState = {
    codeCBOData: {},
    codeCBOError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CODE_CBO:
            return { ...state, codeCBOData: action.payload }
        case CODE_CBO_DATA:
            return { ...state, codeCBOData: action.payload }
        case CODE_CBO_LOADING:
            return { ...state, loading: action.payload }
        case CODE_CBO_ERROR:
            return { ...state, codeCBOError: action.payload }
        default:
            return state
    }
}