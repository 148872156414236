export default `
fragment ParagraphExtendedImage on ParagraphExtendedImage {
  id
  uuid
  entityBundle
  title
  subtitle
  description {
    value
  }
  media {
    entity {
      ...Media
    }
  }
}

`