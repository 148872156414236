import apiClient from 'api/config/apiClient'
import { sortBy } from 'lodash'

export default {
  getBillingReportFinancialResponsible(payload) {
    return apiClient.request('billing_report_financial_responsible', payload)
  },

  orderByBillingReportFinancialResponsible(query) {
    const { order, values } = query

    if (values.applications && order.direction == 'asc') {
      let asc = sortBy(
        values.applications?.billingReportsFinancialResponsible.billingReportsFinancialResponsibleList.reports,
        order.column
      ).reverse()
      values.applications.billingReportsFinancialResponsible.billingReportsFinancialResponsibleList.reports = asc
      return values
    } else if (values.applications && order.direction == 'desc') {
      let desc = sortBy(
        values.applications?.billingReportsFinancialResponsible.billingReportsFinancialResponsibleList.reports,
        order.column
      )
      values.applications.billingReportsFinancialResponsible.billingReportsFinancialResponsibleList.reports = desc
      return values
    }
  },
}
