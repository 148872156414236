import { takeLatest, call, put } from "redux-saga/effects"
import * as constants from "store/constants"
import api from "./api"


function* getFaqCategory({ payload }) {
  try {
    yield put({ type: constants.LOADING_FAQ_CATEGORY, payload: true })

    const res = yield call(api.getFaqCategory, payload)

    yield put({
      type: constants.FAQ_CATEGORY_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_FAQ_CATEGORY, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_FAQ_CATEGORY_ERROR, payload: e.message })
    yield put({ type: constants.LOADING_FAQ_CATEGORY, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_FAQ_CATEGORY, getFaqCategory)
}
