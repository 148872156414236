import { startsWithHttp } from 'helpers/strings'
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import get from 'lodash.get'

function NavDesktopLink(props) {
  const { id, url, name, links = links || [], active, onClick, cssClass, showAllItems } = props
  const item = { id, url, name, links, active, onClick }
  const history = useHistory()

  const handleClick = (param) => {
    if (onClick) onClick(item)

    const subSectionLink = get(param, 'url.path')
    if (subSectionLink || (url && url !== '' && url !== '/')) {
      history.push(subSectionLink || url)
    }
  }

  const handleRenderedItem = () => {
    if (startsWithHttp(url)) {
      return (
        <a
          className={`${cssClass ? cssClass : 'navbar-dropdown-list-one'}-btn ${active ? 'active' : ''}`}
          href={url}
          title={name}
          target="_blank"
          rel="noopener noreferrer"
        >
          <span className="max-line-2">{name}</span>
          <i className="icone-diagonal-arrow-right-up" />
        </a>
      )
    } else if (showAllItems && links && links.length > 0) {
      return (
        <>
          <li>
            <span className="navbar-dropdown-list-two-btn navbar-link__title">{name}</span>
          </li>
          {links.map((item, index) =>
            startsWithHttp(get(item, 'url.path')) ? (
              <li key={index} className="ml-3">
                <a
                  id={get(item, 'id')}
                  className="navbar-dropdown-list-two-btn"
                  href={get(item, 'url.path')}
                  title={get(item, 'label')}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <span className="max-line-2">{get(item, 'label')}</span>
                  <i className="icone-diagonal-arrow-right-up" />
                </a>
              </li>
            ) : (
              <li key={index} className="ml-3">
                <button
                  id={get(item, 'id')}
                  className="navbar-dropdown-list-two-btn"
                  title={get(item, 'label')}
                  onClick={() => handleClick(item)}
                  key={index}
                >
                  <span className="max-line-2">{get(item, 'label')}</span>
                </button>
              </li>
            )
          )}
        </>
      )
    } else if (showAllItems) {
      return (
        <li>
          <button
            id={id}
            className={`${cssClass ? cssClass : 'navbar-dropdown-list-one'}-btn ${active ? 'active' : ''} ${
              links.length > 0 ? 'navbar-list-items' : ''
            }`}
            title={name}
            onClick={handleClick}
          >
            <span className="max-line-2">{name}</span>
            {links.length > 0 && <i className="icone-chevron-right" />}
          </button>
        </li>
      )
    } else {
      return (
        <button
          id={id}
          className={`${cssClass ? cssClass : 'navbar-dropdown-list-one'}-btn ${active ? 'active' : ''} ${
            links.length > 0 ? 'navbar-list-items' : ''
          }`}
          title={name}
          onClick={handleClick}
        >
          <span className="max-line-2">{name}</span>
          {links.length > 0 && <i className="icone-chevron-right" />}
        </button>
      )
    }
  }

  return handleRenderedItem()
}

NavDesktopLink.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  url: PropTypes.string,
  links: PropTypes.array,
  onClick: PropTypes.func,
  cssClass: PropTypes.string,
}

export default NavDesktopLink
