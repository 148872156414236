import { gql } from 'graphql-request'
export default gql`
  query webformSubmissions($userId: String!, $webformId: String!) {
    webformSubmissions(userId: $userId, webformId: $webformId) {
      result {
        date
        userSubmitted
      }
    }
  }
`