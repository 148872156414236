export const snakeToPascal = (string) => {
  if (typeof string !== 'string') return ''

  return string
    .split('_')
    .map((str) => {
      return upperFirst(str.split('/').map(upperFirst).join('/'))
    })
    .join('')
}

export const upperFirst = (string) => {
  if (typeof string !== 'string') return ''
  return string.slice(0, 1).toUpperCase() + string.slice(1, string.length)
}

export const startsWithHttp = (string) => {
  if (typeof string !== 'string') return false
  const link = string.toLowerCase()
  if (link.includes('tel:') || link.includes('mailto:') || link.includes('api.whatsapp:') || link.includes('wa.me:'))
    return true
  return link.substring(0, 4) === 'http'
}

export const toUpperCase = (string) => {
  if (typeof string !== 'string') return ''
  return string.toUpperCase()
}

export const toLowerCase = (string) => {
  if (typeof string !== 'string') return ''
  return string.toLowerCase()
}

export const removeSpecialChars = (string) => {
  if (typeof string !== 'string') return ''
  return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export const slugify = (string) => {
  if (typeof string !== 'string') return ''
  return removeSpecialChars(string).trim().replace(/ /g, '-').replace(/\//g, '-').toLowerCase()
}

export const generateId = (suffix, prefix) => {
  if (suffix && typeof suffix !== 'string') return ''
  if (prefix && typeof prefix !== 'string') return ''
  const time = new Date().getTime()
  return `${slugify(prefix)}${time}${slugify(suffix)}`
}

export const cpfMask = (value) => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value) => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{4})(\d{1,2})/, '$1/$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cepMask = (value) => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/\.(\d{3})(\d)/, '$1-$2')
    .replace(/(-\d{3})\d+?$/, '$1') // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const phoneWithDDDMask = (value, maxLength = 15, isCellphone = false) => {
  if (typeof value !== 'string') return ''
  if (value.length <= maxLength) {
    if (isCellphone) {
      return value.replace(/\D/g, '').replace(/^(\d{2})(\d{5})(\d{4}).*/, '($1) $2-$3')
    } else {
      return value.replace(/\D/g, '').replace(/^(\d{2})(\d{4})(\d{4}).*/, '($1) $2-$3')
    }
  } else return false
}

export const phoneMask = (value) => {
  if (typeof value !== 'string') return ''
  if (value.length >= 9) {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  } else {
    return value
      .replace(/\D/g, '')
      .replace(/^(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{4})\d+?$/, '$1')
  }
}

export const codeMask = (value) => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/\D/g, '')
    .replace(/^(\d{1})(\d)/, '$1 $2')
    .replace(/(\d{3})(\d)/, '$1 $2')
    .replace(/(\d{12})(\d)/, '$1 $2')
}

export const dateMask = (value) => {
  if (typeof value !== 'string') return ''
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1')
}

export const hideString = (value) => {
  if (typeof value !== 'string') return ''
  let str = value.charAt(0)
  for (var i = 1; i < value.length - 1; i++) {
    str += '*'
  }
  str += value.charAt(value.length - 1)
  return str
}

export const hideEmail = (value) => {
  if (typeof value !== 'string') return ''
  let parts = value.split('@')
  let email = hideString(parts[0]) + '@'
  parts = parts[1].split('.')
  email += hideString(parts[0]) + '.' + parts[1] + (parts[2] ? '.' + parts[2] : '')
  return email
}

export const numberMask = (value, limit = 99) => {
  if (typeof value !== 'string') return ''
  return value.replace(/\D/g, '').substr(0, limit) // substitui qualquer caracter que nao seja numero por nada
}

export const accentuationMask = (value) => {
  if (typeof value !== 'string') return ''
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

export default {
  snakeToPascal,
  upperFirst,
  startsWithHttp,
  toUpperCase,
  toLowerCase,
  slugify,
  removeSpecialChars,
  generateId,
}
