import apiClient from 'api/config/apiClient'

export default {
  getToken(values) {
    if (values['g-recaptcha-response'] === "NOCAPTCHA") {
      return apiClient.request('get_token_nocaptcha', values)
    } else {
      return apiClient.request('get_token', values)
    }
  },
}
