import React, { useEffect, useState, useContext } from 'react'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { Spinner, Button } from 'react-bootstrap'
import API_AUTH from 'store/modules/auth/api'
import * as authActions from 'store/modules/auth/actions'
import * as tokenActions from 'store/modules/token/actions'
import { numberMask } from 'helpers/strings'
import { validateToken, invalidateUser } from 'helpers/auth'
import { Base64 } from 'js-base64'

// Components
import PageSteps from 'components/organisms/PageSteps'
import Typography from 'components/atoms/Typography'
import { getProfilesErrors } from '../../helpers'

// Styles
import './LoginEmailValidated.scss'

function LoginEmailValidated(props) {
  const { store } = useContext(ReactReduxContext)
  const changeStep = get(props, 'changeStep')
  let { userId, userToken } = useParams()
  let login_user = useSelector((state) => state.auth.user)
  let loading_user = useSelector((state) => state.auth.loadingUser)
  let user_error = useSelector((state) => state.auth.userError)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const social_token = new URLSearchParams(location.search).get('token')
  const [isFirstSocialLogin, setIsFirstSocialLogin] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const changeBreadcrumbVisibility = get(props, 'changeBreadcrumbVisibility')
  const changeHeaderVisibility = get(props, 'changeHeaderVisibility')

  useEffect(async () => {
    if (social_token) {
      let social_auth = {
        ...JSON.parse(Base64.decode(social_token)),
        last_request: new Date(),
      }
      dispatch(tokenActions.setToken(social_auth))
      if (validateToken(store)) {
        dispatch(authActions.getUser({ default: true, store: store }))
      } else {
        await invalidateUser(store)
        changeStep('SomethingWrong')
      }
    } else {
      changeHeaderVisibility && changeHeaderVisibility(false)
      changeBreadcrumbVisibility && changeBreadcrumbVisibility(false)
    }
  }, [])

  useEffect(async () => {
    if (user_error) {
      await invalidateUser(store)
      changeStep('SomethingWrong')
    } else {
      if (login_user) {
        if (get(login_user, 'active')) {
          history.push('/app')
        } else {
          setIsFirstSocialLogin(true)
        }
      }
    }
  }, [login_user, user_error])

  async function addFinancialResponsiblePJ() {
    const formValues = {
      cnpj: '33517640001285',
      contract: '33517640001285',
      login: '33517640001285',
      id: '33517640001285',
      type: 'responsavel_financeiro',
    }
    await handleAddNewProfile(formValues)
  }

  async function addFinancialResponsiblePF() {
    const formValues = {
      cpf: '49664662968',
      contract: '49664662968',
      login: '49664662968',
      id: '49664662968',
      type: 'responsavel_financeiro',
    }
    await handleAddNewProfile(formValues)
  }

  async function addThirdParty() {
    const formValues = {
      cnpj: '78747821000183',
      contract: '78747821000183',
      login: '78747821000183',
      id: '78747821000183',
      type: 'empresacobranca',
    }
    await handleAddNewProfile(formValues)
  }

  async function handleAddNewProfile(formValues) {
    formValues.id = parseInt(userId)
    formValues.token = userToken
    formValues.card_number = numberMask(formValues.card_number)
    formValues.cnpj = numberMask(formValues.cnpj)
    formValues.cpf = numberMask(formValues.cpf)
    formValues.birth_date = formValues.birth_date ? formValues.birth_date.split('/').reverse().join('-') : ''
    formValues.email = get(login_user, 'user.email')
    formValues.contract = formValues.contract
    setIsLoading(true)
    try {
      let response = await API_AUTH.checkUserAndAddProfile(formValues)
      let appendData = {
        ...formValues,
        ...response.checkUserAndAddProfile,
        token: userToken,
        isNewUser: true,
        fromStep: 'LoginEmailValidated',
        inactiveMessage: '',
      }

      setIsLoading(false)

      if (response.checkUserAndAddProfile.message) {
        const email = response.checkUserAndAddProfile.email_client

        const pTemp = response.checkUserAndAddProfile.message.split('#')
        const role = pTemp[0]
        let phone = ''

        switch (role) {
          case 'client':
            phone = '0800-642 2002'
            break
          case 'cooperado':
            phone = '41 3021 7000'
            break
          default:
            phone = '0800-642 2002'
            break
        }

        if (response.checkUserAndAddProfile.message.indexOf('O e-mail informado não confere') >= 0) {
          const message = `
            <p>
              O e-mail informado não confere com o cadastro existente na Unimed Curitiba.
            </p>
            <p>Você deve utilizar a conta de e-mail ${email} para poder utilizar este perfil de acesso.</p>
            <p>
              Não tendo mais acesso a esta conta de e-mail ou em caso de dúvida, entre em contato com a central de atendimento no ${phone}.
            </p>
          `
          changeStep('AccessDenied', { message })
        } else if (response.checkUserAndAddProfile.message.indexOf('O cliente não possui e-mail cadastrado') >= 0) {
          const message = `
            <p>
            Para criar esse perfil você precisa ter um e-mail cadastrado na Unimed Curitiba.
            </p>
            <p>
            Entre em contato com os canais de atendimento para para atualizar seu cadastro ou com a central de atendimento no ${phone}.
            </p>
          `
          changeStep('AccessDenied', { message })
        }
        return false
      }

      if (isFirstSocialLogin) {
        if (response.checkUserAndAddProfile.message) {
          changeStep('AccessDenied')
        } else {
          changeStep('AccountCreated', appendData)
        }
      } else {
        changeStep('ChangePassword', appendData)
      }
    } catch (error) {
      setIsLoading(false)
      document.querySelector('body').scrollIntoView()
      let errorMessage = get(error, 'response.errors[0].message')
      if (!errorMessage) {
        errorMessage = get(error, 'message')
      }
      changeStep('AccessDenied', getProfilesErrors(errorMessage))
    }
  }

  return (
    <section className="login-email-validated">
      <div className="content">
        <div className="login-header">
          <div className="container">
            <Typography variant="p" cssClass="title">
              Bem-vindo à sua Unimed
            </Typography>
          </div>
        </div>
        <div className="login-email-validated-body">
          <div className="container">
            {loading_user ? (
              <center>
                <Spinner animation="border" />
              </center>
            ) : (
              <>
                <div className="container">
                  <p className="text mb-4">
                    Realize seu cadastro no Portal Unimed Curitiba e utilize nossos serviços online disponíveis para
                    você.
                  </p>
                </div>
                <PageSteps onSubmit={handleAddNewProfile} isLoading={isLoading} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default LoginEmailValidated
