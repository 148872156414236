import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getBillingReportFinancialResponsible({ payload }) {
  try {
    yield put({ type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING, payload: true })

    const res = yield call(api.getBillingReportFinancialResponsible, payload)

    yield put({
      type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA,
      payload: res,
    })

    yield put({ type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR, payload: e.message })
    yield put({ type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_LOADING, payload: false })
  }
}

function* orderByBillingReportFinancialResponsible({ payload }) {

  try {
    const res = yield call(api.orderByBillingReportFinancialResponsible, payload)
    yield put({
      type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_DATA,
      payload: res,
    })

  } catch (e) {
    console.error(`${e.message}`)
    yield put({ type: constants.BILLING_REPORT_FINANCIAL_RESPONSIBLE_ERROR, payload: e.message })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_BILLING_REPORT_FINANCIAL_RESPONSIBLE, getBillingReportFinancialResponsible)
  yield takeLatest(constants.ORDER_BILLING_REPORT_FINANCIAL_RESPONSIBLE, orderByBillingReportFinancialResponsible)
}