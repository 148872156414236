export default `
fragment ParagraphImagesMosaic on ParagraphImagesMosaic {
  id
  uuid
  entityBundle
  items {
    entity {
      ...ParagraphTitleAndDescription
    }
  }
  medias {
    entity {
      ...Media
    }
  }
}
`