import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import SocialMedia from 'components/molecules/SocialMedia'

function SocialMediaMobile({ links }) {
  return (
    <Row className="d-block d-md-none pt-4">
      <Col sm={12}>
        <SocialMedia links={links} />
      </Col>
    </Row>
  )
}

export default SocialMediaMobile