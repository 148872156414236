export default `
fragment ParagraphAudio on ParagraphAudio {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  media {
    entity {
      ...Media
    }
  }
  thumb {
    entity{
      ...Media
    }
  }
}  
`