import React, { useState } from 'react'
import { Container, Form } from 'react-bootstrap'
import { get } from 'lodash'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import API_AUTH from 'store/modules/auth/api'

import CustomButton from 'components/atoms/CustomButton'
import Typography from 'components/atoms/Typography'

import './ChangePassword.scss'

function ChangePassword({ changeStep, data }) {
  let { userId, userToken } = useParams()
  const location = useLocation()
  const fromStep = get(data, 'fromStep')
  const firstAccess = fromStep == 'AcceptInvitation' || fromStep == 'LoginEmailValidated'
  const history = useHistory()
  const [newPassword, setNewPassword] = useState('')
  const [userName, setUserName] = useState('')
  const [formValidated, setFormValidated] = useState(false)
  const [requiredPolicyPrivacy, setRequeridPolicyPrivacy] = useState(false)
  const [confirmNewPassword, setConfirmNewPassword] = useState('')
  const [passwordShown, setPasswordShown] = useState(false)
  const [passwordRequirement, setPasswordRequirement] = useState({
    length: false,
    case_sensitive: false,
    number: false,
    same: false,
  })
  const { length, case_sensitive, number, same } = passwordRequirement
  const [isLoading, setIsLoading] = useState(false)

  function handleChangePassword({ target: { value } }) {
    const hasUpperCase = [...value].find((letter) => letter.match(/[a-z]/i) && letter === letter.toUpperCase())
    const hasLowerCase = [...value].find((letter) => letter.match(/[a-z]/i) && letter === letter.toLowerCase())
    setPasswordRequirement({
      length: value.length >= 8,
      case_sensitive: hasUpperCase && hasLowerCase,
      number: value.match(/[0-9]/g),
      same: value === confirmNewPassword,
    })
    setNewPassword(value)
  }
  function handleChangeConfirmPassword({ target: { value } }) {
    setPasswordRequirement((current) => ({ ...current, same: value === newPassword }))
    setConfirmNewPassword(value)
  }
  const username = get(data, 'name')

  const greeting = username ? `Olá ${username}` : 'Bem-vindo ao Portal Unimed'

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget

    if (form.checkValidity()) {
      setIsLoading(true)
      try {
        if (
          location.pathname.indexOf('/reset-password') === 0 ||
          location.pathname.indexOf('/accept-invitation') === 0
        ) {
          let payload = {
            id: parseInt(userId),
            token: userToken,
            password: newPassword,
            name: userName,
          }
          const response = await API_AUTH.resetPassword(payload)
          if (response.resetUserPassword.success === false) {
            changeStep('SomethingWrong')
          } else {
            changeStep('FeedbackSuccess', { ...response.resetUserPassword, type: 'password' })
          }
        } else {
          let payload = {
            id: data.id.toString(),
            token: data.token,
            password: newPassword,
            email: get(data, 'email'),
            name: get(data, 'name'),
            login: get(data, 'contract'),
          }
          const response = await API_AUTH.activateUser(payload)
          changeStep('AccountCreated', { ...response, inactiveMessage: get(data, 'inactiveMessage') })
        }
        setIsLoading(false)
      } catch (error) {
        changeStep('SomethingWrong')
      }
    }
    setFormValidated(true)
  }

  return (
    <div className="change-password">
      <div className="login-header">
        <div className="container">
          <Typography variant="p" cssClass="title">
            {firstAccess ? greeting : 'Esqueceu seu e-mail ou senha?'}
          </Typography>
        </div>
      </div>
      <Container className="py-4">
        <Typography color="primary" cssClass="mb-3 text-center">
          {firstAccess ? 'Vamos criar seu acesso:' : 'Vamos redefinir sua senha:'}
        </Typography>
        <Form noValidate validated={formValidated} onSubmit={handleSubmit}>
          {firstAccess && fromStep != 'LoginEmailValidated' && (
            <Form.Group>
              <Form.Label>Nome Completo:</Form.Label>
              <Form.Control
                type="name"
                id="name"
                name="name"
                aria-label="name"
                required
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">Informe seu nome.</Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="mb-2 password-input">
            <Form.Label htmlFor="password">
              {firstAccess ? 'Digite a senha escolhida' : 'Digite a nova senha:'}
            </Form.Label>
            <Form.Control
              type={passwordShown ? 'text' : 'password'}
              id="password"
              name="password"
              aria-label="password"
              required
              onChange={handleChangePassword}
            />
            <span className="password-visible" onClick={togglePasswordVisiblity}>
              {passwordShown ? <i className={'icone-eye show'} /> : <i className={'icone-eye'} />}
            </span>
            <Form.Control.Feedback type="invalid">Informe sua senha.</Form.Control.Feedback>
          </Form.Group>
          <Typography color="gray-100">Sua senha deve ter:</Typography>
          <ul className="change-password__criteria mb-4">
            <li>
              <Typography color={length ? 'primary' : 'gray-100'}>
                {length && <i className="icone-checkmark mr-1" />}8 ou mais caracteres
              </Typography>
            </li>
            <li>
              <Typography color={case_sensitive ? 'primary' : 'gray-100'}>
                {case_sensitive && <i className="icone-checkmark mr-1" />}
                Letras maiúsculas e minúsculas
              </Typography>
            </li>
            <li>
              <Typography color={number ? 'primary' : 'gray-100'}>
                {number && <i className="icone-checkmark mr-1" />}
                Pelo menos um número
              </Typography>
            </li>
            <li>
              <Typography color={same ? 'primary' : 'gray-100'}>
                {same && <i className="icone-checkmark mr-1" />}
                As senhas devem ser iguais
              </Typography>
            </li>
          </ul>
          <Form.Group className="confirm-password-input">
            <Form.Label htmlFor="confirm-password">
              {firstAccess ? 'Confirme sua senha' : 'Confirme sua nova senha:'}
            </Form.Label>
            <Form.Control
              type={passwordShown ? 'text' : 'password'}
              id="confirm-password"
              name="confirm-password"
              aria-label="confirm-password"
              required
              onChange={handleChangeConfirmPassword}
            />
          </Form.Group>
          {firstAccess && fromStep != 'LoginEmailValidated' && (
            <Form.Group controlId="policyPrivacy">
              <Form.Check
                type="checkbox"
                checked={requiredPolicyPrivacy}
                onChange={({ target }) => setRequeridPolicyPrivacy(target.checked)}
                required
                invalid={!requiredPolicyPrivacy}
                label={
                  <span>
                    Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou consentimento, quando
                    aplicável conforme descrito nesta{' '}
                    <HashLink to="/politica-de-privacidade" target="_blank">
                      Política de Privacidade
                    </HashLink>{' '}
                    e{' '}
                    <HashLink to="/termos-de-uso" target="_blank">
                      Termos de Uso
                    </HashLink>
                  </span>
                }
              ></Form.Check>
              <Form.Control.Feedback type="invalid">
                É necessário estar de acordo com a Política de Privacidade e os Termos de Uso.
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <CustomButton
            className="change-password__submit my-5 justify-content-center"
            disabled={!(length && case_sensitive && number && same) || isLoading}
            text="Confirmar"
            noIcon
            type="submit"
            isLoading={isLoading}
          />
        </Form>
        {username && (
          <Typography
            color="gray-100"
            cssClass="text-center d-flex flex-wrap justify-content-center align-items-center"
          >
            <Typography cssClass="mb-4 text-center" color="gray-100">
              Caso tenha encontrado divergência no seu cadastro, entre em contato com a Unimed Curitiba pelo canal de acordo com seu perfil:
            </Typography>
            <p className='text-left'>
              <ul className='text-left'>
                <li>Cliente: 0800 642 2002</li>
                <li>Empresa contratante: 0800 642 4004</li>
                <li>Médico cooperado ou secretária(o): (41) 3021-7000</li>
                <li>Prestador ou fornecedor: (41) 3021-9420</li>
              </ul>
            </p>
          </Typography>
        )}
      </Container>
    </div>
  )
}

export default ChangePassword
