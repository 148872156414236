import apiClient from 'api/config/apiClient'

export default {
  getCities(payload) {
    return apiClient.request('cities', payload)
  },

  getSpecialties(payload) {
    return apiClient.request('specialties', payload)
  },

  getMedicalSchedule(payload) {
    return apiClient.request('medical_schedule', payload)
  },
}
