export default `
fragment ParagraphFlipbook on ParagraphFlipbook {
  uuid
  entityBundle
  media {
    entity {
      ...Media
    }
  }
}
`
