import { takeLatest, call, put } from "redux-saga/effects"
import * as constants from "store/constants"
import api from "./api"

function* getWebformSubmissions({ payload }) {
    try {
        yield put({ type: constants.LOADING_WEBFORM_SUBMISSIONS, payload: true })

        const res = yield call(api.getWebformSubmissions, payload)

        yield put({
            type: constants.WEBFORM_SUBMISSIONS_DATA,
            payload: res,
        })

        yield put({ type: constants.LOADING_WEBFORM_SUBMISSIONS, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.ERROR_WEBFORM_SUBMISSIONS, payload: e.message })
        yield put({ type: constants.LOADING_WEBFORM_SUBMISSIONS, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_WEBFORM_SUBMISSIONS, getWebformSubmissions)
}
