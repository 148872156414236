import { gql } from 'graphql-request'

export default gql`
  mutation updateQuickAccess($index: Int!, $role: String!, $link_url: String!, $link_title: String!) {
    updateQuickAccess(index: $index, role: $role, link_url: $link_url, link_title: $link_title) {
      index
      user {
        id
      }
      role {
        role
      }
      link {
        title
        url {
          path
        }
      }
    }
  }
`
