import { gql } from 'graphql-request'

export const activateUser = gql`
  mutation activateUser(
    $id: String!
    $token: String!
    $password: String!
    $login: String
    $name: String
    $email: String
  ) {
    activateUser(id: $id, token: $token, password: $password, login: $login, name: $name, email: $email) {
      success
      message
    }
  }
`

export const addUser = gql`
  mutation addUser($email: String!, $name: String!, $resend: Boolean!) {
    addUser(email: $email, name: $name, resend: $resend) {
      id
      email
      name
      message
    }
  }
`
export const setUserProfile = gql`
  mutation setUserProfile($profile_id: String!, $contract: String, $type: String!, $profile_tasy: String, $email: String) {
    setUserProfile(profile_id: $profile_id, contract: $contract, type: $type, profile_tasy: $profile_tasy, email: $email) {
      id
      email
      name
      contract
      st
      roles
      crm
      menu
      active
      active_benner
      profile_id
      account
    }
  }
`

export const setUserDefaultProfile = gql`
  mutation setUserDefaultProfile($profile_id: String!, $contract: String!) {
    setUserDefaultProfile(profile_id: $profile_id, contract: $contract) {
      profile_id
      name
      type
      contract
      default_profile
      owner
      active
      active_benner
      account
      profile_tasy
      invites {
        id
        name
        email
        profile_id
        active
        active_benner
      }
    }
  }
`

export const grantAccessUser = gql`
  mutation grantAccessUser($profile_id: String!, $contract: String!, $email: String!) {
    grantAccessUser(profile_id: $profile_id, contract: $contract, email: $email) {
      success
      message
      email
      name
      profile
    }
  }
`

export const removeAccessUser = gql`
  mutation removeAccessUser($profile_id: String!, $contract: String!) {
    removeAccessUser(profile_id: $profile_id, contract: $contract) {
      id
      email
      message
    }
  }
`

export const getToken = gql`
  query getToken($username: String, $password: String, $refresh_token: String) {
    token(username: $username, password: $password, refresh_token: $refresh_token) {
      token_type
      expires_in
      access_token
      refresh_token
      drupal_session {
        key
        value
      }
    }
  }
`

export const getUser = gql`
  query getUser($default: Boolean) {
    user(default: $default) {
      id
      menu
      account
      profile_id
      email
      name
      contract
      st
      roles
      crm
      active
      active_benner
      owner
    }
  }
`

export const getUserProfiles = gql`
  query getUserProfiles {
    userProfiles {
      profile_id
      name
      type
      contract
      default_profile
      owner
      active
      active_benner
      profile_tasy
      invites {
        id
        name
        email
        profile_id
        contract
      }
    }
  }
`

export const rememberEmail = gql`
  query userRememberEmail(
    $name: String
    $cpf: String
    $card_number: String
    $birth_date: String
    $cnpj: String
    $crm: String
    $login: String
    $profile: String
    $type: String
  ) {
    userRememberEmail(
      name: $name
      cpf: $cpf
      card_number: $card_number
      birth_date: $birth_date
      cnpj: $cnpj
      crm: $crm
      login: $login
      profile: $profile
      type: $type
    ) {
      success
      message
      email
    }
  }
`

export const rememberPassword = gql`
  mutation rememberUserPassword($email: String!, $resend: Boolean!) {
    rememberUserPassword(email: $email, resend: $resend) {
      success
      message
    }
  }
`

export const resetPassword = gql`
  mutation resetUserPassword($id: Int!, $token: String!, $password: String!, $name: String) {
    resetUserPassword(id: $id, token: $token, password: $password, name: $name) {
      success
      message
    }
  }
`

export const checkUserAndAddProfile = gql`
  mutation checkUserAndAddProfile(
    $id: Int!
    $name: String
    $cpf: String
    $card_number: String
    $birth_date: String
    $cnpj: String
    $crm: String
    $login: String
    $profile: String
    $type: String
    $email: String
    $user_name: String
  ) {
    checkUserAndAddProfile(
      id: $id
      name: $name
      cpf: $cpf
      card_number: $card_number
      birth_date: $birth_date
      cnpj: $cnpj
      crm: $crm
      login: $login
      profile: $profile
      type: $type
      email: $email
      user_name: $user_name
    ) {
      id
      name
      email
      contract
      message
      active
      active_benner
      email_client
      user_name
    }
  }
`

export const removeSharedProfile = gql`
  mutation removeSharedProfile($invited_id: Int!, $profile_id: Int!, $contract: String!) {
    removeSharedProfile(invited_id: $invited_id, profile_id: $profile_id, contract: $contract) {
      success
      message
    }
  }
`

export const somethingWrong = gql`
  mutation somethingWrong(
    $id: Int!
    $name: String!
    $birthday: String
    $cpf: String
    $code: String
    $type: String!
    $cnpj: String
    $crm: String
    $old_name: String!
    $old_birthday: String
    $old_cpf: String
    $old_code: String
    $old_type: String
    $old_cnpj: String
    $old_crm: String
  ) {
    somethingWrong(
      id: $id
      name: $name
      birthday: $birthday
      cpf: $cpf
      code: $code
      type: $type
      cnpj: $cnpj
      crm: $crm
      old_name: $old_name
      old_birthday: $old_birthday
      old_cpf: $old_cpf
      old_code: $old_code
      old_type: $old_type
      old_cnpj: $old_cnpj
      old_crm: $old_crm
    ) {
      success
      message
    }
  }
`

export const validateActivationToken = gql`
  mutation validateActivationToken($id: Int!, $token: String!) {
    validateActivationToken(id: $id, token: $token) {
      success
      message
    }
  }
`

export const sendActivationLink = gql`
  mutation sendActivationLink($user_id: Int!, $profile_id: String!, $contract: String!, $resend: Boolean!) {
    sendActivationLink(user_id: $user_id, profile_id: $profile_id, contract: $contract, resend: $resend) {
      success
      message
    }
  }
`
