import buttonFragment from 'api/queries/fragments/buttonFragment'

export default `
fragment ParagraphHtmlItemListImage on ParagraphHtmlItemListImage {
  id
  uuid
  entityBundle
  title
  descriptions {
    value
  }
  items {
    entity {
      ...ParagraphDescriptionWithIcon
    }
  }
  image {
    entity {
      ...Media
    }
  }
  imageAlignment
  button {
    entity {
      ...ParagraphButton
    }
  }
  primaryColor {
    entity {
      fieldColor {
        color
      }
    }
  }
  secondaryColor {
    entity {
      fieldColor {
        color
      }
    }
  }
}

`