export default `
fragment ParagraphPhotoGalleryWidget on ParagraphPhotoGalleryWidget {
  uuid
  entityBundle
  title
  category {
    entity {
      tid
      name
    }
  }
  photo_gallery {
    items {
      title
      promote
      created      
      body {
        value
      }
      url {
        path
      }
      main {
        entity {
          ...Media
        }
      }
    }
    pager {
      current_page
      total_items
      total_pages
      items_per_page
    }
  } 
}
`
