export default `
  fragment ParagraphPhoneTagsWithModal on ParagraphPhoneTagsWithModal {
    id
    uuid
    entityBundle
    title
    description {
      value
    }
    link {
      entity {
        ...ParagraphButton
      }
    }
    theme {
        entity {
          name
          classes
        }
    }  
    colorPalette {
        entity {
          name
          colors {
            color
          }
        }
    }    
    items {
      entity {
        ...ParagraphPhoneTag
      }
    }
    categories {
      entity{
        name
        phones {
          entities {
            title
            phone
            body {
              value
            }
          }
        }
      }
    }
  }
`