export default `
fragment ParagraphMap on ParagraphMap {
 id
  uuid
  entityBundle
  title
  description {
    value
  }
  addresses {
    entity{
      uuid
      title
      address1
      address2
      number
      address3
      city
      state
      country
      zipcode
      latitude
      longitude
    }
  }
  theme {
    entity {
      name
      classes
    }
  }
}
`
