import { gql } from 'graphql-request'

export default gql`
	query getBenefitEligibility($login: String!, $code: String!) {
		applications {
            elegibility {
                elegibilityResult (login: $login, code: $code) {
                    mensagemErro
                    numeroCarteira
                    status
                    dataHora
                    nomeBeneficiario
                }
            }
        }
	}
`