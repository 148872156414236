import { gql } from 'graphql-request'

export default gql`
query getFaqs($method: String!, $category: String!) {
  faqQuery(method: $method, category: $category) {
    entities {
      body {
        value
        summary
      }
      category {
        entity {
          tid
          name
        }
      }
      title
      link {
        path
      }
    }
  }
}
`