import React from 'react'
import PropTypes from 'prop-types'

// Components
import CustomModal from 'components/molecules/CustomModal'
import CardSample from 'assets/img/Unimed_Card_Sample.png'

// Style
import './ModalUnimedCard.scss'
function ModalUnimedCard({ open, onClose }) {
  return (
    <CustomModal open={open} closeModal={onClose} size="sm" title="Código da sua carteirinha">
      <div className="ModalLogin__modal-container">
        <p>O código é a sequência numérica localizada em seu cartão de identificação da Unimed Curitiba.</p>
        <img src={CardSample} className="img-fluid card-sample" alt="Modelo de carteirinha Unimed" />
      </div>
    </CustomModal>
  )
}

ModalUnimedCard.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
}

export default ModalUnimedCard
