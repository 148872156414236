export default `
fragment ParagraphPhotoGallery on ParagraphPhotoGallery {
  uuid
  entityBundle
  sectionTitle
  title
  description {
    value
  }
  carouselMobile
  number  
  theme {
    entity {
      name
      classes 
    }
  }
  link {
    title
    url {      
      path 
    } 
  }  
  photo_gallery {
    items {
      title
      promote
      created      
      body {
        value
      }
      url {
        path
      }
      main {
        entity {
          ...Media
        }
      }
    }
    pager {
      current_page
      total_items
      total_pages
      items_per_page
    }
  }
}
`
