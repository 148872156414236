import { capitalize } from 'lodash'

import {
  CARDS_DATA,
  CARDS_ERROR,
  CARDS_LOADING,
  CARD_RECIPIENT_DATA,
  CARD_RECIPIENT_ERROR,
  CARD_RECIPIENT_LOADING,
  UPDATE_CARD_RECIPIENT_DATA,
  UPDATE_CARD_RECIPIENT_ERROR,
  UPDATE_CARD_RECIPIENT_LOADING,
  SEARCH_ZIPCODE_DATA,
  SEARCH_ZIPCODE_ERROR,
  SEARCH_ZIPCODE_LOADING,
} from 'store/constants'

export const initialState = {
  cards: null,
  errorCardsUnlock: null,
  loadingCardsUnlock: null,
  cardRecipient: null,
  errorCardRecipent: null,
  loadingCardRecipent: null,
  updateCardRecipient: null,
  errorUpdateCardRecipient: null,
  loadingUpdatedCardRecipient: null,
  searchZipCode: null,
  errorSearchZipCode: null,
  loadingSearchZipCode: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CARDS_DATA:
      return { ...state, cards: action.payload.applications.cardUnlock.cardList.cards }
    case CARDS_ERROR:
      return { ...state, errorCardsUnlock: action.payload }
    case CARDS_LOADING:
      return { ...state, loadingCardsUnlock: action.payload }
    case CARD_RECIPIENT_DATA:
      return { ...state, cardRecipient: action.payload.applications.cardUnlock.cardRecipient }
    case CARD_RECIPIENT_ERROR:
      return { ...state, errorCardRecipent: action.payload }
    case CARD_RECIPIENT_LOADING:
      return { ...state, loadingCardRecipent: action.payload }
    case UPDATE_CARD_RECIPIENT_DATA:
      return { ...state, updateCardRecipient: action.payload.applications.cardUnlock.cardRecipientUpdate }
    case UPDATE_CARD_RECIPIENT_ERROR:
      return { ...state, errorUpdateCardRecipient: action.payload }
    case UPDATE_CARD_RECIPIENT_LOADING:
      return { ...state, loadingUpdatedCardRecipient: action.payload }
    case SEARCH_ZIPCODE_DATA:
      return { ...state, searchZipCode: action.payload.applications.cardUnlock }
    case SEARCH_ZIPCODE_ERROR:
      return { ...state, errorSearchZipCode: action.payload }
    case SEARCH_ZIPCODE_LOADING:
      return { ...state, loadingSearchZipCode: action.payload }
    default:
      return state
  }
}
