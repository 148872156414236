import { gql } from 'graphql-request'

export default gql`
query getBillingReport($contract: String, $digit: String!, $st: String) {
  applications {
    billingReport {
      billingReportsList(contract: $contract, digit: $digit, st: $st) {
        reports {
          file
          file_code
          file_extension
          competence
          contract
          digit
        }
      }
    }
  }
}`

