import { gql } from 'graphql-request'

export default gql`
  query getBillingReportFinancialResponsible($contract: String) {
    applications {
      billingReportsFinancialResponsible {
        billingReportsFinancialResponsibleList(contract: $contract) {
          reports {
            file
            file_code
            file_extension
            competence
            contract
          }
        }
      }
    }
  }
`
