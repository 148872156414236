import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getRecipientDetails({ payload }) {
  try {
    yield put({ type: constants.GET_RECIPIENT_DETAILS_LOADING, payload: true })

    const res = yield call(api.getRecipientDetails, payload)

    yield put({
      type: constants.GET_RECIPIENT_DETAILS_DATA,
      payload: res,
    })
    yield put({ type: constants.GET_RECIPIENT_DETAILS_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_RECIPIENT_DETAILS_ERROR, payload: e.message })
    yield put({ type: constants.GET_RECIPIENT_DETAILS_LOADING, payload: false })
  }
}

function* getRecipientsList({ payload }) {
  try {
    yield put({ type: constants.GET_RECIPIENTS_LIST_LOADING, payload: true })

    const res = yield call(api.getRecipientsList, payload)

    yield put({
      type: constants.GET_RECIPIENTS_LIST_DATA,
      payload: res,
    })
    yield put({ type: constants.GET_RECIPIENTS_LIST_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_RECIPIENTS_LIST_ERROR, payload: e.message })
    yield put({ type: constants.GET_RECIPIENTS_LIST_LOADING, payload: false })
  }
}



export default function* saga() {
  yield takeLatest(constants.GET_RECIPIENTS_LIST, getRecipientsList)
  yield takeLatest(constants.GET_RECIPIENT_DETAILS, getRecipientDetails)
}
