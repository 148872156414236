import {
    GET_EMPLOYEE_CARDS,
    EMPLOYEE_CARDS_LOADING,
    EMPLOYEE_CARDS_DATA,
    EMPLOYEE_CARDS_ERROR
} from 'store/constants'

export const initialState = {
    employeeCardData: {},
    employeeCardError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_CARDS:
            return { ...state, employeeCardData: action.payload }
        case EMPLOYEE_CARDS_DATA:
            return { ...state, employeeCardData: action.payload }
        case EMPLOYEE_CARDS_LOADING:
            return { ...state, loading: action.payload }
        case EMPLOYEE_CARDS_ERROR:
            return { ...state, employeeCardError: action.payload }
        default:
            return state
    }
}