import { gql } from 'graphql-request'

export default gql`
  query getCards($cardNumber: String!, $expirationDate: String!, $birthDate: String!, $cpf: String!) {
    applications {
      cardUnlock {
        cardList(cardNumber: $cardNumber, expirationDate: $expirationDate, birthDate: $birthDate, cpf: $cpf) {
          cards {
            name
            type
            code
            blocked
            dependentType
            age
            cpf
            handle
          }
        }
      }
    }
  }
`
