export default `
fragment ParagraphTitleAndDescription on ParagraphTitleAndDescription {
  id
  uuid
  entityBundle
  sectionTitle
  title
  description {
    value
  }
  text
  theme {
      entity {
        name
        classes
      }
  }  
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
}
`