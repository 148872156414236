export default `
fragment ParagraphLibraryItem on ParagraphLibraryItem {
    uuid
    entityBundle
    paragraphs {
        entity {
            ...AllParagraphs
        }
    }
}
`