import React, { useRef, useEffect, useState, useContext } from 'react'
import { get } from 'lodash'
import { ReactReduxContext, useSelector, useDispatch } from 'react-redux'
import { useLocation, useHistory, Redirect } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import isEqual from 'lodash.isequal'
import { isClient } from 'helpers/tools'
import CustomPages from 'components/templates/CustomPages'
import ErrorPages from 'components/templates/ErrorPages'
import { getData } from 'store/modules/customPage/actions'
import { getMenuName } from 'helpers/auth'

const useScrollTo = (hash, elem) => {
  setTimeout(() => {
    if (!!hash) {
      elem?.scrollIntoView()
    } else {
      try {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }    
      catch (error) {
        elem?.scrollIntoView()
      } 
    }
  }, 0)
}

const CustomPage = ({ user, refs }) => {
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const pageRef = useRef()
  const [firstLoad, setFirstLoad] = useState(true)
  const require_login = location.pathname.indexOf('/app') === 0 ? true : false
  const { data, dataError, loading } = useSelector(({ customPage }) => customPage, isEqual)
  const isLogged = Boolean(user || user?.setUserProfile)
  const menu_name = getMenuName(user)

  let metaTags = data?.route?.entity?.metaTags
  let metaTitle = ''

  useEffect(async () => {
    useScrollTo(location.hash, pageRef.current)
    if (require_login && !isLogged) {
      history.push(`/login?path=${location.pathname}`)
    } else if ((require_login && isLogged) && (user || get(user, 'setUserProfile'))) {
      const profileActive = get(user, 'active')
      const profileActiveBenner = get(user, 'active_benner')
      const profileId = get(user, 'profile_id')
      if (!profileId || !profileActive || !profileActiveBenner) {
        require_login && history.push('/change-profile')
      }
    } else {
      getMeta()
    }
  }, [location])

  useEffect(() => {
    if (user || get(user, 'setUserProfile')) {
      const profileActive = get(user, 'active')
      const profileActiveBenner = get(user, 'active_benner')
      const profileId = get(user, 'profile_id')
      if (!profileId || !profileActive || !profileActiveBenner) {
        require_login && history.push('/change-profile')
      } else {
        if (!firstLoad) {
          const payload = {
            pathname: location.pathname,
            search: location.search,
            menu_name: menu_name,
            store: store,
          }
          dispatch(getData(payload))
          getMeta()
        }
      }
    }
    setFirstLoad(false)
  }, [user])

  const getMeta = () => {
    let metaOgTitle = ''
    let metaDescription = ''
    let metaOgDescription = ''
    let twitterCreator = ''
    let twitterTitle = ''
    let twitterDescription = ''
    let meta = []

    metaTags?.map((item) => {
      if (item.key === 'title') metaTitle = item?.value
      if (item.key === 'description') metaDescription = item?.value
    })

    return [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: metaOgTitle,
      },
      {
        property: `og:description`,
        content: metaOgDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: twitterCreator,
      },
      {
        name: `twitter:title`,
        content: twitterTitle,
      },
      {
        name: `twitter:description`,
        content: twitterDescription,
      },
    ].concat(meta)
  }

  let parsedMeta = getMeta()
  if (dataError) return <ErrorPages errors={dataError} user={user} refs={refs} />
  if (data?.route) {
    const { redirect_url, status_code } = data?.route
    if (!user && status_code === '403') {
      return <Redirect to={`/login?path=${location.pathname}`} />
    }
    if (status_code?.match(/40[0-9]/)) {
      // Redirect caso a Home de algum perfil não exista no Drupal será redirecionado para home padrão
      if (location.pathname.match(/app\/home-.+/g)) {
        return <Redirect to="/app" />
      }
      return <ErrorPages status={status_code} errors={dataError} user={user} refs={refs} />
    }
    if (status_code?.match(/30[0-9]/)) {
      return <Redirect to={redirect_url} />
    }
    return (
      <div ref={pageRef}>
        {isClient() && loading && (
          <div className="loading-page">
            <Spinner className="loading-page__spinner" size="lg" animation="border" variant="white" />
          </div>
        )}
        <Helmet defaultTitle={metaTitle} meta={parsedMeta} />
        <CustomPages data={!loading ? data : null} user={user} refs={refs} />
      </div>
    )
  }
  return <ErrorPages status={404} user={user} refs={refs} />
}

export default CustomPage
