import { gql } from 'graphql-request'

export default gql`
  query getRecipientsDate {
    applications {
      pinSSData {
        recipientsDates {
          data {
            date
            month_year
          }
        }
      }
    }
  }
`