export default `
fragment ParagraphContentList on ParagraphContentList {
  id
  uuid
  entityBundle
  title
  grouped
  viewPdf
  description {
    value
  }
  theme {
    entity {
      name
      classes
    }
  }  
  pages {
    entity {
      ... on NodeDownload {
        uuid
        title
        created
        url {
          path
        }
        media {
          entity {
            ...Media
          }
        }
        absoluteLink {
          title
          url {
            path
          }
        }
      }
      ... on NodePage {
        uuid
        title
        created        
        url {
          path
        }
        media {
          entity {
            ...Media
          }
        }        
      }      
    }
  }  
}
`
