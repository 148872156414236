import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accessibility from 'components/molecules/Accessibility'
import FastAccess from 'components/molecules/FastAccess'

function AccessibilityBar(props) {
  const { refs } = props
  return (
    <Container id="toolbar" fluid="fluid" className="bg-green-dark-300">
      <Container>
        <Row className="py-1">
          <Col md={0} lg={6} className="pl-0">
            <FastAccess refs={refs} />
          </Col>
          <Col sm={12} lg={6} className="text-md-right pr-0">
            <Accessibility />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default AccessibilityBar
