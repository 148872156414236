import React from "react"
import Accordion from 'components/molecules/Accordion'
import Links from 'components/molecules/Links'
import get from 'lodash.get'
import "./LinksFooter.scss"

function LinksFooter(props) {
  return (
    <>
      <section id="footermenu" className="LinksFooterDesk d-none d-lg-flex">
        {Array.isArray(props.links) && props.links.map((item, index) => (
          <div id={item.id} key={index} className="LinksFooterDesk__links-footer-container">
            <h4 className="LinksFooterDesk__no-classname mt-md-0 mt-3">
              {get(item, 'label')}
            </h4>

            <Links
              ulClass="LinksFooterDesk__no-classname"
              liClass="LinksFooterDesk__no-classname"
              links={get(item, 'links')}
            />
          </div>
        ))}
      </section>
      <Accordion
        className="d-lg-none"
        items={get(props, 'links')}
        type="footer"
      />
    </>
  )
}

export default LinksFooter
