import { gql } from 'graphql-request'

export default gql`
query getReportLogs($userCode: String!) {
  applications {
    paymentDemonstrative {
      reportLogsPerDay(userCode: $userCode) {
        total
        total_success
        total_processing
        total_waiting
        total_errors
        logs {
          success {
            request_date
            request_task
            pwbus_correlation_id
            response {
              error
              data_loaded
              data_loaded_time
              pdf_report
              pdf_report_time
              xls_report
              xls_report_time
              xml_report
              xml_report_exchange
              xml_report_time
              total_time
            }
            created_at
            updated_at
          }
          waiting {
            request_date
            request_task
            pwbus_correlation_id
            response {
              error
              data_loaded
              data_loaded_time
              pdf_report
              pdf_report_time
              xls_report
              xls_report_time
              xml_report
              xml_report_exchange
              xml_report_time
              total_time
            }
            created_at
            updated_at
          }
          processing {
            request_date
            request_task
            pwbus_correlation_id
            response {
              error
              data_loaded
              data_loaded_time
              pdf_report
              pdf_report_time
              xls_report
              xls_report_time
              xml_report
              xml_report_exchange
              xml_report_time
              total_time
            }
            created_at
            updated_at
          }
          errors {
            request_date
            request_task
            pwbus_correlation_id
            response {
              error
              data_loaded
              data_loaded_time
              pdf_report
              pdf_report_time
              xls_report
              xls_report_time
              xml_report
              xml_report_exchange
              xml_report_time
              total_time
            }
            created_at
            updated_at
          }
        }
      }
    }
  }
}
`