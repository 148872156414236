import {
    GET_REFERENCED_LIST,
    REFERENCED_LIST_LOADING,
    REFERENCED_LIST_DATA,
    REFERENCED_LIST_ERROR,
} from 'store/constants'

export const initialState = {
    referencedListData: {},
    referencedListError: false,
    orderreferencedList: {},
    loading: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_REFERENCED_LIST:
            return { ...state, referencedListData: action.payload }
        case REFERENCED_LIST_DATA:
            return { ...state, referencedListData: action.payload }
        case REFERENCED_LIST_LOADING:
            return { ...state, loading: action.payload }
        case REFERENCED_LIST_ERROR:
            return { ...state, referencedListError: action.payload }
        default:
            return state
    }
}