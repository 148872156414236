export default `
  fragment ParagraphStaffGallery on ParagraphStaffGallery {
    id
    uuid
    entityBundle
    title
    description {
      value
    }
    staffs {
      entity {
        ... on NodeStaff {
          uuid
          title
          subtitle
          body {
            value
          }
          media {
            entity {
              ...Media
            }
          }
        }
      }
    }
  }
`
