import React from 'react'
import Helmet from 'react-helmet'

import Routes from './routes'
import globalStyles from 'styles/global.scss'

const App = () => {
  return (
    <>
      <Helmet>
        <style type="text/css">{globalStyles.toString()}</style>
      </Helmet>
      <Routes />
    </>
  )
}

export default App
