export default`
fragment ParagraphInformationCard on ParagraphInformationCard {
  id
  sectionTitle
  title
  subtitle
  entityBundle
  colorPalette {
    entity {
      name
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  media {
    entity {
      ...Media
    }
  }
  button {
    entity {
      ...ParagraphButton
    }
  }
}
`