export default `
fragment ParagraphDescriptionWithIcon on ParagraphDescriptionWithIcon {
  id
  uuid
  entityBundle
  description {
    value
  }
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
}`