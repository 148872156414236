import { setToken } from 'store/modules/token/actions'
import { setUser } from 'store/modules/auth/actions'
import { isClient } from 'helpers/tools'
import axios from 'axios'

const xauthorization = 'Basic dWNfc3RhZ2luZzo4bmtrS0VCeUo4dFN4OHV0'

const isDev = process.env.HOST_ENV === 'local' || process.env.HOST_ENV === 'develop' ? true : false
const ttl_token = !isDev ? 30 : 5
const ttl_refresh_token = !isDev ? 28800 : 240

export const validateToken = async (store) => {
  let auth = await store?.getState()?.token?.token
  if (!auth) return false
  auth = typeof auth === 'string' ? JSON.parse(auth) : auth
  const last_request = new Date(auth.last_request)
  const now = new Date()
  const expires_in = auth?.expires_in
  const ttl = expires_in ? expires_in - Math.round((now.getTime() - last_request.getTime()) / 1000) : 0
  console.log('TTL', ttl)
  if (ttl > ttl_token) {
    return { token: auth.access_token, refresh: false, expired: false }
  } else {
    return validateRefreshToken(store)
  }
}
export const validateRefreshToken = async (store) => {
  let auth = await store?.getState()?.token?.token
  if (!auth) return false
  auth = typeof auth === 'string' ? JSON.parse(auth) : auth
  const last_request = new Date(auth.last_request)
  const now = new Date()
  const ttl = ttl_refresh_token - Math.round((now.getTime() - last_request.getTime()) / 1000)
  if (ttl > ttl_token) {
    return { token: auth.refresh_token, refresh: true, expired: false }
  } else {
    console.log('TTL EXPIRED')
    await invalidateUser(store)
    return { token: auth.refresh_token, refresh: false, expired: true }
  }
}

export const invalidateUser = async (store, res) => {
  if (isClient()) {
    await axios.get('/logout')
  } else if (res) {
    res.clearCookie('token')
    res.clearCookie('auth')
  }
  await store.dispatch(setToken(null))
  await store.dispatch(setUser(null))
}

export const getMenuName = (auth) => {
  if (auth?.menu) {
    if (auth?.roles.length) {
      const menuRoles = auth.roles.sort().join('+')
      return menuRoles
    } else {
      return auth.menu
    }
  } else {
    return 'mainmenu'
  }
}

export const refreshTokenApi = async (token, store) => {
  try {
    const api = new axios.create({
      headers: {
        axios: true,
        xauthorization,
      },
    })
    const absolutePath = isClient() ? '' : 'http://web:3000'
    const response = await api.post(absolutePath + '/fetch/refresh_token', { refresh_token: token })
    if (response?.data?.token) {
      await store.dispatch(setToken(response.data.token))
      return response.data
    }
  } catch (error) {
    await invalidateUser(store)
    return false
  }
}

export const allowedOrigins = [
  'http://127.0.0.1:3000',
  'http://localhost:3000',
  'http://localhost:9090',
  'http://local.unimedcuritiba.com.br',
  'https://local.unimedcuritiba.com.br',
  'https://develop.unimedcuritiba.com.br',
  'https://drupal-develop.unimedcuritiba.com.br',
  'https://staging.unimedcuritiba.com.br',
  'https://drupal-staging.unimedcuritiba.com.br',
  'https://production.unimedcuritiba.com.br',
  'https://drupal-production.unimedcuritiba.com.br',
  'https://unimedcuritiba.com.br',
  'https://wwww.unimedcuritiba.com.br',
]
