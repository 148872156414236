export default `
fragment ParagraphFaqList on ParagraphFaqList {
  id
  uuid
  entityBundle
  title
  description {
    value
    format
  }
  category {
    entity {
      name
      tid
      faqs {
        entities {
          ...on NodeFaq {
            title
            created
            url {
              path
            }
          }
        }
      }    
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
}
`