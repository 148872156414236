export default `
fragment ParagraphTextAndLinkWithRightImage on ParagraphTextAndLinkWithRightImage {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  link {
    title
    url {
      path
    }
  }
  media {
    entity {
      ...Media
    }
  }
  news {
    items {
      title
      promote
      body {
        value
        summary
      }
      url {
        path
      }
      media {
        entity {
          ...Media
        }
      }
      created
      updated
    }
  }
}
`
