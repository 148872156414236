import { gql } from 'graphql-request'

export default gql`
query getReferencedList($contract: String!, $st: String, $pending: String) {
  applications {
    referencedList {
      referencedList(contract: $contract, st: $st, pending: $pending) {
        reports {
          file
          file_extension
          contract
          date
        }
      }
    }
  }
}`
