import React, { useEffect } from 'react'
import ClassNames from 'classnames'
import Typography from 'components/atoms/Typography'
import CustomButton from 'components/atoms/CustomButton'
import PropTypes from 'prop-types'
import get from 'lodash.get'

import './CustomModal.scss'

function CustomModal(props) {
  const footer = get(props, 'footer')
  const open = get(props, 'open')
  const minimizeMobile = get(props, 'minimizeMobile')
  const alignCenter = get(props, 'alignCenter')
  const size = get(props, 'size')
  const closeModal = get(props, 'closeModal')
  const title = get(props, 'title')
  const children = get(props, 'children')
  const className = get(props, 'className')

  useEffect(() => {
    // Close modal on press ESC
    const close = (e) => {
      if (e.keyCode === 27 && open) {
        closeModal()
      }
    }

    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [open])

  return (
    <div
      className={ClassNames('custom-modal', className, size || 'sm', {
        minimizeMobile: minimizeMobile,
        open: open,
        center: alignCenter,
      })}
    >
      <div className="custom-modal__overlay overlay" onClick={closeModal} />

      <div className={ClassNames('custom-modal__container', { 'with-footer': get(footer, 'button') })}>
        {title ? (
          <header className="custom-modal__header custom-modal__header--title">
            <button type="button" className="custom-modal__arrow-close" aria-label="Fechar" onClick={closeModal}>
              <i className="icone-arrow-left" />
            </button>

            <Typography variant="h2" color="primary" cssClass="custom-modal__title">
              {title}
            </Typography>

            <button type="button" className="custom-modal__close" onClick={closeModal}>
              <i className="icone-close-book" />
            </button>
          </header>
        ) : (
          <header className="custom-modal__header">
            <button type="button" className="custom-modal__close--display" onClick={closeModal}>
              <i className="icone-close-book custom-modal__icon" />
              <Typography color="gray-100" variant="span" cssClass="custom-modal__close-text ml-1">
                Fechar
              </Typography>
            </button>
          </header>
        )}

        <section className="custom-modal__content">{children}</section>

        {footer && (
          <footer className="custom-modal__footer">
            {get(footer, 'closeButton') && (
              <CustomButton
                className="custom-modal__footer-close"
                color="outline-primary"
                text="Fechar"
                onClick={closeModal}
              />
            )}

            {get(footer, 'button') && (
              <CustomButton
                className="custom-modal__footer-button"
                text={get(footer, 'button.text')}
                onClick={get(footer, 'button.action')}
                icon="diagonal-arrow-right-up"
                iconAlign={get(footer, 'iconAlignment')}
              />
            )}
          </footer>
        )}
      </div>
    </div>
  )
}

CustomModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  alignCenter: PropTypes.bool,
  title: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  minimizeMobile: PropTypes.bool,
  footer: PropTypes.object,
}

export default CustomModal
