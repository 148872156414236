export default `
fragment ParagraphBennerApplication on ParagraphBennerApplication {
    uuid
    entityBundle
    url
    title
    description {
      value
    }
    profile {
      entity {
        ... on TermBennerProfile {
          name
          code
        }
      }
    }
    external
  }
`