import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import isEqual from 'lodash.isequal'
import SVG from 'react-inlinesvg'
import BasicBanner from 'components/organisms/BasicBanner'
import Footer from 'components/organisms/Footer'
import AccessibilityBar from 'components/organisms/AccessibilityBar'
import { SessionExpired } from './components'

// Styles
import './ErrorPages.scss'
import Logotipo from 'assets/img/logotipo-unimed-curitiba.png'
import { isClient } from 'helpers/tools'

const ErrorPages = ({ status, errors, user, refs }) => {
  const history = useHistory()
  const { loading } = useSelector(({ customPage }) => customPage, isEqual)
  const responseStatus = status || get(errors[0], 'details.response_status')
  const location = useLocation()
  const logged = location.pathname.indexOf('/app') === 0 ? true : false
  const data = {
    uuid: `error-${responseStatus}`,
    ...getMessageByStatus(responseStatus),
    form_search: true,
    media: {
      entity: {
        images: {
          medium: {
            url: '/sites/default/files/public/styles/medium/public/2021-02/family_banner.jpg.webp',
          },
          wide: {
            url: '/sites/default/files/public/styles/wide/public/2021-02/family_banner.jpg.webp',
          },
        },
      },
    },
  }

  function getMessageByStatus(status) {
    switch (status) {
      case '404':
        return { title: 'Ooops!! Página não encontrada!' }
      case '403':
        return {
          title: 'Sem permissão de acesso',
          message:
            'A sua sessão expirou, provavelmente devido a inatividade. <br /> Para continuar acessando <b>volte para a home</b> ou <b>faça login</b> novamente!',
        }
      case '401':
        return { title: 'Sem conexão' }
      default:
        return { title: 'Oops!!! Algo deu errado...' }
    }
  }

  return (
    <>
      {isClient() && loading && (
        <div className="loading-page">
          <Spinner className="loading-page__spinner" size="lg" animation="border" variant="white" />
        </div>
      )}
      {responseStatus !== '401' && (
        <header ref={refs.header}>
          <BasicBanner
            action={{ text: 'Voltar para home', url: user ? '/app' : '/' }}
            data={data}
            logged={logged}
            user={user}
            refs={refs}
          />
        </header>
      )}
      {responseStatus === '401' && !loading && (
        <>
          <header>
            <AccessibilityBar refs={refs} />
          </header>
          <main ref={refs.content}>
            <Container className="mt-5 mb-5 error-page">
              <Row>
                <Col>
                  <div className="logo">
                    <SVG
                      className="icon"
                      src={Logotipo}
                      preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <SessionExpired data={data} history={history} />
                </Col>
              </Row>
            </Container>
          </main>
        </>
      )}
      <footer>
        <Footer refs={refs} errorPage={true} />
      </footer>
    </>
  )
}

ErrorPages.propTypes = {
  status: PropTypes.any,
  errors: PropTypes.any,
  user: PropTypes.any,
  refs: PropTypes.any,
}

export default ErrorPages
