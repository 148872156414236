import {
	GET_RECIPIENTS,
	RECIPIENTS_ERROR,
	RECIPIENTS_DATA,
	RECIPIENTS_LOADING,
	UPDATE_RECIPIENT_DATA,
	UPDATE_RECIPIENT_ERROR,
	UPDATE_RECIPIENT_LOADING,

} from 'store/constants'

export const initialState = {
	recipients: {},
	recipientsData: null,
	recipientsError: null,
	loading: false,
	updateRecipient: {},
	errorUpdateRecipient: null,
	loadingUpdatedRecipient: false
}

export default (state = initialState, action) => {
	switch (action.type) {
		case GET_RECIPIENTS:
			return { ...state, recipients: action.payload }
		case RECIPIENTS_DATA:
			return { ...state, recipientsData: action.payload.applications.civilAction.civilActionsList.civil_action }
		case RECIPIENTS_LOADING:
			return { ...state, loading: action.payload }
		case RECIPIENTS_ERROR:
			return { ...state, recipientsError: action.payload }
		case UPDATE_RECIPIENT_DATA:
			return { ...state, updateRecipient: action.payload.applications.civilAction.civilActionUpdate.civil_action }
		case UPDATE_RECIPIENT_ERROR:
			return { ...state, errorUpdateRecipient: action.payload }
		case UPDATE_RECIPIENT_LOADING:
			return { ...state, loadingUpdatedRecipient: action.payload }
		default:
			return state
	}
}
