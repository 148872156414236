import apiClient from 'api/config/apiClient'

export default {
  getIpAddress(values) {
    return apiClient.request('cache-disable/get_ip', values)
  },

  getUser(values) {
    return apiClient.request('get_user', values)
  },

  getUserProfiles(values) {
    return apiClient.request('get_user_profiles', values)
  },

  setUserProfile(values) {
    return apiClient.request('set_user_profile', values)
  },

  grantAccessUser(values) {
    return apiClient.request('grant_access_user', values)
  },

  removeAccessUser(values) {
    return apiClient.request('remove_access_user', values)
  },

  setUserDefaultProfile(values) {
    return apiClient.request('set_user_default_profile', values)
  },

  registerUser(values) {
    return apiClient.request('add_user', values)
  },

  resetPassword(values) {
    return apiClient.request('reset_password', values)
  },

  rememberPassword(values) {
    return apiClient.request('remember_password', values)
  },

  rememberEmail(values) {
    return apiClient.request('remember_password', values)
  },

  checkUserAndAddProfile(values) {
    return apiClient.request('check_user_and_add_profile', values)
  },

  activateUser(values) {
    return apiClient.request('activate_user', values)
  },

  removeSharedProfile(values) {
    return apiClient.request('remove_shared_profile', values)
  },

  sendFormSomethingWrong(values) {
    return apiClient.request('something_wrong', values)
  },

  validateActivationToken(values) {
    return apiClient.request('validate_activation_token', values)
  },

  sendActivationLink(values) {
    return apiClient.request('send_activation_link', values)
  },
}
