import React from 'react'
import PropTypes from 'prop-types'
import NavMobileLink from 'components/atoms/NavMobileLink'
import get from 'lodash.get'
function NavMobileList(props) {
  const { links, label } = props

  return (
    <details className="navbar-menu-drop">
      <summary>{label}</summary>

      {Array.isArray(links) && links.length > 0 && (
        <nav aria-label="Lista de navegação mobile">
          <ul>
            {Array.isArray(links) && links.map(item => (
              <li key={item.label}>
                <NavMobileLink url={get(item, 'url.path')} label={item.label} links={item.links} />
              </li>
            ))}
          </ul>
        </nav>
      )}
    </details>
  )
}

NavMobileList.propTypes = {
  links: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
}

export default NavMobileList