import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import LinkHandler from 'components/atoms/LinkHandler'
import './ImageLink.scss'

function ImageLink({ className, link, image }) {
  return (
    <figure className={ClassNames('image-link my-0', className)}>
      <LinkHandler to={link?.url} type={link?.type}>
        <img className="image-link__image" src={image?.src} alt={image?.alt} />
      </LinkHandler>
    </figure>
  )
}

ImageLink.propTypes = {
  link: PropTypes.exact({
    url: PropTypes.string,
    type: PropTypes.string,
  }),
  image: PropTypes.exact({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
}

export default ImageLink
