import React from 'react'
import { useHistory } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { Button } from 'react-bootstrap'
import get from 'lodash/get'

import Typography from 'components/atoms/Typography'
import sadSmile from 'assets/img/sad-smile-chat-box.svg'
import ReactHtmlParser from 'react-html-parser'

// Styles
import './AccessDenied.scss'

function AccessDenied({ data }) {
  const history = useHistory()
  const title = get(data, 'title')
  const message = get(data, 'message')

  return (
    <section className="access-denied">
      <div className="content">
        <div className="login-header">
          <div className="container">
            <Typography variant="p" cssClass="title" color="danger">
              {title || 'Acesso negado!'}
            </Typography>
          </div>
        </div>
        <div className="access-denied-body">
          <SVG
            className="icon"
            src={sadSmile}
            preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
            width="100%"
            height="100%"
          />
          <span className="text mb-4">
            {ReactHtmlParser(
              message ||
                'Você não tem acesso a essa área. Para suporte técnico ou mais informações, entre em contato pelo e-mail:'
            )}
          </span>

          <div className="cta">
            <Button
              type="button"
              variant="primary"
              size="lg"
              className="login mx-2 d-flex"
              onClick={() => {
                history.go(0)
              }}
            >
              <span>Voltar para a tela anterior</span>
              <span>
                <i className="icone-arrow-right" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccessDenied
