import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* pinSSUtilization({ payload }) {
  try {
    yield put({ type: constants.GET_PIN_UTILIZATION_LOADING, payload: true })

    const res = yield call(api.getPinSSUtilization, payload)

    yield put({
      type: constants.GET_PIN_UTILIZATION_DATA,
      payload: res,
    })

    yield put({ type: constants.GET_PIN_UTILIZATION_LOADING, payload: false })
    yield put({ type: constants.GET_PIN_UTILIZATION_ERROR, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_PIN_UTILIZATION_ERROR, payload: e.message })
    yield put({ type: constants.GET_PIN_UTILIZATION_LOADING, payload: false })
  }
}

function* getRecipientsDate({ payload }) {
  try {
    yield put({ type: constants.GET_RECIPIENTS_DATE_LOADING, payload: true })

    const res = yield call(api.getRecipientsDate, payload)

    yield put({
      type: constants.GET_RECIPIENTS_DATE_DATA,
      payload: res,
    })

    yield put({ type: constants.GET_RECIPIENTS_DATE_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.GET_RECIPIENTS_DATE_ERROR, payload: e.message })
    yield put({ type: constants.GET_RECIPIENTS_DATE_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_PIN_UTILIZATION, pinSSUtilization)
  yield takeLatest(constants.GET_RECIPIENTS_DATE, getRecipientsDate)
}
