import {
  GET_RECIPIENT_DETAILS,
  GET_RECIPIENT_DETAILS_DATA,
  GET_RECIPIENT_DETAILS_LOADING,
  GET_RECIPIENT_DETAILS_ERROR,
  GET_RECIPIENTS_LIST,
  GET_RECIPIENTS_LIST_DATA,
  GET_RECIPIENTS_LIST_LOADING,
  GET_RECIPIENTS_LIST_ERROR,
} from 'store/constants'

export const initialState = {
  recipientsListData: {},
  recipientsListError: false,
  recipientsListLoading: false,
  recipientDetailsData: {},
  recipientDetailsError: false,
  recipientDetailsLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECIPIENT_DETAILS:
      return { ...state, recipientDetailsData: action.payload }
    case GET_RECIPIENT_DETAILS_DATA:
      return { ...state, recipientDetailsData: action.payload }
    case GET_RECIPIENT_DETAILS_LOADING:
      return { ...state, recipientDetailsLoading: action.payload }
    case GET_RECIPIENT_DETAILS_ERROR:
      return { ...state, recipientDetailsError: action.payload }

    case GET_RECIPIENTS_LIST:
      return { ...state, recipientsListData: action.payload }
    case GET_RECIPIENTS_LIST_DATA:
      return { ...state, recipientsListData: action.payload }
    case GET_RECIPIENTS_LIST_LOADING:
      return { ...state, recipientListLoading: action.payload }
    case GET_RECIPIENTS_LIST_ERROR:
      return { ...state, recipientsListError: action.payload }

    default:
      return state
  }
}
