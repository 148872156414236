export default `
    fragment ParagraphTextCarouselWithImage on ParagraphTextCarouselWithImage {
      id
      uuid
      entityBundle
      media {
        entity {
          ...Media
        }
      }
      theme {
        entity {
          name
          classes
        }
      }
      items {
        entity {
          ...ParagraphTitleAndDescription
        }
      }
    }
`
