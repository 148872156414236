import React, { useState, useEffect, useRef } from 'react'
import { HashLink } from 'react-router-hash-link'
import { startsWithHttp } from 'helpers/strings'
import PropTypes from 'prop-types'

import CustomModal from 'components/molecules/CustomModal'
import ExternalLinkRedirect from 'components/molecules/ExternalLinkRedirect'
import './LinkHandler.scss'

function LinkHandler({ ariaLabel, target, to, children, className, onClick, onCloseModal, id, type }) {
  const [modalOpened, setModalOpened] = useState(false)
  const [dontShowAgain, setDontShowAgain] = useState(false)
  const externalLink = startsWithHttp(to) || type === 'external'
  const buttonRef = useRef(null)
  const [sameOriginUrl, setSameOriginUrl] = useState(false)

  useEffect(() => {
    let dontShowRedirectModal = localStorage.getItem('dont-show-redirect-modal')

    // Creates local storage item if it doesnt exists
    if (dontShowRedirectModal === undefined || dontShowRedirectModal === null) {
      localStorage.setItem('dont-show-redirect-modal', false)
      dontShowRedirectModal = false
    }
    setSameOriginUrl(to?.search(location?.hostname) !== -1)
    setDontShowAgain(dontShowRedirectModal == true)
  }, [])

  const openModal = (val) => {
    setModalOpened(val)
  }

  const redirectUser = () => {
    localStorage.setItem('dont-show-redirect-modal', dontShowAgain)
    if (onClick) onClick()
    if (to) window.open(to, '_blank')
    setModalOpened(false)
  }

  const handleHashLinkClick = (e) => {
    if (to?.includes(`#`)) {
      const pathname = to.split('#')[0]
      const hash = to.split('#')[1]
      if ((!pathname || window.location.pathname === pathname) && hash) {
        e.preventDefault()
        document.querySelector(`#${hash}`)?.scrollIntoView()
      }
    }

    if (onClick) onClick()
  }

  const handleClickExternalLink = () => {
    !dontShowAgain ? openModal(true) : window.open(to, '_blank')
  }

  const handleCloseModal = () => {
    if (onCloseModal) onCloseModal()
    openModal(false)
  }

  // Slick slider uses transform to slide the elements.
  // Transform atribute has to be remove in other to open the redirect modal correctly.
  useEffect(() => {
    const externalLinkButton = buttonRef.current
    if (externalLinkButton) {
      const slickTrack = externalLinkButton.closest('.slick-track')
      const slickSlider = externalLinkButton.closest('.slick-list')
      if (modalOpened) {
        if (slickTrack) slickTrack.style.transform = 'none'
        if (slickSlider) slickSlider.style.transform = 'none'
      } else {
        if (slickTrack) slickTrack.style.transform = 'translate3d(0, 0, 0)'
        if (slickSlider) slickSlider.style.transform = 'translate3d(0, 0, 0)'
      }
    }
  }, [modalOpened])

  if (sameOriginUrl) {
    return (
      <a
        aria-label={ariaLabel}
        className={className}
        target={target || (externalLink ? '_blank' : '_self')}
        href={to}
        id={id}
        onClick={handleHashLinkClick}
      >
        {children}
      </a>
    )
  }

  if (externalLink) {
    return (
      <>
        <button
          id={id}
          className={`external-link-button ${className}`}
          onClick={handleClickExternalLink}
          ref={buttonRef}
          aria-label={ariaLabel}
        >
          {children}
        </button>

        <CustomModal
          open={modalOpened}
          closeModal={handleCloseModal}
          alignCenter
          size="sm"
          title="Abrir nova página"
          footer={{
            closeButton: true,
            button: {
              text: 'Continuar',
              action: redirectUser,
            },
            iconAlignment: 'right',
          }}
        >
          <ExternalLinkRedirect dontShowAgain={setDontShowAgain} />
        </CustomModal>
      </>
    )
  }

  return (
    <HashLink
      aria-label={ariaLabel}
      className={className}
      id={id}
      target={target}
      to={to || ''}
      onClick={handleHashLinkClick}
    >
      {children}
    </HashLink>
  )
}

LinkHandler.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  type: PropTypes.string,
}

export default LinkHandler
