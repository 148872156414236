import React, { useState, useEffect } from 'react'

function ContrastControl() {
  const [accessibilityMode, setAccessibilityMode] = useState(false)
  const [contrast, setContrast] = useState(accessibilityMode)

  useEffect(() => {
    const html = document.querySelector('html')
    if (contrast) html.classList.add('contrast')
    else html.classList.remove('contrast')
    localStorage.setItem('acessibility-mode', contrast)
  }, [contrast])

  useEffect(() => {
    setAccessibilityMode(JSON.parse(localStorage.getItem('acessibility-mode')) ? true : false)
  }, [])

  return (
    <li>
      <button id="accessibility_high_contrast" onClick={() => setContrast(!contrast)}>
        <span>Contraste</span>
        <i className="icone-contrast ml-2" />
      </button>
    </li>
  )
}

export default ContrastControl
