import React from 'react'
import PropTypes from 'prop-types'
import ClassNames from 'classnames'
import LazyLoad, { forceCheck } from 'react-lazyload'

import './Image.scss'
function Image({
  alt,
  src,
  shadow,
  roundedCorners,
  size,
  fullImage,
  className,
  lazyloadClassName,
  height,
  width,
  openOnNewTab,
}) {
  forceCheck()

  function ImageComponent() {
    return (
      <LazyLoad className={lazyloadClassName} height={height}>
        <img
          alt={alt}
          className={ClassNames('ImageComponent', className, {
            'box-shadow': shadow,
            'only-media': roundedCorners,
            [`size${size}`]: size,
            'full-image': fullImage,
          })}
          src={src}
          height={height}
          width={width}
        />
      </LazyLoad>
    )
  }

  if (openOnNewTab) {
    return (
      <a href={src} target="_blank">
        <ImageComponent />
      </a>
    )
  }
  return <ImageComponent />
}

Image.defaultProps = {}

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string.isRequired,
  shadow: PropTypes.bool,
  roundedCorners: PropTypes.bool,
  size: PropTypes.string,
  fullImage: PropTypes.bool,
  className: PropTypes.string,
  lazyloadClassName: PropTypes.string,
  openOnNewTab: PropTypes.bool,
}

export default Image
