import React from 'react'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'

import Typography from 'components/atoms/Typography'

import './SimpleBigButton.scss'

function SimpleBigButton({ className, title, theme, selected, icon, onClick, useSvg }) {
  const selectedClass = selected ? 'active' : ''

  return (
    <button
      className={`simple-big-button card card-icon card-${theme} ${className} ${selectedClass} p-2 p-lg-3 max-line-2 text-left d-flex flex-column justify-content-between`}
      type="button"
      onClick={onClick}
    >
      {useSvg ? (
        <SVG
          src={icon}
          className="simple-big-button__icon mb-2"
          preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
        />
      ) : (
        <i className={`simple-big-button__icon mb-2 ${icon}`} />
      )}
      <Typography className="simple-big-button__title">{title}</Typography>
    </button>
  )
}

SimpleBigButton.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  title: PropTypes.string,
  theme: PropTypes.string,
  useSvg: PropTypes.bool,
}

export default SimpleBigButton
