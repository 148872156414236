export default `
fragment ParagraphDownloadButton on ParagraphDownloadButton {
  id
  uuid
  entityBundle
  title
  fieldMedia {
    entity {
      ...Media
    }
  }
}

`