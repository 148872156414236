import apiClient from 'api/config/apiClient'

export default {
  getData(values) {
    return apiClient.request('page', values)
  },
  getHeaderMenus(values) {
    return apiClient.request('menu', { ...values, name: 'mainmenu' })
  },
  getApplicationMenus(values) {
    return apiClient.request('menu', values)
  },
  getFooterMenus(values) {
    return apiClient.request('menu', { ...values, name: 'footermenu' })
  },
  getFooterSubMenus(values) {
    return apiClient.request('menu', { ...values, name: 'footersubmenu' })
  },
  getAccessMenus(values) {
    return apiClient.request('menu', { ...values, name: 'accessmenu' })
  },
  getPlans(values) {
    if (Array.isArray(values.categories) && values.categories.length > 0) {
      return apiClient.request('plan', values)
    } else {
      return apiClient.request('plan_segment', values)
    }
  },
  getPlanFilters(values) {
    return apiClient.request('planfilters', values)
  },
  search(values) {
    return apiClient.request('search', values)
  },
  getFooterData() {
    return apiClient.request('footer')
  },
}
