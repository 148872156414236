import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import { HashLink } from 'react-router-hash-link'
import { Form, FormControl } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import PropTypes from 'prop-types'
import SVG from 'react-inlinesvg'
import Slider from 'react-slick'
import { userCheckMobileScreen } from 'helpers/tools'
import { numberMask } from 'helpers/strings'

// Components
import Input from 'components/atoms/Input'
import CustomButton from 'components/atoms/CustomButton'
import { SimpleBigButton } from 'components/molecules/BigButton'
import ModalUnimedCard from 'components/templates/LoginPages/components/ModalUnimedCard'

// Style
import './PageSteps.scss'
import questionMark from 'assets/img/question-mark.svg'

function PageSteps({ className, onSubmit, isLoading }) {
  const [userType, setUserType] = useState('')
  const [validFields, setValidFields] = useState(false)
  const [exchange, setExchange] = useState(false)
  const [lastStep, setLastStep] = useState(false)
  const [fieldLabel, setFieldLabel] = useState('Código Unimed:')
  const [unimedCardModal, setUnimedCardModal] = useState(false)
  const [requiredPolicyPrivacy, setRequeridPolicyPrivacy] = useState(false)
  const [pjProfile, setPjProfile] = useState('')
  const [isUserTasy, setIsUserTasy] = useState(false)
  const [othersProfile, setOthersProfile] = useState('')
  const [isMobile, setIsMobile] = useState(false)

  const userTypes = [
    { icon: 'icone-smile', type: 'pf', title: 'Para você' },
    { icon: 'icone-briefcase', type: 'pj', title: 'Para sua empresa' },
    { icon: 'icone-customer-service', type: 'coop', title: 'Médico Cooperado' },
    { icon: 'icone-global-network', type: 'sinapse', title: 'Sinapse' },
    { icon: 'icone-people', type: 'others', title: 'Outros' },
  ]

  const [fieldsForm, setFieldsForm] = useState({
    card_number: '',
    name: '',
    birth_date: '',
    cpf: '',
    profile: '',
    policyPrivacy: requiredPolicyPrivacy,
  })

  const settings = {
    infinite: false,
    slidesToShow: 2,
    arrows: false,
    centerMode: true,
    variableWidth: true,
    dots: true,
    dotsClass: 'slick-dots primary',
  }

  useEffect(() => {
    setIsMobile(userCheckMobileScreen())
  }, [])

  function validateUserPF({ card_number, name, birth_date, cpf }) {
    // Exchange is when the card number starts with 0032
    if (exchange) {
      if (name && birth_date && cpf && card_number) {
        setValidFields(true)
      } else {
        setValidFields(false)
      }
    } else {
      if (name && birth_date && card_number) {
        setValidFields(true)
      } else {
        setValidFields(false)
      }
    }
  }

  function validateUserPJ({ login, cnpj, user_name }) {
    if (
      pjProfile && login && cnpj ||
      pjProfile && user_name
    ) {
      setValidFields(true)
    } else {
      setValidFields(false)
    }
  }

  function validateUserSinapse({ login, cnpj }) {
    if (login && cnpj) {
      setValidFields(true)
    } else {
      setValidFields(false)
    }
  }

  function validateOthers({ cpf, cnpj }) {
    if (othersProfile === 'responsavel_financeiro_pf') {
      if (cpf) setValidFields(true)
      else setValidFields(false)
    } else if (othersProfile) {
      if (cnpj) setValidFields(true)
      else setValidFields(false)
    } else {
      setValidFields(false)
    }
  }

  useEffect(() => {
    if (userType === 'pf') {
      validateUserPF(fieldsForm)
    }
    if (userType === 'pj') {
      validateUserPJ(fieldsForm)
    }
    if (userType === 'sinapse') {
      validateUserSinapse(fieldsForm)
    }
    if (userType === 'others') {
      validateOthers(fieldsForm)
    }
  }, [fieldsForm, othersProfile])

  function handleSubmit(e) {
    e.preventDefault()
    const form = new FormData(e.target)
    const formValues = {}
    formValues.card_number = form.get('card_number')
    formValues.profile = form.get('profile')
    formValues.name = form.get('name')
    formValues.cnpj = form.get('cnpj')
    formValues.user_name = form.get('user_name')?.replace(/[^0-9]/g, "")
    formValues.cpf = form.get('cpf')
    formValues.login = form.get('login') || form.get('cpf')?.replace(/\D/g, '') || form.get('cnpj')?.replace(/\D/g, '')
    formValues.contract = form.get('login')
    formValues.crm = form.get('crm')
    formValues.birth_date = form.get('birth_date')


    // Responsável Responsável Financeiro PJ e PF tem o mesmo nome de máquina
    if (userType === 'others') {
      if (othersProfile.match(/responsavel_financeiro/)) {
        formValues.type = 'responsavel_financeiro'
      } else {
        formValues.type = othersProfile
      }
    } else {
      formValues.type = userType
    }

    Object.keys(formValues).forEach((key) => (formValues[key] === (undefined || null) ? delete formValues[key] : {}))
    onSubmit(formValues)
  }

  function handleChangeCode({ target }, valid) {
    const field = target.name
    if (!valid) setFieldsForm((current) => ({ ...current, name: '', birth_date: '', cpf: '' }))
    else setFieldsForm((current) => ({ ...current, [field]: target.value }))
    setFieldsForm((current) => ({ ...current, [field]: target.value }))
    setLastStep(valid)
    setExchange(valid && numberMask(target.value).indexOf('0032') === 0)
  }

  function handleChangeField({ target: { name, value } }, valid) {
    if (valid || value === '') {
      setFieldsForm((current) => ({ ...current, [name]: value }))
    } else {
      setValidFields(false)
    }
  }

  function handleChangeProfile({ target: { value } }) {
    if (value.includes('tasy')) {
      setIsUserTasy(true)
    } else {
      setIsUserTasy(false)
      if (value === 'pj') {
        setFieldLabel('Código Unimed e Lotações:')
      } else {
        setFieldLabel('Código Unimed:')
      }
    }
    setPjProfile(value)
  }

  function handleChangeOtherProfiles({ target: { value } }) {
    setOthersProfile(value)
  }

  const handleChangeUserType = (type) => {
    setUserType(type)
    setValidFields(false)
  }

  function handleChangeEmployerNumber({ target: { value, name } }) {
    const employerNumber = value.replace(/\/|-|\./g, '').match(/\d+/g)
    const valid = employerNumber && employerNumber[0].length === 14

    if (valid || value === '') {
      setFieldsForm((current) => ({ ...current, [name]: value }))
    } else {
      setValidFields(false)
    }
  }

  function handleChangeMedicNumber({ target: { value } }) {
    const medicNumber = value.match(/\d+/g)
    setValidFields(medicNumber && medicNumber[0].length >= 2)
  }

  function handleClickQuestionMark() {
    setUnimedCardModal(true)
  }

  return (
    <Form
      className={classNames(className, 'page-steps flex-column d-lg-flex justify-content-center flex-wrap')}
      onSubmit={handleSubmit}
      validated={validFields && requiredPolicyPrivacy}
      noValidate
    >

      <ModalUnimedCard open={unimedCardModal} onClose={() => setUnimedCardModal(false)} />

      {isMobile ? (
        <Slider {...settings}>
          {userTypes.map(({ title, type, icon }) => (
            <SimpleBigButton
              icon={icon}
              onClick={() => handleChangeUserType(type)}
              selected={userType === type}
              key={type}
              title={title}
              theme="primary outline"
            />
          ))}
        </Slider>
      ) : (
        <div className="page-steps__simple-big-buttons d-flex align-self-center flex-wrap">
          {userTypes.map(({ title, type, icon }) => (
            <SimpleBigButton
              icon={icon}
              onClick={() => handleChangeUserType(type)}
              selected={userType === type}
              key={type}
              title={title}
              theme="primary outline"
            />
          ))}
        </div>
      )}
      <div className="container mt-4">
        {userType === 'pf' && (
          <>
            <Input
              label={
                <div className="d-flex justify-content-between" htmlFor="card_number">
                  <span>Digite o código da sua carteirinha:</span>
                  <button type="button" onClick={handleClickQuestionMark}>
                    <SVG src={questionMark}></SVG>
                  </button>
                </div>
              }
              validationType="cardNumber"
              required
              placeholder="0 000 000000000000 0"
              onChange={handleChangeCode}
              mask="1 111 111111111111 1"
              name="card_number"
            />
            {lastStep && (
              <>
                <Input className="mb-4" required name="name" label="Nome Completo:" onChange={handleChangeField} />
                <Input
                  as={MaskedInput}
                  className="mb-4"
                  label="Data de Nascimento:"
                  name="birth_date"
                  validationType="birthday"
                  placeholder="00/00/0000"
                  mask="11/11/1111"
                  onChange={handleChangeField}
                />
                {exchange && (
                  <Input
                    className="mb-4"
                    placeholder="000.000.000-00"
                    mask="111.111.111-11"
                    name="cpf"
                    validationType="cpf"
                    onChange={handleChangeField}
                    label="CPF do Titular do Plano:"
                  />
                )}
              </>
            )}
          </>
        )}
        {userType === 'others' && (
          <>
            <Form.Group>
              <Form.Label htmlFor="profile">Tipo de perfil:</Form.Label>
              <FormControl
                as="select"
                name="others_profile"
                onChange={handleChangeOtherProfiles}
                required
                isInvalid={validFields && !othersProfile}
              >
                <option value="">Selecione</option>
                <option value="responsavel_financeiro_pf">Responsável Financeiro PF</option>
                <option value="responsavel_financeiro_pj">Responsável Financeiro PJ</option>
                <option value="empresacobranca">Empresa Terceirizada</option>
              </FormControl>
            </Form.Group>
            {othersProfile === 'responsavel_financeiro_pf' && (
              <Input
                className="mb-4"
                placeholder="000.000.000-00"
                mask="111.111.111-11"
                name="cpf"
                validationType="cpf"
                onChange={handleChangeField}
                label="CPF:"
              />
            )}
            {(othersProfile === 'responsavel_financeiro_pj' || othersProfile === 'empresacobranca') && (
              <Input
                placeholder="00.000.000/0001-00"
                onChange={handleChangeEmployerNumber}
                label="CNPJ:"
                required
                mask="11.111.111/1111-11"
                validationType="cnpj"
                name="cnpj"
                className="mb-4"
              />
            )}
          </>
        )}
        {userType === 'pj' && (
          <>
            <Form.Group>
              <Form.Label htmlFor="profile">Tipo de perfil:</Form.Label>
              <FormControl
                as="select"
                name="profile"
                onChange={handleChangeProfile}
                required
                isInvalid={validFields && !pjProfile}
              >
                <option value="">Selecione</option>
                <option value="pj">Empresa Contratante</option>
                <option value="fornecedor">Fornecedor</option>
                <option value="prestador">Prestador</option>
                <option value="tasy_prestador_administrativo">Prestador - Perfil administrativo</option>
                <option value="tasy_prestador_comercial">Prestador - Perfil comercial</option>
                <option value="tasy_prestador_tecnico">Prestador - Perfil técnico</option>
              </FormControl>
              <Form.Control.Feedback type="invalid">Selecione uma das opções.</Form.Control.Feedback>
            </Form.Group>
            {!isUserTasy && (
              <>
                <Input label={fieldLabel} name="login" required onChange={handleChangeField} />
                <Input
                  placeholder="00.000.000/0001-00"
                  onChange={handleChangeEmployerNumber}
                  label="CNPJ:"
                  required
                  mask="11.111.111/1111-11"
                  validationType="cnpj"
                  name="cnpj"
                  className="mb-4"
                />
              </>
            )}
            {isUserTasy && (
              <Input
                placeholder="Digite seu username"
                label="Nome de usuário:"
                name="user_name"
                required
                onChange={handleChangeField}
              />
            )}
          </>
        )}
        {userType === 'coop' && (
          <>
            <Input className="mb-4" label="Código Unimed:" name="login" required />
            <Input label="CRM:" placeholder="00000" onChange={handleChangeMedicNumber} name="crm" required />
          </>
        )}
        {userType === 'sinapse' && (
          <>
            <Input className="mb-4" label="Código Cooperativa:" name="login" required onChange={handleChangeField} />
            <Input
              placeholder="00.000.000/0001-00"
              onChange={handleChangeEmployerNumber}
              label="CNPJ Cooperativa:"
              required
              mask="11.111.111/1111-11"
              validationType="cnpj"
              name="cnpj"
              className="mb-4"
            />
          </>
        )}
        {userType && (
          <>
            <Form.Group controlId="policyPrivacy" className="login-secondary-validated">
              <Form.Check
                type="checkbox"
                checked={requiredPolicyPrivacy}
                onChange={({ target }) => setRequeridPolicyPrivacy(target.checked)}
                required
                invalid={!requiredPolicyPrivacy}
                label={
                  <span>
                    Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou consentimento, quando
                    aplicável conforme descrito nesta{' '}
                    <HashLink to="/politica-de-privacidade" target="_blank">
                      Política de Privacidade
                    </HashLink>{' '}
                    e{' '}
                    <HashLink to="/termos-de-uso" target="_blank">
                      Termos de Uso
                    </HashLink>
                  </span>
                }
              ></Form.Check>
              <Form.Control.Feedback type="invalid">
                É necessário estar de acordo com a Política de Privacidade e os Termos de Uso.
              </Form.Control.Feedback>
            </Form.Group>
            <CustomButton
              className="mt-4 page-steps__submit"
              disabled={!validFields || !requiredPolicyPrivacy || isLoading}
              text="Continuar"
              noIcon
              type="submit"
              isLoading={isLoading}
            />
          </>
        )}
        <p className="text mt-4">
          Não sabe qual é o seu perfil? <HashLink to="/faq">Clique aqui e saiba mais</HashLink>
        </p>
      </div>
    </Form>
  )
}

PageSteps.propTypes = {
  className: PropTypes.string,
}

export default PageSteps
