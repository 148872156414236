import { capitalize } from 'lodash'

import {
  PAYMENT_DEMONSTRATIVE_DATA,
  GET_PAYMENT_DEMONSTRATIVE_ERROR,
  PAYMENT_DEMONSTRATIVE_LOADING,
  GET_PAYMENT_DEMONSTRATIVE_LOGS,
  GET_PAYMENT_DEMONSTRATIVE_LOGS_ERROR,
  PAYMENT_DEMONSTRATIVE_LOGS_DATA,
  PAYMENT_DEMONSTRATIVE_LOGS_LOADING
} from 'store/constants'

export const initialState = {
  status: {},
  docs: [],
  reportLogs: {},
  errorReportLogs: false,
  loadingReportLogs: false,  
  loadingPaymentDemonstrative: false,
  errorPaymentDemonstrative: false,
  reportsBeingGenerated: [],
  datesWithNoData: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_DEMONSTRATIVE_LOGS:
      return { ...state, reportLogs: action.payload }
    case PAYMENT_DEMONSTRATIVE_LOGS_DATA:      
      return { ...state, reportLogs: action.payload }
    case PAYMENT_DEMONSTRATIVE_DATA:
      const requisitionType = capitalize(action.payload.datepickerType)
      const pwbusPayloadDemonstrativeId =
        action.payload.applications.paymentDemonstrative[`reportsPer${requisitionType}`].pwbus_correlation_id
      const existingItemDemonstrativeIndex = state.docs.filter((item) => {
        if (item.applications.paymentDemonstrative[`reportsPer${requisitionType}`]) {
          return (
            item.applications.paymentDemonstrative[`reportsPer${requisitionType}`].pwbus_correlation_id ===
            pwbusPayloadDemonstrativeId
          )
        } else {
          return null
        }
      })

      if (!existingItemDemonstrativeIndex || existingItemDemonstrativeIndex.length === 0) {
        return { ...state, docs: [...state.docs, action.payload] }
      } else {
        let docs = state.docs
        docs[existingItemDemonstrativeIndex] = action.payload

        return { ...state, docs }
      }

    case GET_PAYMENT_DEMONSTRATIVE_ERROR:
      return { ...state, errorPaymentDemonstrative: action.payload }
    
    case GET_PAYMENT_DEMONSTRATIVE_LOGS_ERROR:
      return { ...state, errorReportLogs: action.payload }
    
    case PAYMENT_DEMONSTRATIVE_LOADING:
      return { ...state, loadingPaymentDemonstrative: action.payload }
    
    case PAYMENT_DEMONSTRATIVE_LOGS_LOADING:
      return { ...state, loadingReportLogs: action.payload }

    default:
      return state
  }
}
