import React, { useEffect, useState, useContext } from 'react'
import { ReactReduxContext, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Spinner, Button } from 'react-bootstrap'
import API_AUTH from 'store/modules/auth/api'
import SVG from 'react-inlinesvg'
import sadSmile from 'assets/img/sad-smile-chat-box.svg'
import smile from 'assets/img/icon-smile.svg'
import { invalidateUser } from 'helpers/auth'
import * as tokenActions from 'store/modules/token/actions'

// Components
import Typography from 'components/atoms/Typography'

import './ActivateProfile.scss'

function ActivateProfile(props) {
  let { userId, userToken } = useParams()
  const dispatch = useDispatch()
  const { store } = useContext(ReactReduxContext)
  const history = useHistory()

  const [isLoading, setIsLoading] = useState(false)
  const [message, setMessage] = useState(null)
  const [helpMessage, setHelpMessage] = useState(null)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    validateToken()
  }, [])

  const validateToken = async () => {
    try {
      setIsLoading(true)
      const res = await API_AUTH.validateActivationToken({ id: parseInt(userId), token: userToken })
      setMessage(res?.validateActivationToken?.message)
      setSuccess(res?.validateActivationToken?.success)

      if (!res?.validateActivationToken?.success) {
        if (res?.validateActivationToken?.message == 'Token expirado!') {
          setHelpMessage(
            'O link é válido por 24h, você pode gerar um novo link de ativação na sua área logada clicando no botão abaixo.'
          )
        } else {
          setHelpMessage(
            'O token é inválido, tente clicar novamente no link de ativação, ou gerar um novo link clicando no botão abaixo.'
          )
        }
      } else {
        setHelpMessage('Agora você pode utilizar o perfil cadastrado clicando no botão abaixo.')
      }
      await invalidateUser(store)
      dispatch(tokenActions.setToken(null))
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <section className="activate-profile">
      <div className="content">
        {isLoading && (
          <p className="my-5 text-center">
            <Spinner animation="border" />
          </p>
        )}
        {!isLoading && message && (
          <>
            <div className="login-header">
              <div className="container">
                <Typography variant="p" cssClass="title" color={success ? 'primary' : 'danger'}>
                  {message}
                </Typography>
              </div>
            </div>
            <div className="activate-profile-body">
              <div className="container">
                <p className="my-2 text-center">
                  {!success && (
                    <SVG
                      className="icon"
                      src={sadSmile}
                      preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                      width="100%"
                      height="100%"
                    />
                  )}
                  {success && (
                    <SVG
                      className="icon"
                      src={smile}
                      preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                      width="100%"
                      height="100%"
                    />
                  )}
                </p>

                <Typography variant="p" className="text-center my-4">
                  {helpMessage}
                </Typography>

                <div className="cta mt-5">
                  <Button
                    type="button"
                    variant="primary"
                    size="lg"
                    className="login m-auto"
                    onClick={() => {
                      history.push('/app')
                    }}
                  >
                    <span>Acessar</span>
                    <span>
                      <i className="icone-arrow-right" />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  )
}

export default ActivateProfile
