export default `
fragment ParagraphLatestInformative on ParagraphLatestInformative {
  id
  uuid
  entityBundle
  latestInformative {
    title
    uuid
    body {
      value
      summary
    }
  }
}
`
