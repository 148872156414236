import { graphQLClient } from 'api/config/graphql'
import { axiosClient } from 'api/config/axios'
import { isClient } from 'helpers/tools'
import queryMapping from 'server/queryMapping'

export default {
  request: async (url, values, options={}) => {
    if (isClient() || queryMapping[url].query === null) {
      const res = await axiosClient.request(url, values, queryMapping[url].method, options)
      if (res?.errors) {
        throw res?.errors
      }
      return res
    } else {
      return graphQLClient.request(queryMapping[url].query, values)
    }
  },
}
