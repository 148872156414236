import {
    GET_READJUSTMENT_STATEMENT,
    READJUSTMENT_STATEMENT_LOADING,
    READJUSTMENT_STATEMENT_DATA,
    READJUSTMENT_STATEMENT_ERROR,
    ORDER_READJUSTMENT_STATEMENT
} from 'store/constants'

export const initialState = {
    readjustmentStatementData: {},
    readjustmentStatementError: false,
    orderReadjustmentStatement: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_READJUSTMENT_STATEMENT:
            return { ...state, readjustmentStatementData: action.payload }
        case READJUSTMENT_STATEMENT_DATA:
            return { ...state, readjustmentStatementData: action.payload }
        case ORDER_READJUSTMENT_STATEMENT:
            return { ...state, orderReadjustmentStatement: action.payload }
        case READJUSTMENT_STATEMENT_LOADING:
            return { ...state, loading: action.payload }
        case READJUSTMENT_STATEMENT_ERROR:
            return { ...state, readjustmentStatementError: action.payload }
        default:
            return state
    }
}