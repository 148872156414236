import React from 'react'
import { Modal } from 'react-bootstrap'
import CustomButton from 'components/atoms/CustomButton'
import Typography from 'components/atoms/Typography'

function ConfirmModal({ text, onConfirm, onRefuse, open, onClose }) {
  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <Typography cssClass="title-book-md">Tem certeza?</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Typography>{text}</Typography>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex">
          <CustomButton className="mr-3" color="outline-primary" noIcon onClick={onRefuse} text="Fechar" />
          <CustomButton noIcon onClick={onConfirm} text="Continuar" className="mr-3" />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
