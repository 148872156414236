import {
    LOADING_WEBFORM_SUBMISSIONS,
    WEBFORM_SUBMISSIONS_DATA,
    ERROR_WEBFORM_SUBMISSIONS
} from 'store/constants'

export const initialState = {
    webformSubmissionsData: {},
    loadingWebformSubmissions: false,
    webformSubmissionsErrors: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case WEBFORM_SUBMISSIONS_DATA:
            return { ...state, webformSubmissionsData: action.payload }
        case ERROR_WEBFORM_SUBMISSIONS:
            return { ...state, webformSubmissionsErrors: action.payload }
        case LOADING_WEBFORM_SUBMISSIONS:
            return { ...state, loadingWebformSubmissions: action.payload }

        default:
            return state
    }
}
