import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEqual } from 'lodash'
import { useHistory } from 'react-router-dom'
import ReactHTMLParser from 'react-html-parser'
import { Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import * as customPageActions from 'store/modules/customPage/actions'
import LinkHandler from 'components/atoms/LinkHandler'

import formSearchStyles from './FormSearch.scss'

function FormSearch(props) {
  const history = useHistory()
  const query = new URLSearchParams(history.location.search)
  const searchTerm = query.get('q') || ''
  const dispatch = useDispatch()
  const [showResults, setShowResults] = useState(false)
  const [inputValue, setInputValue] = useState(searchTerm)

  useEffect(() => {
    if (inputValue && !window.__SERVER_SIDE__) {
      dispatch(
        customPageActions.searchAutocomplete({
          doctype: query.getAll('doctype[]'),
          terms: inputValue,
          sort_by: 'search_api_relevance',
          sort_order: 'DESC',
          page: 0,
          limit: 10,
          type: query.getAll('type[]'),
        })
      )
    }
  }, [inputValue])

  const { searchAutocompleteData: suggestionsData, loadingSearchAutocomplete } = useSelector(
    ({ customPage }) => customPage,
    isEqual
  )

  const suggestions = get(suggestionsData, 'searchQuery.rows', [])?.map((item) => {
    const replacedText = item.title.replaceAll(inputValue, `<strong>${inputValue}</strong>`)
    return { ...item, title: replacedText }
  })

  const handleChange = (val) => {
    if (val !== '‹' && val !== '£') {
      setInputValue(val)
      setShowResults(val.length > 0)
    }
  }

  const clearField = () => {
    setInputValue('')
    setShowResults(false)
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Enter' && inputValue.length > 0) {
      history.push(`/busca?q=${inputValue}`)
      setShowResults(false)

      if (props.active) {
        props.active(false)
      }
    }
  }

  return (
    <div className={`FormSearchContainer float`}>
      <Helmet>
        <style type="text/css">{formSearchStyles.toString()}</style>
      </Helmet>
      {showResults && suggestions.length > 0 && <div className="overlay" />}
      <div className={`FormSearch ${showResults ? 'active' : ''}`}>
        <div className="FormSearch__form d-flex mx-auto">
          <input
            className="FormSearch__input"
            type="text"
            placeholder={get(props, 'placeholder')}
            ref={props.inputRef}
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
            onKeyUp={handleKeyUp}
          />
          <button
            className="FormSearch__submit"
            onClick={() => (showResults ? clearField() : {})}
            aria-label={showResults ? 'icone-close' : 'icone-search'}
          >
            {loadingSearchAutocomplete ? (
              <Spinner animation="border" variant="white" size="sm" />
            ) : (
              <i className={showResults ? 'icone-close-book' : 'icone-search'} role="presentation" />
            )}
          </button>
        </div>

        {showResults && !loadingSearchAutocomplete && suggestions.length > 0 && (
          <ul className="FormSearch__resultList">
            {suggestions.map((item, index) => (
              <li className="FormSearch__result" key={index}>
                <LinkHandler
                  className="FormSearch__result-link"
                  target={get(item, 'type') === 'download' && '_blank'}
                  to={get(item, 'url')}
                >
                  {ReactHTMLParser(get(item, 'title'))}
                </LinkHandler>
                <i className="icone-search" role="presentation" />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default FormSearch
