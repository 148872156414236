import { SET_TOKEN, GET_TOKEN } from 'store/constants'

export function setToken(values) {
  return {
    type: SET_TOKEN,
    payload: values,
  }
}
export function getToken(values) {
  return {
    type: GET_TOKEN,
    payload: values,
  }
}
