import { validateToken, refreshTokenApi } from '../../helpers/auth'
import axios from 'axios'
import { isClient } from 'helpers/tools'

const xauthorization = 'Basic dWNfc3RhZ2luZzo4bmtrS0VCeUo4dFN4OHV0'

const axiosClient = {
  errors: false,
  auth: false,
  store: null,
  validateToken: async function (store) {
    this.errors = false
    this.auth = await validateToken(store)
    if (this.auth) {
      if (this.auth.expired) {
        this.errors = [
          {
            message: 'Sessão Expirada',
            isBusinessError: false,
            type: 'ApiError',
            details: {
              request: {
                method: 'GET',
                path: '',
              },
              response_status: 403,
            },
          },
        ]
      } else if (this.auth.refresh) {
        try {
          if (isClient() && store) {
            const resRefreshApi = await refreshTokenApi(this.auth.token, store)
            this.auth.token = resRefreshApi?.token?.access_token
          }
        } catch (e) {
          console.log(e)
          const errors = JSON.parse(JSON.stringify(e, undefined, 2))
          this.errors = errors.response.errors
        }
      }
    }
    return this.auth
  },
  request: async function (type, values, method = 'get', customOptions = {}) {
    this.store = values?.store || this.store
    const url = type !== 'page' ? '/fetch/' + type : values?.pathname + values?.search
    await this.validateToken(this.store)
    if (this.errors) return { errors: this.errors }
    if (values?.store) delete values.store

    const options = {
      withCredentials: true,
      headers: {
        axios: true,
        xauthorization,
      },
      ...customOptions
    }

    const api = new axios.create(options)

    const response = method === 'get' ? await api.get(url, { params: values }) : await api.post(url, values)
    if (response.data?.errors) throw response.data.errors[0]
    return response.data
  },
}
export { axiosClient }
