import {
    GET_BENEFIT_ELIGIBILITY,
    BENEFIT_ELIGIBILITY_LOADING,
    BENEFIT_ELIGIBILITY_DATA,
    BENEFIT_ELIGIBILITY_ERROR
} from 'store/constants'

export const initialState = {
    benefitEligibilityData: {},
    benefitEligibilityError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_BENEFIT_ELIGIBILITY:
            return { ...state, benefitEligibilityData: action.payload }
        case BENEFIT_ELIGIBILITY_DATA:
            return { ...state, benefitEligibilityData: action.payload }
        case BENEFIT_ELIGIBILITY_LOADING:
            return { ...state, loading: action.payload }
        case BENEFIT_ELIGIBILITY_ERROR:
            return { ...state, benefitEligibilityError: action.payload }
        default:
            return state
    }
}