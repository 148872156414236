import React, { useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { useDispatch, useSelector, ReactReduxContext } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import { Col, Container, Row, Dropdown, Form, Spinner } from 'react-bootstrap'
import { get } from 'lodash'
import API_AUTH from 'store/modules/auth/api'
import * as authActions from 'store/modules/auth/actions'
import { numberMask } from 'helpers/strings'
import { profileNamesForLoggedArea } from 'helpers/tools'
import ReactHtmlParser from 'react-html-parser'

// Components
import ConfirmModal from 'components/molecules/ConfirmModal'
import PageSteps from 'components/organisms/PageSteps'
import Typography from 'components/atoms/Typography'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import { getProfilesErrors } from '../../helpers'
import { removeAccessUser } from 'api/queries/user'
import CustomModal from 'components/molecules/CustomModal'

// Styles
import './ChangeProfile.scss'

function ChangeProfile(props) {
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const changeStep = get(props, 'changeStep')
  const [isLoading, setIsLoading] = useState(false)
  const user = get(props, 'data.user')
  const { user: session, loadingUser, userError } = useSelector((state) => state.auth)
  const [profiles, setProfiles] = useState(false)
  const [addProfile, setAddProfile] = useState(false)
  const [invite, setInvite] = useState(false)
  const [inviteContract, setInviteContract] = useState(false)
  const [deleteInvite, setDeleteInvite] = useState(false)
  const [deleteProfile, setDeleteProfile] = useState(false)
  const [formValidated, setFormValidated] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [confirmMessage, setConfirmMessage] = useState('')
  const [confirmAction, setConfirmAction] = useState(null)
  const [userRegisterLoading, setUserRegisterLoading] = useState(false)
  const [requiredGrantAccess, setRequiredGrantAccess] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState(false)
  const [loadingSelected, setLoadingSelected] = useState(undefined)
  const [errorRemoveAccess, setErrorRemoveAccess] = useState('')
  const [errorInactiveProfile, setErrorInactiveProfile] = useState('')
  const [isLoadingProfiles, setIsLoadingProfiles] = useState(false)
  const [activationLinkSent, setActivationLinkSent] = useState(false)
  const [activationLinkSending, setActivationLinkSending] = useState(false)

  const iconsMaps = {
    client: 'smile',
    clientepj: 'briefcase',
    fornecedor: 'briefcase',
    prestador: 'briefcase',
    cooperado: 'customer-service',
    sinapse: 'global-network',
  }

  const loadProfiles = () => {
    setIsLoadingProfiles(true)
    API_AUTH.getUserProfiles({ store })
      .then(({ userProfiles }) => {
        setProfiles(userProfiles)
        setIsLoadingProfiles(false)
        if (userProfiles && userProfiles.length == 0) {
          setAddProfile(true)
        }
      })
      .catch(({ response }) => {
        if (response?.errors[0]?.message) {
          switch (response?.errors[0]?.message) {
            case '403 - Acesso não autorizado':
              changeStep('AccessDenied')
              break
            default:
              changeStep('AccessDenied', {
                title: 'Ocorreu um erro inesperado',
                message: 'Não foi possível acessar os perfis do usuário',
              })
          }
        } else {
          changeStep('AccessDenied', {
            title: 'Ocorreu um erro inesperado',
            message: 'Não foi possível acessar os perfis do usuário',
          })
        }
      })
  }

  const revokeInvitation = (invite, name) => {
    setConfirmMessage(`Deseja remover o acesso de ${invite.email} ao perfil ${name}?`)
    setOpenConfirmModal(true)
    setDeleteInvite(invite)
    setConfirmAction('delete_invite')
  }

  const revokeProfile = (profile) => {
    setConfirmMessage(`Deseja remover o perfil ${profile.name}?`)
    setOpenConfirmModal(true)
    setDeleteProfile(profile)
    setConfirmAction('delete_profile')
  }

  const confirm = () => {
    if (confirmAction == 'delete_invite') {
      handleRemoveAccess(deleteInvite.id, deleteInvite.profile_id, deleteInvite.contract)
      setDeleteInvite(false)
    } else if (confirmAction == 'delete_profile') {
      handleRemove(deleteProfile)
      setDeleteProfile(false)
    }
    setOpenConfirmModal(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget

    if (!emailValidation(userEmail) && requiredGrantAccess) {
      registerUser()
    }
  }

  const handleRemove = async (profile) => {
    const payload = {
      profile_id: profile.profile_id,
      contract: profile.contract,
      store,
    }
    try {
      const { removeAccessUser } = await API_AUTH.removeAccessUser(payload)
      if (removeAccessUser.id === session.id) {
        dispatch(authActions.getUser({ store }))
      }
      if (removeAccessUser.message) {
        setErrorRemoveAccess(removeAccessUser.message)
      }
      loadProfiles()
    } catch (error) {
      console.log(error)
      setUserRegisterLoading(false)
    }
  }

  const registerUser = async () => {
    setUserRegisterLoading(true)
    const payload = {
      email: userEmail,
      profile_id: invite,
      contract: inviteContract,
      grantAccess: requiredGrantAccess,
      store,
    }
    try {
      await API_AUTH.grantAccessUser(payload)
      setInvite(false)
      setInviteContract(false)
      setUserRegisterLoading(false)
      loadProfiles()
    } catch (error) {
      console.log(error)
      setInvite(false)
      setInviteContract(false)
      setUserRegisterLoading(false)
    }
  }

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    
    const email_regex = !email ? false : regex.test(email) === false

    const { email: session_email, roles } = session
    if (session_email && roles.includes('sinapse')) {
      const domain_session_email = session_email.split('@')[1]
      const domain_form_email = email.split('@')[1]
      const domain_check = domain_session_email === domain_form_email

      if (!email_regex && domain_check) {
        return email_regex
      }
      return true
    }

    return email_regex
  }

  useEffect(() => {
    if (user) loadProfiles()
  }, [])

  const sendActivationLink = async ({ contract, profile_id }) => {
    try {
      setActivationLinkSending(true)
      const response = await API_AUTH.sendActivationLink({
        contract,
        profile_id,
        user_id: session.id,
        resend: activationLinkSent,
      })
      setActivationLinkSent(true)
    } catch (error) {
      console.log('error sendActivationLink -> ', error)
    } finally {
      setActivationLinkSending(false)
    }
  }

  async function addFinancialResponsiblePJ() {
    const formValues = {
      cnpj: '33517640001285',
      contract: '33517640001285',
      login: '33517640001285',
      id: '33517640001285',
      type: 'responsavel_financeiro',
    }
    await handleAddNewProfile(formValues)
  }

  async function addFinancialResponsiblePF() {
    const formValues = {
      cpf: '49664662968',
      contract: '49664662968',
      login: '49664662968',
      id: '49664662968',
      type: 'responsavel_financeiro',
    }
    await handleAddNewProfile(formValues)
  }

  async function addThirdParty() {
    const formValues = {
      cnpj: '78747821000183',
      contract: '78747821000183',
      login: '78747821000183',
      id: '78747821000183',
      type: 'empresacobranca',
    }
    await handleAddNewProfile(formValues)
  }

  async function handleAddNewProfile(formValues) {
    formValues.id = parseInt(session.id)
    formValues.card_number = numberMask(formValues.card_number)
    formValues.cnpj = numberMask(formValues.cnpj)
    formValues.cpf = numberMask(formValues.cpf)
    formValues.birth_date = formValues.birth_date ? formValues.birth_date.split('/').reverse().join('-') : ''
    formValues.email = session.email
    setIsLoading(true)
    try {
      const response = await API_AUTH.checkUserAndAddProfile({ ...formValues, store })

      if (!response.checkUserAndAddProfile.active) {
        const email = response.checkUserAndAddProfile.email_client
        const message = `
          <p>
            O perfil foi adicionado, porém, por motivos de segurança, é necessário acessar a conta de e-mail 
            <b>${email}</b> e clicar no link de ativação para concluir o cadastro.
          </p>
          <br />
          <p>Agradecemos a compreensão</p>
        `
        setErrorInactiveProfile(message)
      }

      if (response.checkUserAndAddProfile.message) {
        const email = response.checkUserAndAddProfile.email_client

        const pTemp = response.checkUserAndAddProfile.message.split('#')
        const role = pTemp[0]
        let phone = ''

        switch (role) {
          case 'client':
            phone = '0800-642 2002'
            break
          case 'cooperado':
            phone = '41 3021 7000'
            break
          default:
            phone = '0800-642 2002'
            break
        }
        if (response.checkUserAndAddProfile.message.indexOf('O e-mail informado não confere') >= 0) {
          const message = `
            <p>
              O e-mail informado não confere com o cadastro existente na Unimed Curitiba.
            </p>
            <p>Você deve utilizar a conta de e-mail ${email} para poder utilizar este perfil de acesso.</p>
            <p>
              Não tendo mais acesso a esta conta de e-mail ou em caso de dúvida, entre em contato com a central de atendimento no ${phone}.
            </p>
          `
          setErrorInactiveProfile(message)
        } else if (response.checkUserAndAddProfile.message.indexOf('O cliente não possui e-mail cadastrado') >= 0) {
          const message = `
            <p>
            Para criar esse perfil você precisa ter um e-mail cadastrado na Unimed Curitiba.
            </p>
            <p>
            Entre em contato com os canais de atendimento para para atualizar seu cadastro ou com a central de atendimento no ${phone}.
            </p>
          `
          setErrorInactiveProfile(message)
        }
      }

      setIsLoading(false)
      loadProfiles()
      setAddProfile(false)
      dispatch(authActions.getUser({ store }))
    } catch (error) {
      setIsLoading(false)
      document.querySelector('body').scrollIntoView()
      let errorMessage = get(error, 'response.errors[0].message')
      if (!errorMessage) {
        errorMessage = get(error, 'message')
      }
      changeStep('AccessDenied', getProfilesErrors(errorMessage))
    }
  }

  function handleSelectProfile(profile) {
    // let auth = cookies.get('auth')
    // delete auth.original_user
    // cookies.set('auth', JSON.stringify(auth), { path: '/' })

    // Clear old reports
    localStorage.removeItem('reportsBeingGeneratedCopan')
    localStorage.removeItem('reportsBeingGenerated')

    dispatch(authActions.setUserProfile({ ...profile, store }))
    setSelectedProfile(true)
  }

  useEffect(() => {
    if (selectedProfile && session?.contract) {
      const profileNameForLoggedArea = profileNamesForLoggedArea(session?.menu)
      if(profileNameForLoggedArea) {
        const loggedAreaPathURL = `/app/home-${profileNameForLoggedArea}`
        history.push(loggedAreaPathURL)
      }
    }
  }, [session])

  useEffect(() => {
    if (userError) {
      setIsLoading(false)
      document.querySelector('body').scrollIntoView()
      let errorMessage = get(userError, 'response.errors[0].message')
      if (!errorMessage) {
        errorMessage = get(userError, 'message')
      }
      changeStep('AccessDenied', getProfilesErrors(errorMessage))
    }
  }, [userError])

  function handleChangeDefault(profile, index) {
    API_AUTH.setUserDefaultProfile(profile).then(({ setUserDefaultProfile }) => {
      setProfiles((currentProfiles) => {
        const newProfile = currentProfiles.map((profile) => ({ ...profile, default_profile: false, store }))
        newProfile[index] = setUserDefaultProfile
        return newProfile
      })
    })
  }

  function handleRemoveAccess(invitedId, profileId, contract) {
    const payload = { invited_id: parseInt(invitedId), profile_id: parseInt(profileId), contract, store }
    API_AUTH.removeSharedProfile(payload).then(({ removeSharedProfile }) => {
      if (removeSharedProfile.success) {
        setOpenConfirmModal(false)
        loadProfiles()
      } else {
        console.error(removeAccessUser.message)
      }
    })
  }

  return (
    <section className="login-email-validated">
      <div className="content">
        <div className="login-header">
          <div className="container">
            <Typography variant="p" cssClass="title">
              {profiles && profiles.length > 0
                ? 'Selecione o perfil desejado ou adicione um novo'
                : 'Adicione um novo perfil'}
            </Typography>
          </div>
        </div>
        <div className="login-email-validated-body">
          <Container className="profile d-flex flex-column align-items-center p-3">
            {isLoadingProfiles && <Spinner animation="border" size="lg" className="my-4" />}
            {profiles &&
              profiles.map((profile, index) => {
                const { name, type, default_profile, contract, profile_id, owner, active, active_benner } = profile
                const selected = session?.owner && session?.contract !== contract ? 'outline' : ''
                return (
                  <Row
                    className={classNames('profile profile__line align-items-center flex-lg-row mb-1 p-2 py-3 card', {
                      selected,
                      'card-primary': profile.owner,
                      'card-success': !profile.owner,
                    })}
                    key={index}
                  >
                    <Col md={12}>
                      <Row>
                        <Col className="d-flex align-items-center mb-2 my-lg-0 d-flex" md={5}>
                          <i className={`icone-${iconsMaps[profile.type] || 'smile'} mr-3`}></i>
                          <p className="profile__username">{name}</p>
                        </Col>
                        <Col className="profile__contract mb-2 my-lg-0 d-flex align-items-center" xs={7} md={4}>
                          Código de Acesso:
                          <br />
                          {contract}
                        </Col>
                        <Col md={3} xs={5} className="d-flex align-items-center justify-content-end mt-2 mt-lg-0">
                          <Dropdown size="sm">
                            <Dropdown.Toggle
                              disabled={loadingUser}
                              variant="secondary"
                              className="profile__options"
                              id="dropdown-basic"
                            >
                              {loadingSelected === index ? <Spinner animation="border" size="sm" /> : 'Opções'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {active && active_benner && (
                                <Dropdown.Item
                                  onClick={() => {
                                    setLoadingSelected(index)
                                    handleSelectProfile(profile)
                                  }}
                                >
                                  Selecionar
                                </Dropdown.Item>
                              )}
                              {owner && active && active_benner && (
                                <>
                                  {!default_profile && (
                                    <Dropdown.Item
                                      onClick={(e) => {
                                        e.stopPropagation()
                                        handleChangeDefault(profile, index)
                                      }}
                                    >
                                      Tornar Perfil Padrão
                                    </Dropdown.Item>
                                  )}
                                  {(type == 'sinapse' ||
                                    type == 'cooperado' ||
                                    type == 'clientepj' ||
                                    type == 'prestador' ||
                                    type == 'fornecedor') && (
                                      <Dropdown.Item
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          setUserEmail('')
                                          setInvite(!invite && profile_id)
                                          setInviteContract(!invite && contract)
                                        }}
                                      >
                                        Conceder Acesso
                                      </Dropdown.Item>
                                    )}
                                </>
                              )}
                              <Dropdown.Item
                                onClick={(e) => {
                                  e.stopPropagation()
                                  revokeProfile(profile)
                                }}
                              >
                                Remover Perfil
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                      </Row>
                      {!active && (
                        <Row className="mt-3">
                          <Col md={12}>
                            <Typography variant="p" className="not-activated-message">
                              Este perfil ainda não foi ativado. Por favor acesse a sua caixa de email e clique no link
                              de ativação para concluir o cadastro.
                            </Typography>
                            <p className="mt-4">
                              <CustomButton
                                block
                                noIcon
                                color="secondary"
                                type="button"
                                isLoading={activationLinkSending}
                                text={activationLinkSent ? 'E-mail de ativação enviado!' : 'Reenviar email de ativação'}
                                disabled={activationLinkSent}
                                className="m-auto"
                                onClick={() => sendActivationLink({ contract, profile_id })}
                              />
                            </p>
                          </Col>
                        </Row>
                      )}
                      {!active_benner && active && (
                        <Row className="mt-3">
                          <Col md={12}>
                            <Typography variant="p" className="not-activated-message">
                              Este perfil foi desativado. Entre em contato com o atendimento da Unimed Curitiba em caso
                              de dúvidas.
                            </Typography>
                          </Col>
                        </Row>
                      )}
                      <Container fluid>
                        {active && active_benner && profile?.invites?.length > 0 && (
                          <Row className="profile__invited py-1 px-md-3 mt-3 d-flex align-items-center">
                            <Col md={12} className="d-flex">
                              <Typography variant="p" className="shared-profile">
                                <strong>Perfil compartilhado com:</strong>
                              </Typography>
                            </Col>
                          </Row>
                        )}
                        {profile?.invites?.map((invite) => (
                          <Row
                            className="profile__invited py-1 px-md-3 d-flex align-items-center"
                            key={invite.id + invite.profile_id}
                          >
                            <Col xs={7}>
                              <Typography className="shared-profile">{invite.email}</Typography>
                            </Col>
                            <Col xs={5} className="d-flex justify-content-end">
                              <CustomButton
                                color="success"
                                className="profile__remove-invited"
                                iconAlign="right"
                                icon="close-circle"
                                size="sm"
                                onClick={() => revokeInvitation(invite, name)}
                                text="Remover"
                              />
                            </Col>
                          </Row>
                        ))}
                      </Container>
                      {active && active_benner && invite == profile_id && contract == inviteContract && (
                        <Row className="mt-3">
                          <Col md={12}>
                            <Form
                              className="login-register-area"
                              noValidate
                              validated={formValidated}
                              onSubmit={handleSubmit}
                            >
                              <Form.Group>
                                <Form.Label>
                                  <Typography variant="span">Conceder acesso ao e-mail:</Typography>
                                </Form.Label>
                                <Form.Control
                                  type="email"
                                  id="email"
                                  name="email"
                                  aria-label="email"
                                  required
                                  value={userEmail}
                                  isInvalid={emailValidation(userEmail)}
                                  onChange={(e) => setUserEmail(e.target.value)}
                                />
                                {type !== 'sinapse' && (
                                  <Form.Control.Feedback type="invalid">Informe um e-mail válido.</Form.Control.Feedback>
                                )}
                                {type === 'sinapse' && (
                                  <Form.Control.Feedback type="invalid">Informe um e-mail válido e que possua o mesmo domínio do e-mail da cooperativa.</Form.Control.Feedback>
                                )}
                              </Form.Group>
                              <Form.Group controlId="policyPrivacy">
                                <Form.Check
                                  type="checkbox"
                                  checked={requiredGrantAccess}
                                  onChange={({ target }) => setRequiredGrantAccess(target.checked)}
                                  required
                                  isInvalid={true}
                                  label={
                                    <span>
                                      Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou
                                      consentimento, quando aplicável conforme descrito nesta{' '}
                                      <HashLink to="/politica-de-privacidade" target="_blank">
                                        Política de Privacidade
                                      </HashLink>{' '}
                                      e{' '}
                                      <HashLink to="/termos-de-uso" target="_blank">
                                        Termos de Uso
                                      </HashLink>
                                    </span>
                                  }
                                ></Form.Check>
                                <Form.Control.Feedback type="invalid">
                                  É necessário estar de acordo com o termo de Concessão e Responsabilidade.
                                </Form.Control.Feedback>
                              </Form.Group>

                              <CustomButton
                                disabled={userRegisterLoading}
                                block
                                className="submit-button"
                                noIcon
                                color="secondary"
                                type="submit"
                                isLoading={userRegisterLoading}
                                text="Enviar Convite"
                              />
                            </Form>
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                )
              })}
            <Row
              className="profile__line align-items-center mb-1 p-3 card card-tertiary flex-lg-row add-profile"
              onClick={() => setAddProfile(true)}
              role="button"
            >
              {!addProfile ? (
                <Col className="text-center d-flex align-items-center justify-content-center">
                  Adicionar novo perfil
                </Col>
              ) : (
                <>
                  <Col className="text-center">
                    <Typography color="secondary" cssClass="mb-4">
                      Selecione o tipo de login
                    </Typography>
                  </Col>
                  <Col xs={12} className="d-flex justify-content-center">
                    <PageSteps
                      className="profile__page-steps px-md-3"
                      onSubmit={handleAddNewProfile}
                      isLoading={isLoading}
                    />
                  </Col>
                </>
              )}
            </Row>
          </Container>
          <ConfirmModal
            text={confirmMessage}
            onConfirm={confirm}
            onRefuse={() => setOpenConfirmModal(false)}
            onClose={() => setOpenConfirmModal(false)}
            open={openConfirmModal}
          />
        </div>
      </div>

      <CustomModal
        open={errorRemoveAccess != ''}
        closeModal={() => setErrorRemoveAccess('')}
        size="sm"
        title="Erro ao remover perfil"
        footer={{ closeButton: true }}
      >
        <div className="ModalLogin__modal-container">
          <strong>{errorRemoveAccess}</strong>
        </div>
      </CustomModal>

      <CustomModal
        open={errorInactiveProfile != ''}
        closeModal={() => setErrorInactiveProfile('')}
        size="sm"
        title="Cadastro de perfil de acesso"
        footer={{ closeButton: true }}
      >
        <div className="ModalLogin__modal-container">
          <span>{ReactHtmlParser(errorInactiveProfile)}</span>
        </div>
      </CustomModal>
    </section>
  )
}

export default ChangeProfile
