export default `
fragment ParagraphPostit on ParagraphPostit {
  id
  uuid
  entityBundle
  title
  date {
    value
  }
  theme {
      entity {
        name
        classes
      }
  }
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }  
}
`