import apiClient from 'api/config/apiClient'

export default {
  getQuickAccess(values) {
    return apiClient.request('cache-disable/quickaccess', values)
  },
  addQuickAccess(values) {
    return apiClient.request('add_quickaccess', values)
  },
  updateQuickAccess(values) {
    return apiClient.request('update_quickaccess', values)
  },
  deleteQuickAccess(values) {
    return apiClient.request('delete_quickaccess', values)
  },
}
