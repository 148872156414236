export default `

fragment ParagraphBannerWithMosaic on ParagraphBannerWithMosaic {
  id
  uuid
  entityBundle
  logo {
    entity {
      ...Media
    }
  }
  menu {
    title
    url{
      path
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  title
  description {
    value
  }
  background_color {
    color
  }
  button {
    entity {
    ...ParagraphButton
    }
  }
  medias {
    entity {
      ...Media
    }
  }
}
`
