export default `
fragment ParagraphStepByStep on ParagraphStepByStep {
  id
  uuid
  entityBundle
  title
  steps {
    entity {
      ...ParagraphStep
    }
  }
}

`