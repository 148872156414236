export default `
fragment ParagraphPostits on ParagraphPostits {
  id
  uuid
  entityBundle
  theme {
      entity {
        name
        classes
      }
  }
  items {
    entity {
      ...ParagraphPostit
    }
  }
}
`