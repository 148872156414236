export default `
fragment ParagraphHalfBannersMosaic on ParagraphHalfBannersMosaic {
    id
    uuid
    entityBundle
    theme {
      entity {
        classes
      }
    }
    media {
      entity {
        ...Media
      }
    }
    items {
      entity {
        ...ParagraphHalfBanner
      }
    }
  }
`