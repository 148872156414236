import React, { useState, useEffect } from 'react'
import renderComponents from 'helpers/renderComponents'
import { snakeToPascal } from 'helpers/strings'
import get from 'lodash.get'
import Footer from 'components/organisms/Footer'
import dataGa from 'helpers/dataGa'

const LandingPages = (props) => {
  const { data, user, refs } = props
  const entity = get(data, 'route.entity') || { content: [] }
  const components = Array.isArray(entity.content) ? entity.content : []
  const header = Array.isArray(entity.header) ? entity.header : []
  const [acessibilityMode, setAcessibilityMode] = useState(false)

  useEffect(() => {
    dataGa(entity.dataGa)
    setAcessibilityMode(JSON.parse(localStorage.getItem('acessibility-mode')) ? true : false)
  }, [])

  return (
    <>
      <header id="header" ref={refs.header}>
        {header
          .filter((i) => i.entity)
          .map((item, index) => {
            let propsItem =
              item.entity.entityBundle === 'from_library' ? item.entity.entities.entity.paragraphs.entity : item.entity
            const RenderComponent = renderComponents(snakeToPascal(get(propsItem, 'entityBundle')))
            return <RenderComponent key={index + propsItem.uuid} data={propsItem} user={user} refs={refs} />
          })}
      </header>
      <main id="content" ref={refs.content}>
        {components
          .filter((i) => i.entity)
          .map((item, index) => {
            let propsItem =
              item.entity.entityBundle === 'from_library' ? item.entity.entities.entity.paragraphs.entity : item.entity
            if (propsItem) {
              propsItem.acessibilityMode = acessibilityMode
              if (index === 0) {
                propsItem = {
                  ...propsItem,
                  first: true,
                }
              }
              if (index === components.length - 1) {
                propsItem = {
                  ...propsItem,
                  last: true,
                }
              }

              const RenderComponent = renderComponents(snakeToPascal(get(propsItem, 'entityBundle')))
              return <RenderComponent key={index + propsItem.uuid} data={propsItem} user={user} refs={refs} />
            }
          })}
      </main>
      <footer id="footer">
        <Footer landingPage refs={refs} />
      </footer>
    </>
  )
}

export default LandingPages
