import { GET_USER, SET_USER, SET_USER_DATA, GET_IP, SET_IP } from 'store/constants'

export function getUser(values) {
  return {
    type: GET_USER,
    payload: values,
  }
}

export function setUser(values) {
  return {
    type: SET_USER_DATA,
    payload: values,
  }
}

export function setUserProfile(values) {
  return {
    type: SET_USER,
    payload: values,
  }
}

export function getIpAddress(values) {
  return {
    type: GET_IP,
    payload: values,
  }
}

export function setIpAddress(values) {
  return {
    type: SET_IP,
    payload: values,
  }
}