export default `
fragment ParagraphBigButton on ParagraphBigButton {
  id
  uuid
  entityBundle
  title
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  link {
    url {
      path
    }
  }
}
`