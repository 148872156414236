import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, FormControl, Spinner } from 'react-bootstrap'
import { CSSTransition } from 'react-transition-group'
import { get } from 'lodash'
import API_AUTH from 'store/modules/auth/api'
import { numberMask } from 'helpers/strings'

// Components
import PageSteps from 'components/organisms/PageSteps'
import Typography from 'components/atoms/Typography'
import CustomButton from 'components/atoms/CustomButton'
import EmailSuccess from '../EmailSuccess'

// Style
import './ForgotAccess.scss'

function ForgotAccess(props) {
  const changeStep = get(props, 'changeStep')
  const changeBreadcrumbVisibility = get(props, 'changeBreadcrumbVisibility')
  const changeHeaderVisibility = get(props, 'changeHeaderVisibility')
  const [retrievePassword, setRetrivePassword] = useState(false)
  const [retrieveLogin, setRetriveLogin] = useState(false)
  const [validEmail, setValidEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [step, setStep] = useState()
  const handleChangeUserType = get(props, 'handleChangeUserType')

  useEffect(() => {
    changeHeaderVisibility(false)
    changeBreadcrumbVisibility(false)
  }, [])

  function handleChangeEmail({ target: { value } }) {
    const regexValidEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (value.match(regexValidEmail)) setValidEmail(value)
    else setValidEmail('')
  }

  async function handleSendEmail(e) {
    e.preventDefault()

    try {
      const response = await sendEmail(false)
      setStep(
        <EmailSuccess
          changeStep={changeStep}
          response={response.rememberUserPassword}
          email={validEmail}
          handleResendEmail={() => {
            sendEmail(true)
          }}
          handleSendOtherEmail={goBack}
        />
      )
    } catch (error) {
      changeStep('AccessDenied')
    }
  }

  async function sendEmail(resend) {
    setIsLoading(true)
    let payload = {
      email: validEmail,
      resend: resend,
    }
    const response = await API_AUTH.rememberPassword(payload)
    setIsLoading(false)
    return response
  }

  function goBack() {
    history.go(0)
  }

  async function handleRetrieveEmail(formValues) {
    formValues.card_number = numberMask(formValues.card_number)
    formValues.cnpj = numberMask(formValues.cnpj)
    formValues.cpf = numberMask(formValues.cpf)
    formValues.birth_date = formValues.birth_date ? formValues.birth_date.split('/').reverse().join('-') : ''
    setIsLoading(true)
    try {
      let response = await API_AUTH.rememberEmail(formValues)
      if (response.userRememberEmail.success === false) {
        changeStep('SomethingWrong')
      } else {
        changeStep('FeedbackSuccess', { ...response.userRememberEmail, type: 'email' })
      }
    } catch (error) {
      setIsLoading(false)
      changeStep('AccessDenied')
    }
  }
  // async function handleRetrieveEmail(formValues) {
  //   setIsLoading(true)
  //   let payload = {
  //     cnpj: numberMask(formValues.cnpj),
  //     crm: formValues.crm,
  //     login: formValues.login,
  //     profile: formValues.profile,
  //     type: formValues.type,
  //   }
  //   const response = await API_AUTH.rememberEmail(payload)
  //   setIsLoading(false)
  //   if (response.userRememberEmail.success === false) {
  //     changeStep('SomethingWrong')
  //   } else {
  //     changeStep('FeedbackSuccess', { ...response.userRememberEmail, type: 'email' })
  //   }
  // }

  return (
    <div className="forgot-access">
      <div className="login-header">
        <div className="container">
          <Typography variant="p" cssClass="title">
            Esqueceu seu e-mail ou senha?
          </Typography>
        </div>
      </div>
      {step || (
        <div className="p-3">
          <div className="text-center mb-4">
            <Typography color="secondary">Tudo bem! Essas coisas acontecem.</Typography>
            <Typography color="secondary">O que você deseja fazer?</Typography>
          </div>
          <div className="d-flex justify-content-center">
            <Form.Group className="mb-3">
              <Form.Check>
                <Form.Check.Input
                  id="retrieve-email"
                  name="retrieve-option"
                  onChange={() => setRetriveLogin(true)}
                  type="radio"
                ></Form.Check.Input>
                <Form.Check.Label htmlFor="retrieve-email">
                  <Typography color="gray-100">Recuperar o e-mail</Typography>
                </Form.Check.Label>
              </Form.Check>

              <Form.Check>
                <Form.Check.Input
                  name="retrieve-option"
                  id="retrieve-password"
                  onChange={() => setRetrivePassword(true)}
                  type="radio"
                ></Form.Check.Input>
                <Form.Check.Label htmlFor="retrieve-password">
                  <Typography color="gray-100">Recuperar a senha</Typography>
                </Form.Check.Label>
              </Form.Check>
            </Form.Group>
          </div>

          <CSSTransition
            classNames="retrieve-password"
            in={retrievePassword}
            timeout={500}
            unmountOnExit
            onEnter={() => setRetriveLogin(false)}
            onExit={() => setRetrivePassword(false)}
          >
            <form className="retrieve-password container" onSubmit={handleSendEmail}>
              <Form.Group className="mb-4">
                <Form.Label htmlFor="email">E-mail</Form.Label>
                <FormControl name="email" type="email" onChange={handleChangeEmail} />
              </Form.Group>
              <CustomButton
                className="retrieve-password__submit"
                disabled={!validEmail || isLoading}
                text="Continuar"
                noIcon
                isLoading={isLoading}
                type="submit"
              />
            </form>
          </CSSTransition>

          <CSSTransition
            classNames="retrieve-email"
            in={retrieveLogin}
            timeout={500}
            unmountOnExit
            onEnter={() => setRetrivePassword(false)}
            onExit={() => setRetriveLogin(false)}
          >
            <div className="retrieve-email d-flex flex-column align-items-center">
              <Typography color="secondary" cssClass="mb-4">
                Selecione o tipo de login
              </Typography>
              <PageSteps onSubmit={handleRetrieveEmail} isLoading={isLoading} changeUserType={handleChangeUserType} />
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  )
}

PropTypes.propTypes = {}

ForgotAccess.defaultProps = {
  data: [
    { title: ' empresarial', icon: 'icon' },
    { title: ' empresarial', icon: 'icon' },
  ],
}

export default ForgotAccess
