export function getProfilesErrors(error = '') {
  if (error.includes('Usuário benner inválido ou não encontrado') || error.includes('Usuário não encontrado')) {
    return {
      title: 'Algo deu errado',
      message: `
        Os dados inseridos não conferem com a base da Unimed Curitiba.<br>
        Revise as informações e tente novamente.<br><br>
        Caso o problema persista, entre em contato conosco por um dos nossos canais de atendimento:
        <br>
        <p class="text-left">
          <br>
          <ul class="text-left">
            <li>Cliente PF: 0800-642 2002</li>
            <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
            <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
            <li>Prestador: Entre em contato pelo 3021-9420</li>
            <li>Fornecedor: 3021-9420</li>
          </ul>
        </p>`,
    }
  }
  if (error.includes('Este perfil já foi adicionado por outro usuário.')) {
    return {
      title: 'Essa conta está vinculada a outro e-mail',
      message: `
        Revise as informações inseridas e tente novamente.<br><br>
        Caso o problema persista, entre em contato conosco por um dos nossos canais de atendimento:
        <br>
        <p class="text-left">
          <br>
          <ul class="text-left">
            <li>Cliente PF: 0800-642 2002</li>
            <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
            <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
            <li>Prestador: Entre em contato pelo 3021-9420</li>
            <li>Fornecedor: 3021-9420</li>
          </ul>
        </p>`,
    }
  }
  if (error.includes('Perfil duplicado')) {
    return {
      title: 'Essa conta está vinculada a outro e-mail',
      message: `
        Revise as informações inseridas e tente novamente.<br><br>
        Caso o problema persista, entre em contato conosco por um dos nossos canais de atendimento:
        <br>
        <p class="text-left">
          <br>
          <ul class="text-left">
            <li>Cliente PF: 0800-642 2002</li>
            <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
            <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
            <li>Prestador: Entre em contato pelo 3021-9420</li>
            <li>Fornecedor: 3021-9420</li>
          </ul>
        </p>`,
    }
  }
  if (error.includes('The user is inactive')) {
    return {
      title: 'Algo deu errado',
      message: `
        O usuário encontra-se inativo.<br>
        <br><br>
        Caso o problema persista, entre em contato conosco por um dos nossos canais de atendimento:
        <br>
        <p class="text-left">
          <br>
          <ul class="text-left">
            <li>Cliente PF: 0800-642 2002</li>
            <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
            <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
            <li>Prestador: Entre em contato pelo 3021-9420</li>
            <li>Fornecedor: 3021-9420</li>
          </ul>
        </p>`,
    }
  }
  return { title: error, message: 'Ocorreu um erro' }
}
