import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import SVG from 'react-inlinesvg'
import { isFirefox } from 'helpers/tools'
import LinkHandler from 'components/atoms/LinkHandler'

import './CustomButton.scss'

function CustomButton({
  ariaLabel,
  id,
  color,
  className,
  disabled,
  size,
  download,
  noIcon,
  icon,
  iconAlign,
  isIconSVG,
  linkType,
  onClick,
  href,
  target,
  text,
  type,
  isLoading,
}) {
  const iconAlignment = iconAlign || 'left'
  const iconMargin = iconAlignment === 'left' ? 'mr-2' : 'ml-2'
  const [isDisabled, setIsDisabled] = useState(disabled)

  function getLink(href, linkType) {
    switch (linkType) {
      case 'Whatsapp':
        return `https://wa.me/${href.replace(/\D/g, '')}`
      default:
        return href
    }
  }

  function setAriaLabel(linkType) {
    if (ariaLabel) return ariaLabel

    switch (linkType) {
      case 'Whatsapp':
        return `Contato por WhatsApp ${text}`
      case 'E-mail':
        return `Contato por e-mail ${text}`
      case 'Telefone':
        return `Telefone de contato ${text}`
      default:
        return undefined
    }
  }

  useEffect(() => {
    if (linkType === 'Telefone' && href && href.search(/tel:/g) !== -1 && isFirefox()) {
      setIsDisabled(true)
    } else {
      setIsDisabled(disabled)
    }
    return disabled
  })

  const iconElement = isIconSVG ? (
    <SVG className={classnames('custom-button__icon', iconMargin)} src={icon} />
  ) : (
    <i className={classnames(`icone-${icon || 'arrow-right'}`, iconMargin)} role="presentation" />
  )

  const ariaLabelProp = href ? { ariaLabel: setAriaLabel(linkType) } : { 'aria-label': setAriaLabel(linkType) }

  return (
    <Button
      {...ariaLabelProp}
      as={href && LinkHandler}
      id={id}
      disabled={isDisabled}
      variant={color}
      size={size || 'lg'}
      type={type || 'button'}
      onClick={onClick}
      className={classnames('custom-button', className)}
      to={href && getLink(href, linkType)}
      target={target}
      download={download}
    >
      {isLoading && (
        <div className="text-center">
          <Spinner size="sm" className="ml-1" animation="border" />
        </div>
      )}

      {iconAlignment === 'left' && !noIcon && !isLoading && iconElement}
      {!isLoading && text}
      {iconAlignment === 'right' && !noIcon && !isLoading && iconElement}
    </Button>
  )
}

CustomButton.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  download: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  href: PropTypes.string,
  size: PropTypes.oneOf(['lg', 'sm']),
  icon: PropTypes.string,
  noIcon: PropTypes.bool,
  iconAlign: PropTypes.oneOf(['left', 'right']),
  isIconSVG: PropTypes.bool,
  onClick: PropTypes.func,
  target: PropTypes.string,
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
}

export default CustomButton
