import { gql } from 'graphql-request'

export default gql`
	mutation updateCivilAction($input: CivilActionInput!, $code: Int!) {
		applications {
			civilAction {
				civilActionUpdate(input: $input, code: $code) {
					civil_action {
						street
						code
						bank
						other_bank
						agency
						account_type
						account
						digit
						cpf
						birth_date
						rg
						phone
						cell_phone
						address
						number
						neighborhood
						zipcode
						city
						state
						protocol_number
						ip
						info
						no_account
						value
						email
						name
						created_at
					}
				}
			}
		}
	}
`
