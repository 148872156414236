import React, { useEffect } from 'react'
import Typography from 'components/atoms/Typography'
import { get } from 'lodash'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import IconOpenEmail from 'assets/img/icon-open-email.svg'
import IconLock from 'assets/img/icon-lock.svg'

// Styles
import './FeedbackSuccess.scss'

function FeedbackSuccess({ data, changeStep, changeBreadcrumbVisibility, changeHeaderVisibility }) {
  const message = get(data, 'message')
  const email = get(data, 'email')
  const type = get(data, 'type')
  const history = useHistory()

  useEffect(() => {
    changeHeaderVisibility(false)
    changeBreadcrumbVisibility(false)
  }, [])

  return (
    <section className="feedback-success">
      <div className="content">
        <div className="login-header">
          <Typography variant="p" cssClass="title">
            {type === 'password' ? 'Sua senha foi redefinida com sucesso!' : 'Recuperação concluída com sucesso!'}
          </Typography>
        </div>
        <div className="feedback-success-body">
          <SVG
            className="icon"
            src={type === 'password' ? IconLock : IconOpenEmail}
            preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
            width="100%"
            height="100%"
          />
          <p className="text mb-4">
            {type === 'password'
              ? 'Faça o login no Portal Unimed Curitiba e utilize nossos serviços online disponíveis para você.'
              : message}
          </p>
          {email && (
            <a
              className="mx-auto text-green-dark-200"
              href={`mailto:${email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {email}
            </a>
          )}
          <div className="cta">
            <Button
              type="button"
              variant="outline-primary"
              size="lg"
              className="done mx-2"
              onClick={() => {
                history.push('/home')
              }}
            >
              <span>Concluir</span>
            </Button>
            <Button
              type="button"
              variant="primary"
              size="lg"
              className="login mx-2"
              onClick={() => {
                changeStep('LoginForm')
              }}
            >
              <span>Login</span>{' '}
              <span>
                <i className="icone-arrow-right" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeedbackSuccess
