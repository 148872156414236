import { GraphQLClient } from 'graphql-request'
import { validateToken } from '../../helpers/auth'

const xauthorization = 'Basic dWNfc3RhZ2luZzo4bmtrS0VCeUo4dFN4OHV0'
const graphQLClient = {
  endpoint: process.env.RAZZLE_ENDPOINT_GRAPHQL,
  errors: false,
  auth: false,
  headers: null,
  store: null,
  setHeaders: function () {
    this.headers = {
      headers: {
        authorization: 'Bearer ' + this.auth.token,
        xauthorization,
      },
    }
  },
  validateToken: async function (store) {
    this.errors = false
    this.auth = await validateToken(store)
    if (this.auth) {
      if (this.auth.expired) {
        this.headers = null
        this.errors = [
          {
            message: 'Sessão Expirada',
            isBusinessError: false,
            type: 'ApiError',
            details: {
              request: {
                method: 'GET',
                path: '',
              },
              response_status: 403,
            },
          },
        ]
      } else {
        this.setHeaders()
      }
    } else {
      this.headers = {
        headers: {
          xauthorization,
        },
      }
    }
    return this.auth
  },
  request: async function (query, values) {
    try {
      if (values?.store) {
        this.store = values.store
      }
      this.token = await this.validateToken(this.store)
      if (!this.errors) {
        const absolutePath = typeof window !== 'undefined' ? this.endpoint : process.env.RAZZLE_GRAPHQL + this.endpoint
        if (values?.store) delete values.store
        const headers = !values?.public ? this.headers : { headers: { xauthorization } }
        return new GraphQLClient(absolutePath, headers).request(query, values)
      } else {
        return { errors: this.errors }
      }
    } catch (e) {
      console.log('CATCH GRAPHQL REQUEST', e)
    }
  },
}

export { graphQLClient }
