import { gql } from 'graphql-request'

export default gql`
	query getEmployeeCards($contract: String, $name: String, $st: String) {
		applications {
			cards {
				cardsList(contract: $contract, name: $name, st: $st) {
					cards {
						name
						number
						expiration_date
						via
					}
				}
			}
		}
	}
`