import React from 'react'
import { HashLink } from 'react-router-hash-link'
import Helmet from 'react-helmet'
import FastAccessStyle from './FastAccess.scss'

function FastAccess({ refs }) {
  const scrollSettings = { behavior: 'smooth', block: 'start' }

  function handleScrollToContent(ref) {
    if (ref) {
      ref.current.scrollIntoView(scrollSettings)
      ref.current.focus()
    }
  }

  return (
    <nav aria-label="Menu de acesso rápido" id="fast-access" className="fast-access">
      <Helmet>
        <style type="text/css">{FastAccessStyle.toString()}</style>
      </Helmet>
      <ul className="d-none d-lg-inline">
        <li>
          <p className="FastAccess">Ir para:</p>
        </li>
        <li>
          <button
            className="fast-access__item p-1"
            id="fa_content"
            tabIndex={0}
            onClick={() => handleScrollToContent(refs?.content)}
          >
            Conteúdo
          </button>
        </li>
        <li>
          <button
            className="fast-access__item p-1"
            id="fa_menu"
            tabIndex={0}
            onClick={() => handleScrollToContent(refs?.nav)}
          >
            Menu
          </button>
        </li>
        <li>
          <button
            className="fast-access__item p-1"
            id="fa_footer"
            tabIndex={0}
            onClick={() => handleScrollToContent(refs?.footer)}
          >
            Rodapé
          </button>
        </li>
        <li>
          <HashLink className="fast-access__item" id="fa_accessibility" tabIndex={0} to="/acessibilidade">
            Acessibilidade
          </HashLink>
        </li>
      </ul>
    </nav>
  )
}

export default FastAccess
