import {
  GET_PIN_UTILIZATION,
  GET_PIN_UTILIZATION_DATA,
  GET_PIN_UTILIZATION_LOADING,
  GET_PIN_UTILIZATION_ERROR,
  GET_RECIPIENTS_DATE,
  GET_RECIPIENTS_DATE_DATA,
  GET_RECIPIENTS_DATE_LOADING,
  GET_RECIPIENTS_DATE_ERROR,
} from 'store/constants'

export const initialState = {
  pinSSUtilizationData: {},
  pinSSUtilizationError: false,
  pinSSUtilizationDataLoading: false,
  recipientsDatesData: {},
  recipientsDatesLoading: false,
  recipientsDatesError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PIN_UTILIZATION:
      return { ...state, pinSSUtilizationData: action.payload }
    case GET_PIN_UTILIZATION_DATA:
      return { ...state, pinSSUtilizationData: action.payload }
    case GET_PIN_UTILIZATION_LOADING:
      return { ...state, pinSSUtilizationLoading: action.payload }
    case GET_PIN_UTILIZATION_ERROR:
      return { ...state, pinSSUtilizationError: action.payload }

    case GET_RECIPIENTS_DATE:
      return { ...state, recipientsDatesData: action.payload }
    case GET_RECIPIENTS_DATE_DATA:
      return { ...state, recipientsDatesData: action.payload }
    case GET_RECIPIENTS_DATE_LOADING:
      return { ...state, recipientsDatesLoading: action.payload }
    case GET_RECIPIENTS_DATE_ERROR:
      return { ...state, recipientsDatesError: action.payload }
    default:
      return state
  }
}
