export const static_routes = [
    'login',
    'email-validated',
    'reset-password',
    'activate-profile',
    'accept-invitation',
    'simular-acesso',
    'change-profile',
    'cadastre-se',
]
