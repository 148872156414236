export default `
fragment ParagraphCarouselTagBanners on ParagraphCarouselTagBanners {
  id
  uuid
  entityBundle
  title
  theme {
    entity {
      name
      classes
    }
  } 
  items {
    entity {
      ...ParagraphTagBanner
    }
  }
}
`