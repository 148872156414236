import React, { useEffect, useState, Fragment } from 'react'
import { Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import LoginPages from 'components/templates/LoginPages'

const LoginPage = (props) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setLoaded(true)
    }
    window.__SERVER_SIDE__ = false
  })

  return (
    <Fragment>
      {!loaded && (
        <div className="loading-page">
          <Spinner className="loading-page__spinner" size="lg" animation="border" variant="white" />
        </div>
      )}
      <Helmet defaultTitle="Login | Unimed" />
      <LoginPages {...props} />
    </Fragment>
  )
}

export default LoginPage
