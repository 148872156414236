export default `
fragment ParagraphLatestNews on ParagraphLatestNews {
  id
  uuid
  entityBundle
  sectionTitle
  title
  carouselMobile
  number
  theme {
    entity {
      name
      classes
    }
  }
  category {
    entity {
      tid
      name
    }
  }
  linkType {
    entity {
      name
      token
      icon {
        entity {
          media {
            entity {
              ...Media
            }
          }
        }
      }
    }
  }
  link {
    title
    url {
      path
    }
  }
  news {
    items {
      title
      promote
      body {
        value
        summary
      }
      url {
        path
      }
      media {
        entity {
          ...Media
        }
      }
      created
      updated
    }
  }  
}
`
