import React from 'react'
import PropTypes from 'prop-types'

import Typography from 'components/atoms/Typography'

import './SectionTitle.scss'
function SectionTitle({ barColor, className, title, subtitle, textColor, variant }) {
  return (
    <div className={`section-title ${className}`}>
      <Typography variant={variant || ''} color={textColor || 'gray-100'} cssClass="title mb-1">
        {title}
      </Typography>
      <div className="low-bar" style={{ backgroundColor: barColor }}></div>
      {subtitle && (
        <Typography color={textColor || 'gray-100'} cssClass="title mb-1">
          {subtitle}
        </Typography>
      )}
    </div>
  )
}

SectionTitle.propTypes = {
  barColor: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  textColor: PropTypes.string,
}

export default SectionTitle
