import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ClassNames from 'classnames'
import get from 'lodash.get'
import isEqual from 'lodash.isequal'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CardIconLink from 'components/molecules/CardIconLink'
import SocialMedia from 'components/molecules/SocialMedia/SocialMedia'
import SocialMediaMobile from 'components/molecules/SocialMediaMobile'
import ImageLink from 'components/molecules/ImageLink'
import Copyright from 'components/molecules/Copyright'
import PhoneTags from 'components/organisms/PhoneTags'
import LinksFooter from 'components/organisms/LinksFooter'
import { getFooterMenus, getFooterSubMenus, getFooterData } from 'store/modules/customPage/actions'
import IconSmile from 'assets/img/icon-smile.svg'
import './Footer.scss'

function Footer({ logged, landingPage, errorPage, refs }) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!menus.menuQuery) dispatch(getFooterMenus({ public: true }))
    if (!submenus.menuQuery) dispatch(getFooterSubMenus({ public: true }))
    if (!footer.footerQuery) dispatch(getFooterData({ public: true }))
  }, [])

  let menus = useSelector((state) => state.customPage.footerMenus, isEqual)
  let submenus = useSelector((state) => state.customPage.footerSubMenus, isEqual)
  let error = useSelector((state) => state.customPage.footerMenusError)
  let loading = useSelector((state) => state.customPage.loadingFooterMenus)
  let submenus_error = useSelector((state) => state.customPage.footerSubMenusError)
  let submenus_loading = useSelector((state) => state.customPage.loadingFooterSubMenus)
  let footer = useSelector((state) => state.customPage.footerData)

  let footerMainMenus = get(menus, 'menuQuery.entity.links')
  let footerSubMenus = get(submenus, 'menuQuery.entity.links')

  let linksWithIcons = get(footer, 'footerQuery.linksWithIcons')
  let linksSocialMedia = get(footer, 'footerQuery.linksSocialMedia')
  const imageLink = get(footer, 'footerQuery.imageLink')

  function mapLinkSocialMedia(link) {
    const icon = get(link, 'entity.icon.entity.media.entity.fieldMediaFile.entity.url')
    const title = get(link, 'entity.link.title')
    const url = get(link, 'entity.link.url.path')
    const uuid = get(link, 'entity.uuid')
    return { icon, title, url, uuid }
  }

  return (
    !(loading || error || submenus_loading || submenus_error) && (
      <>
        {!logged && !landingPage && !errorPage && <PhoneTags />}
        <Container
          fluid="fluid"
          className={`${logged ? 'bg-green-dark-100' : 'bg-primary'} pt-5 pb-3 pt-md-5 pb-md-5`}
          id="rodape"
          ref={refs.footer}
          tabIndex="-1"
        >
          <Container>
            <Row className="d-flex justify-content-between">
              <Col sm={12} lg={7} className="d-flex justify-content-between justify-content-md-start ">
                <div className="d-flex flex-wrap">
                  {imageLink?.map(({ entity }, index) => (
                    <ImageLink
                      key={get(entity, 'id')}
                      className={ClassNames(
                        { 'border-right': index + 1 < imageLink?.length },
                        index !== 0 ? 'px-3' : 'pr-3'
                      )}
                      image={{
                        alt: get(entity, 'media.entity.images.alt'),
                        src: get(entity, 'media.entity.images.medium.url'),
                      }}
                      link={{
                        url: get(entity, 'link.url.path'),
                        type: get(entity, 'linkType.entity.name'),
                      }}
                    />
                  ))}
                </div>
              </Col>
              <Col sm={12} lg={2} className="d-none d-lg-block">
                {linksSocialMedia && <SocialMedia links={linksSocialMedia.map((link) => mapLinkSocialMedia(link))} />}
              </Col>
            </Row>
            <Row className={`${logged ? 'pt-md-4 pb-md-0' : 'py-md-5'} py-3`}>
              {linksWithIcons?.map((link) => {
                const icon = get(link, 'entity.icon.entity.media.entity.fieldMediaFile.entity.url')
                const title = get(link, 'entity.link.title')
                const url = get(link, 'entity.link.url.path')
                const uuid = get(link, 'entity.uuid')

                return (
                  <Col sm={12} md={4} className="my-1" key={uuid}>
                    <CardIconLink text={title} icon={icon || IconSmile} url={url} />
                  </Col>
                )
              })}
            </Row>
            {!logged && !landingPage && <LinksFooter links={footerMainMenus} />}
            {linksSocialMedia && <SocialMediaMobile links={linksSocialMedia.map((link) => mapLinkSocialMedia(link))} />}
          </Container>
        </Container>
        <Copyright links={footerSubMenus} />
      </>
    )
  )
}

export default Footer
