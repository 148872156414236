import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-maskedinput'
import { Form, FormControl } from 'react-bootstrap'
import { cpf } from 'cpf-cnpj-validator'
import { DateTime } from 'luxon'

function Input({
  as,
  className,
  defaultValue,
  disabled,
  errorMessage,
  isInvalid,
  validationType,
  type,
  mask,
  name,
  onChange,
  value,
  label,
  placeholder,
  required,
  ...otherProps
}) {
  const [error, setError] = useState('')
  const validator = {
    email: validateEmail,
    cpf: validateCPF,
    cnpj: validateCNPJ,
    crm: validateCRM,
    birthday: validateBirthday,
    cardNumber: validateCardNumber,
  }

  function validateEmail(value) {
    const regexValidEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!value.match(regexValidEmail)) {
      setError('E-mail inválido')
      return false
    }
    setError('')
    return true
  }

  function validateCPF(value = '') {
    const cpfNumber = value.replace(/\/|-|\./g, '').match(/\d+/g)
    if (cpfNumber?.length > 0 && !cpf.isValid(cpfNumber[0])) {
      setError('CPF inválido')
      return false
    }
    setError('')
    return true
  }

  function validateCNPJ(value) {
    const employerNumber = value.replace(/\/|-|\./g, '').match(/\d+/g)
    if (employerNumber && employerNumber[0].length !== 14) {
      setError('CNPJ inválido')
      return false
    }
    setError('')
    return true
  }

  function validateCRM(value) {
    const medicNumber = value.match(/\d+/g)
    if (medicNumber && medicNumber[0].length !== 6) {
      setError('CRM inválido')
      return false
    }
    setError('CRM inválido')
    return true
  }

  function validateBirthday(value) {
    const valueToDate = value.split('/').reverse().join('-')
    const inputDate = DateTime.fromISO(valueToDate)
    const currentDate = DateTime.fromISO(DateTime.now())
    if (inputDate > currentDate || !inputDate.isValid) {
      setError('Data inválida')
      return false
    }
    setError('')
    return true
  }

  function validateCardNumber(value) {
    const cardNumber = value.replace(/ /g, '').match(/\d+/g)
    if (cardNumber && cardNumber[0].length !== 17) {
      setError('Número do cartão inválido')
      return false
    }
    setError('')
    return true
  }

  function handleOnChange(e) {
    const { value } = e.target
    let valid = validationType ? validator[validationType](value) : true
    if (required && !value) {
      valid = false
      setError('Campo obrigatório')
    } else if (!validationType) {
      setError('')
    }

    if (onChange) onChange(e, valid)
  }

  function handleKeyDown(event) {
    // Does not allow any char when type is number
    if (type === 'number' && !/[0-9]/.test(event.key) && event.key !== 'Backspace') {
      event.preventDefault()
    }
  }

  return (
    <Form.Group className={className}>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <FormControl
        onKeyDown={handleKeyDown}
        as={(mask && MaskedInput) || as || 'input'}
        defaultValue={defaultValue}
        disabled={disabled}
        isInvalid={error || isInvalid}
        placeholder={placeholder}
        onChange={handleOnChange}
        mask={mask}
        value={value}
        name={name}
        required={required}
        type={type}
        {...otherProps}
      />
      <Form.Control.Feedback type="invalid">{errorMessage || error || 'Campo obrigatório'}</Form.Control.Feedback>
    </Form.Group>
  )
}

Input.propTypes = {
  as: PropTypes.func,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.node,
  mask: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  type: PropTypes.string,
  validationType: PropTypes.oneOf(['email', 'cpf', 'cnpj', 'crm', 'birthday', 'cardNumber']),
  otherProps: PropTypes.any,
}

export default Input
