import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Typography from 'components/atoms/Typography'
import SVG from 'react-inlinesvg'
import IconOpenEmail from 'assets/img/icon-open-email.svg'
import CustomModal from 'components/molecules/CustomModal'
import { hideEmail } from 'helpers/strings'

// Styles
import './EmailSuccess.scss'

function EmailSuccess({ title, changeStep, email, response, handleResendEmail, handleSendOtherEmail }) {
  const [openModalResendEmail, setOpenModalResendEmail] = useState(false)
  return (
    <section className="email-success">
      <div className="content">
        {title && (
          <div className="login-header">
            <div className="container">
              <Typography variant="p" cssClass="title">
                {title}
              </Typography>
            </div>
          </div>
        )}
        <div className="email-success-body">
          <div className="container success">
            <SVG
              className="icon"
              src={IconOpenEmail}
              preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
              width="100%"
              height="100%"
            />
            {response && !response.success ? (
              <>
                <p className="text">
                  <strong>Não foi encontrado</strong> um usuário para o e-mail:
                </p>
                <p className="mail">{hideEmail(email)}</p>
                <p className="text">Verifique se digitou corretamente ou efetue um novo cadastro.</p>
                <div className="cta">
                  <Button
                    type="button"
                    variant="outline-primary"
                    size="lg"
                    className="done mx-2"
                    onClick={() => {
                      changeStep('LoginForm')
                    }}
                  >
                    <span>Login</span>
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    size="lg"
                    className="login mx-2"
                    onClick={() => {
                      changeStep('LoginRegister')
                    }}
                  >
                    <span>Cadastre-se</span>{' '}
                    <span>
                      <i className="icone-arrow-right" />
                    </span>
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="text">Enviamos um e-mail de confirmação para o endereço cadastrado</p>
                <p className="mail">{hideEmail(email)}</p>
                <p className="text">
                  Acesse a mensagem em sua caixa de entrada e clique no link para confirmar seu cadastro.
                </p>
                <div className="cta d-flex flex-wrap">
                  <Button
                    type="button"
                    variant="outline-primary"
                    size="lg"
                    className="done mx-2"
                    onClick={() => changeStep('LoginForm')}
                  >
                    <span>Login</span>
                  </Button>
                  <Button
                    type="button"
                    variant="primary"
                    size="lg"
                    className="resend-email mt-3 mt-lg-0 d-flex"
                    onClick={() => {
                      handleResendEmail()
                      setOpenModalResendEmail(true)
                    }}
                  >
                    <i className="icone-email mr-1" />
                    <span>Enviar novamente</span>
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <CustomModal
        open={openModalResendEmail}
        closeModal={() => setOpenModalResendEmail(false)}
        size="sm"
        title="Enviamos um novo link para você"
      >
        <div className="ModalLogin__modal-container">
          <p>
            Verifique novamente seu e-mail, dentro de alguns minutos, incluindo as caixas de spam, lixo eletrônico ou
            mala direta. Alguns provedores e prestadores de serviços da internet (ISPs) podem filtrar ou bloquear nossas
            mensagens.
          </p>
          <p>Caso você não tenha recebido, entre em contato com nossos atendimentos:</p>
          <ul>
            <li>Cliente PF: 0800-642 2002</li>
            <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
            <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
            <li>Prestador: Entre em contato pelo 3021-9420</li>
            <li>Fornecedor: 3021-9420</li>
          </ul>
          <Button
            type="button"
            variant="primary"
            size="lg"
            className="send-another-email"
            onClick={() => {
              handleSendOtherEmail()
            }}
          >
            <span className="mr-2">Fechar</span>{' '}
            <span>
              <i className="icone-arrow-right" />
            </span>
          </Button>
        </div>
      </CustomModal>
    </section>
  )
}

export default EmailSuccess
