export default `
fragment ParagraphMediaCarousel on ParagraphMediaCarousel {
  id
  uuid
  entityBundle
  sectionTitle
  medias {
    entity {
      ...Media
    }
  }
}
`