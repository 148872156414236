import apiClient from 'api/config/apiClient'
import http from 'http'
import https from'https'

const agents = {
  httpAgent: new http.Agent({ keepAlive: true }),
  httpsAgent: new https.Agent({ keepAlive: true })
}

export default {
  generateDocs(payload) {
    if (payload.datepickerType === 'month') {
      return apiClient.request('cache-disable/payment_monthly_report', payload, agents)
    } else {
      return apiClient.request('cache-disable/payment_report', payload, agents)
    }
  },
  getReportLogs(payload) {
    return apiClient.request('cache-disable/get_report_logs', payload, agents)
  },
}
