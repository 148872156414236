export default `
fragment ParagraphImageGallery on ParagraphImageGallery {
  id
  entityBundle
  uuid
  tags {
    entity {
      tag_medias {
        images {
          size32x32 {
            url
          }
          size48x48 {
            url
          }
          thumbnail {
            url
          }
          medium {
            url
          }
          large {
            url
          }
          wide {
            url
          }
        }
      }
    }
  }
}
`