import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getBillingReport({ payload }) {
    try {
        yield put({ type: constants.BILLING_REPORT_LOADING, payload: true })

        const res = yield call(api.getBillingReport, payload)

        yield put({
            type: constants.BILLING_REPORT_DATA,
            payload: res,
        })

        yield put({ type: constants.BILLING_REPORT_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.BILLING_REPORT_ERROR, payload: e.message })
        yield put({ type: constants.BILLING_REPORT_LOADING, payload: false })
    }
}

function* orderByBillingReport({ payload }) {

    try {
        const res = yield call(api.orderByBillingReport, payload)
        yield put({
            type: constants.BILLING_REPORT_DATA,
            payload: res,
        })

    } catch (e) {
        console.error(`${e.message}`)
        yield put({ type: constants.BILLING_REPORT_ERROR, payload: e.message })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_BILLING_REPORT, getBillingReport)
    yield takeLatest(constants.ORDER_BILLING_REPORT, orderByBillingReport)
}