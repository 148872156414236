export default `
fragment ParagraphPlanWidget on ParagraphPlanWidget {
  id
  uuid
  entityBundle
  title
  alignment
  segment {
    entity {
      tid
      name
    }
  }
  description {
    value
  }
  removeFilters
  color {
    entity {
      name
      fieldColor {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
 }
}
`