import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { get } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import HtmlParser from 'react-html-parser'
import CustomButton from 'components/atoms/CustomButton'
import NavBar from 'components/organisms/NavBar'
import FormSearch from 'components/molecules/FormSearch'
import Typography from 'components/atoms/Typography'
import AccessibilityBar from 'components/organisms/AccessibilityBar'
import SectionTitle from 'components/molecules/SectionTitle'
import { userCheckMobileScreen } from 'helpers/tools'
import basicBannerStyle from './BasicBanner.scss'

function BasicBanner({ action, data, logged, user, refs, headline }) {
  const isMobile = userCheckMobileScreen()
  const id = get(data, 'id')
  const banner = isMobile ? get(data, 'media.entity.images.medium.url') : get(data, 'media.entity.images.wide.url')
  const title = get(data, 'title')
  const message = get(data, 'message')
  const searchForm = get(data, 'form_search')

  return (
    <>
      <Helmet>
        <style type="text/css">{basicBannerStyle.toString()}</style>
      </Helmet>
      <AccessibilityBar refs={refs} />
      <Container id={id} fluid="fluid" className={`basic-banner ${searchForm ? 'full-bg-lg' : 'full-bg'}`}>
        <img className="banner" src={banner} alt={get(data, 'media.entity.images.alt')} />
        <div className="overlay overlay--light" />

        <NavBar logged={logged} user={user} refs={refs} />

        <Container>
          <Row className="my-auto">
            <Col sm={12}>
              {headline ? (
                <>
                  <Typography
                    variant="h2"
                    color="white"
                    cssClass={`box-wrapper hero-headline mb-md-4 ${headline.title.length > 130 ? 'small' : ''}`}
                  >
                    {headline.title}
                  </Typography>
                  <SectionTitle
                    textColor="white"
                    variant="h1"
                    barColor="white"
                    className="hero-category"
                    title={headline.subtitle}
                    subtitle={headline.created || null}
                  ></SectionTitle>
                </>
              ) : (
                <Typography variant="h1" color="white" cssClass="box-wrapper hero-title mb-md-4">
                  {title}
                </Typography>
              )}
              {message && (
                <Typography color="white" cssClass="text-center">
                  {HtmlParser(message)}
                </Typography>
              )}
              {searchForm && (
                <div className="mt-4">
                  <FormSearch float placeholder="Procurar por..." />
                </div>
              )}
            </Col>
          </Row>
          {action && (
            <Row className="d-flex justify-content-center mb-3">
              <CustomButton href={action.url} text={action.text} iconAlign="right" color="secondary" />
            </Row>
          )}
        </Container>
      </Container>
    </>
  )
}

BasicBanner.propTypes = {
  action: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
  /**
   * Data from GraphQL Paragraph Object from Drupal
   */
  data: PropTypes.object,
  logged: PropTypes.bool,
}

BasicBanner.defaultProps = {
  data: null,
  logged: false,
}

export default BasicBanner
