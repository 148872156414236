import apiClient from 'api/config/apiClient'

export default {
  getRecipientsDate(payload) {
    return apiClient.request('recipient_date', payload)
  },
  getPinSSUtilization(payload) {
    return apiClient.request('pinss', payload)
  },
}
