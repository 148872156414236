export default `
fragment ParagraphBasicBanner on ParagraphBasicBanner {
  id
  uuid
  title
  entityBundle
  form_search
  media {
    entity {
      ...Media
    }
  }
}
`