export default`
fragment ParagraphInformationCardWithIcon on ParagraphInformationCardWithIcon {
  id
  entityBundle  
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
  title
  description {
    value
    format
  }  
  theme {
    entity {
      name
      classes
    }
  }  
}
`