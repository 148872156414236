export default `
fragment ParagraphWideBanner on ParagraphWideBanner {
  id
  uuid
  entityBundle
  media {
    entity {
      ...Media
    }
  }
}

`