import { takeLatest, takeEvery, call, put, delay } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getData({ payload }) {
  try {
    yield put({ type: constants.GET_DATA_ERROR, payload: false })
    yield put({ type: constants.LOADING_DATA, payload: true })

    const params = {
      ...payload,
      pathname:
        payload.pathname === '/index.html' || payload.pathname === '/' || payload.pathname === ''
          ? '/home'
          : payload.pathname,
    }
    const res = yield call(api.getData, params)

    yield put({
      type: constants.RESULT_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_DATA, payload: false })
    return res
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    let errorResponse = error?.response?.errors || e.toString()
    if (typeof error === 'object' && error[0]?.details) {
      errorResponse = error
    }
    yield put({ type: constants.GET_DATA_ERROR, payload: errorResponse })
    yield put({ type: constants.LOADING_DATA, payload: false })
  } finally {
    if (payload?.onComplete) {
      payload?.onComplete()
    }
  }
}

function* getHeaderMenus({ payload }) {
  try {
    yield put({ type: constants.LOADING_HEADER_MENUS, payload: true })

    const params = { name: payload }
    const res = yield call(api.getHeaderMenus, params)

    yield put({
      type: constants.HEADER_MENUS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_HEADER_MENUS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_HEADER_MENUS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_HEADER_MENUS, payload: false })
  }
}

function* getApplicationMenus({ payload }) {
  try {
    if (payload == null) {
      yield put({
        type: constants.APPLICATION_MENUS_DATA,
        payload: null,
      })
      return false
    }

    yield put({ type: constants.LOADING_APPLICATION_MENUS, payload: true })

    const params = { name: payload.menu, store: payload.store }
    const res = yield call(api.getApplicationMenus, params)

    yield put({
      type: constants.APPLICATION_MENUS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_APPLICATION_MENUS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_APPLICATION_MENUS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_APPLICATION_MENUS, payload: false })
  }
}

function* getFooterMenus({ payload }) {
  try {
    yield put({ type: constants.LOADING_FOOTER_MENUS, payload: true })

    const res = yield call(api.getFooterMenus, payload)

    yield put({
      type: constants.FOOTER_MENUS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_FOOTER_MENUS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_FOOTER_MENUS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_FOOTER_MENUS, payload: false })
  }
}

function* getFooterSubMenus({ payload }) {
  try {
    yield put({ type: constants.LOADING_FOOTER_SUB_MENUS, payload: true })

    const res = yield call(api.getFooterSubMenus, payload)

    yield put({
      type: constants.FOOTER_SUB_MENUS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_FOOTER_SUB_MENUS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_FOOTER_SUB_MENUS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_FOOTER_SUB_MENUS, payload: false })
  }
}

function* getAccessMenus({ payload }) {
  try {
    yield put({ type: constants.LOADING_ACCESS_MENUS, payload: true })

    const res = yield call(api.getAccessMenus, payload)

    yield put({
      type: constants.ACCESS_MENUS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_ACCESS_MENUS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_ACCESS_MENUS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_ACCESS_MENUS, payload: false })
  }
}

function* getPlans({ payload }) {
  try {
    const statePayload = {}

    statePayload[payload.uuid] = true
    yield put({ type: constants.LOADING_PLANS, payload: statePayload })

    const res = yield call(api.getPlans, payload)
    statePayload[payload.uuid] = res

    yield put({
      type: constants.PLANS_DATA,
      payload: statePayload,
    })

    statePayload[payload.uuid] = false
    yield put({ type: constants.LOADING_PLANS, payload: statePayload })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_PLANS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_PLANS, payload: false })
  }
}

function* getPlanFilters({ payload }) {
  try {
    yield put({ type: constants.LOADING_PLAN_FILTERS, payload: true })

    const res = yield call(api.getPlanFilters, payload)

    yield put({
      type: constants.PLAN_FILTERS_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_PLAN_FILTERS, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.GET_PLAN_FILTERS_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_PLAN_FILTERS, payload: false })
  }
}

function* fetchSearch({ payload }) {
  try {
    yield put({ type: constants.LOADING_SEARCH, payload: true })

    const res = yield call(api.search, payload)

    yield put({
      type: constants.SEARCH_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_SEARCH, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.FETCH_SEARCH_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_SEARCH, payload: false })
  }
}

function* searchAutocomplete({ payload }) {
  yield delay(1000)

  try {
    yield put({ type: constants.LOADING_SEARCH_AUTOCOMPLETE, payload: true })

    const res = yield call(api.search, payload)

    yield put({
      type: constants.SEARCH_AUTOCOMPLETE_DATA,
      payload: res,
    })

    yield put({ type: constants.LOADING_SEARCH_AUTOCOMPLETE, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.FETCH_SEARCH_AUTOCOMPLETE_ERROR, payload: error?.response?.errors || e.toString() })
    yield put({ type: constants.LOADING_SEARCH_AUTOCOMPLETE, payload: false })
  }
}

function* fetchFooterData() {
  try {
    const res = yield call(api.getFooterData)

    yield put({
      type: constants.FOOTER_DATA,
      payload: res,
    })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.FOOTER_DATA_ERROR, payload: error?.response?.errors || e.toString() })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_DATA, getData)
  yield takeLatest(constants.GET_HEADER_MENUS, getHeaderMenus)
  yield takeLatest(constants.GET_APPLICATION_MENUS, getApplicationMenus)
  yield takeLatest(constants.GET_FOOTER_MENUS, getFooterMenus)
  yield takeLatest(constants.GET_FOOTER_SUB_MENUS, getFooterSubMenus)
  yield takeLatest(constants.GET_ACCESS_MENUS, getAccessMenus)
  yield takeEvery(constants.GET_PLANS, getPlans)
  yield takeLatest(constants.GET_PLAN_FILTERS, getPlanFilters)
  yield takeLatest(constants.FETCH_SEARCH, fetchSearch)
  yield takeLatest(constants.SEARCH_AUTOCOMPLETE, searchAutocomplete)
  yield takeLatest(constants.GET_FOOTER_DATA, fetchFooterData)
}
