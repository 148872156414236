import { GET_TOKEN_ERROR, RESULT_TOKEN, LOADING_TOKEN } from 'store/constants'

export const initialState = {
  token: {},
  tokenError: false,
  loadingToken: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESULT_TOKEN:
      return { ...state, token: action.payload }
    case GET_TOKEN_ERROR:
      return { ...state, tokenError: action.payload }
    case LOADING_TOKEN:
      return { ...state, loadingToken: action.payload }
    default:
      return state
  }
}
