import React, { useEffect, useContext } from 'react'
import Helmet from 'react-helmet'
import classnames from 'classnames'
import NavMobile from 'components/organisms/NavMobile'
import NavDesktop from 'components/organisms/NavDesktop'
import { ReactReduxContext, useDispatch, useSelector } from 'react-redux'
import { getApplicationMenus, getHeaderMenus } from 'store/modules/customPage/actions'
import isEqual from 'lodash.isequal'
import get from 'lodash.get'
import { getMenuName } from 'helpers/auth'
import navBarStyles from './NavBar.scss'

function NavBar({ logged, className, refs }) {
  const { store } = useContext(ReactReduxContext)
  const dispatch = useDispatch()
  const session = useSelector((state) => state?.auth?.user)

  let menus = !logged
    ? useSelector((state) => state.customPage.headerMenus, isEqual)
    : useSelector((state) => state.customPage.applicationMenus, isEqual)
  let error = !logged
    ? useSelector((state) => state.customPage.headerMenusError)
    : useSelector((state) => state.customPage.applicationMenusError)
  let loading = !logged
    ? useSelector((state) => state.customPage.loadingHeaderMenus)
    : useSelector((state) => state.customPage.loadingApplicationMenus)

  useEffect(() => {
    const currentMenu = menus?.menuQuery?.entity.name
    const isWrongMenuLoaded = session && currentMenu !== 'mainmenu' && session?.menu !== currentMenu
    const menuName = getMenuName(session)
    if ((!menus?.menuQuery || isWrongMenuLoaded) && !loading) {
      if (logged) {
        dispatch(getApplicationMenus({ menu: menuName, store }))
      } else {
        dispatch(getHeaderMenus({ public: true }))
      }
    }
  }, [logged, menus, loading])

  return (
    <div className={classnames('navbar-position', className)} ref={refs?.nav} tabIndex="-1">
      <Helmet>
        <style type="text/css">{navBarStyles.toString()}</style>
      </Helmet>
      {!(loading || error) && (
        <>
          <NavDesktop logged={logged} user={session} links={get(menus, 'menuQuery.entity.links')} refs={refs} />
          <NavMobile logged={logged} user={session} links={get(menus, 'menuQuery.entity.links')} />
        </>
      )}
    </div>
  )
}

export default NavBar
