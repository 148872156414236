import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import logo from 'assets/img/logotipo-unimed-curitiba.png'
import Typography from 'components/atoms/Typography'
import NavMobileList from 'components/molecules/NavMobileList'
import NavMobileLink from 'components/atoms/NavMobileLink'
import ModalLoggedUser from 'components/molecules/ModalLoggedUser'
import FormSearch from 'components/molecules/FormSearch'
import get from 'lodash.get'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

import './NavMobile.scss'

function NavMobile(props) {
  const history = useHistory()
  const location = useLocation()
  const [active, setActive] = useState('')
  const [dropdown, setDropdown] = useState(false)
  const [login, setLogin] = useState(false)
  const { links, logged, user } = props
  const [hasOutside, setHasOutside] = useState(false)

  const { user: session } = useSelector(state => state.auth)

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflowY = dropdown || login ? 'hidden' : 'auto'
  }, [dropdown, login])

  useEffect(() => {
    setDropdown(false)
  }, [location.pathname])

  const handleClick = (id) => {
    setActive(id === active ? '' : id)
    setLogin(false)
  }

  const handleLoginClick = () => {
    if (!login && !user) {
      if (location.pathname == '/login') {
        history.go(0)
      } else {
        history.push('/login')
      }
    } else {
      setActive('')
      setLogin(!login)
    }
  }

  const handleOutSideMenuUser = () => {
    setHasOutside(true)
    setLogin(!login)
    setTimeout(() => {
      setHasOutside(false)
    }, 500)
  }

  return (
    <section className={`navbar d-lg-none ${logged ? 'navbar--logged' : ''}`}>
      <nav aria-label="Menu de Navegaçao" className="container">
        <ul className="navbar-items">
          <li className="navbar-logo">
            <HashLink to="/">
              <img src={logo} alt="Unimed curitiba" className="navbar-img" />
            </HashLink>
          </li>
          <li>
            <button
              className={`navbar-login navbar-login--mobile ${session ? 'navbar-login--logged' : ''} ${login ? 'active' : ''
                }`}
              onClick={handleLoginClick}
              aria-haspopup="true"
              aria-label="Login"
            >
              {login ? (
                <i className="icone-close-book" role="presentation" />
              ) : (
                <i className="icone-person" role="presentation" />
              )}
              <span>{session && get(session, 'name') ? `Olá, ${get(session, 'name')}` : 'Entrar'}</span>
            </button>

            {session && <ModalLoggedUser active={login} user={session} handleOutSideMenuUser={handleOutSideMenuUser} />}
          </li>
          <li>
            <button className="navbar-menu" onClick={() => setDropdown(!dropdown)} aria-label="Abrir menu">
              <i className="icone-menu" role="presentation" />
            </button>

            <nav aria-label="Menu de Navegaçao" className={`navbar-menu-mobile ${dropdown ? 'active' : ''}`}>
              <section className="navbar-menu-header">
                <Typography variant="h6" color="white">
                  Menu
                </Typography>
                <button onClick={() => setDropdown(!dropdown)}>
                  <i className="icone-close-book" role="presentation" />
                </button>
              </section>

              <nav aria-label="Menu de Navegaçao">
                <ul>
                  {Array.isArray(links) &&
                    links.map((item) => (
                      <li key={get(item, 'label')}>
                        {Array.isArray(get(item, 'links')) && get(item, 'links').length > 0 ? (
                          <button
                            className="navbar-menu-link"
                            title={get(item, 'label')}
                            onClick={() => handleClick(get(item, 'label'))}
                          >
                            <span className="max-line-2">{get(item, 'label')}</span>
                          </button>
                        ) : (
                          <NavMobileLink
                            url={get(item, 'url.path')}
                            label={get(item, 'label')}
                            links={get(item, 'links')}
                          />
                        )}

                        <nav
                          aria-label="Menu de Navegaçao"
                          className={`navbar-menu-container ${get(item, 'label') === active ? 'active' : ''}`}
                        >
                          <section className="navbar-menu-header">
                            <button onClick={() => handleClick(get(item, 'label'))}>
                              <i className="icone-arrow-left" />
                            </button>
                            <Typography variant="h6" color="white">
                              {get(item, 'label')}
                            </Typography>
                            <button onClick={() => setDropdown(!dropdown)}>
                              <i className="icone-close-book" />
                            </button>
                          </section>

                          <ul>
                            {Array.isArray(get(item, 'links')) &&
                              get(item, 'links').map((item) => (
                                <li key={get(item, 'label')}>
                                  {Array.isArray(get(item, 'links')) && get(item, 'links').length > 0 ? (
                                    <NavMobileList links={get(item, 'links')} label={get(item, 'label')} />
                                  ) : (
                                    <NavMobileLink
                                      url={get(item, 'url.path')}
                                      label={get(item, 'label')}
                                      links={get(item, 'links')}
                                    />
                                  )}
                                </li>
                              ))}
                          </ul>
                        </nav>
                      </li>
                    ))}
                </ul>
              </nav>

              <div className="mt-4">
                <FormSearch active={(val) => (!val ? setActive('') : '')} placeholder="Procurar por..." />
              </div>
            </nav>
          </li>
        </ul>
      </nav>
    </section>
  )
}

NavMobile.propTypes = {
  links: PropTypes.array,
}

export default NavMobile
