export default `
fragment ParagraphInformationTag on ParagraphInformationTag {
  id
  uuid
  entityBundle
  title
  link {
    entity {
      ...ParagraphButton
    }
  }
  description {
    value
  }
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  colorPalette {
    entity {
      name
      colors {
        color
      }
    }
  }
}
`
