export default `
fragment ParagraphStep on ParagraphStep {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  media {
    entity {
      ...Media
    }
  }
  downloadButton {
    entity {
      ...ParagraphDownloadButton
    }
  }
  download {
    entity {
      media {
        entity {
          ...Media
        }
      }
      title
      absoluteLink {
        title
        url {
          path
        }
      }
    }
  }
}

`