import React, { useEffect, useContext, useRef } from 'react'
import { ReactReduxContext } from 'react-redux'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import { invalidateUser } from 'helpers/auth'
import { profileNamesForLoggedArea } from 'helpers/tools'
import '../ModalLogin/ModalLogin.scss'

function ModalLoggedUser(props) {
  const { store } = useContext(ReactReduxContext)
  const ref = useRef()
  const history = useHistory()
  const { active, user, handleOutSideMenuUser } = props

  useEffect(() => {
    const checkIfClickedOutside = (event) => {
      if (active && ref.current && !ref.current?.contains(event.target)) {
        handleOutSideMenuUser()
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [active])

  const logout = async () => {
    await invalidateUser(store)
    history.push('/')
  }
  return (
    <>
      {active && (
        <section className="ModalLogin ModalLogin--logged">
          <div className="overlay" />
          <div className="container">
            <div className="ModalLogin__modal-box" ref={ref}>
              <div className="ModalLogin__content">
                <HashLink to="/change-profile">Trocar Perfil</HashLink>
                <HashLink to={`/app/home-${profileNamesForLoggedArea(user?.menu)}`}>Minha área de trabalho</HashLink>
              </div>

              <footer className="ModalLogin__footer">
                <button onClick={logout}>
                  Desconectar <i className="icone-logout" />
                </button>
              </footer>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

ModalLoggedUser.propTypes = {
  active: PropTypes.bool.isRequired,
}

export default ModalLoggedUser
