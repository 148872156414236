import { gql } from 'graphql-request'

export default gql`
query getUserData($user: String!) {
  applications {
    atosUsers {
      atosUsersResult(user: $user) {
        dados {
          COD_USUARIO
          CHAVE
        }
      }
    }
  }
}`
