export default `
fragment ParagraphDefinitionWidget on ParagraphDefinitionWidget {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  category {
    entity {
      tid
      name
      definitions {
        entities {
          ...on NodeDefinition {
            uuid
            view
            title
            created
            url {
              path
            }
            situation
            condition
            revision
            validity
            media {
              entity {
                ...Media
              }
            }            
          }
        }
      }
    }
  }
} 
`