export default `
fragment ParagraphMediaFull on ParagraphMediaFull {
  id
  uuid
  entityBundle
  media{
    entity {
      ...Media
    }
  }
  button{
    entity {
      ...ParagraphButton
    }
  }
  card {
    entity {
      ...ParagraphInformationCard
    }
  }
  backgroundColor {
    color
  }
  secBackgroundColor{
    color
  }
  description {
    value
  }
  media{
    entity {
      ...Media
    }
  }
  title
}
`
