import React from 'react'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types'
import ReactHtmlParser from 'react-html-parser'

import './CardPhone.scss'

function CardPhone({ description, icon, iconFont, maxLine, text, type }) {
  return (
    <a href={`tel:${text}`} title={`Ligar para o número ${text}`}>
      <div className={`card-phone paper-${type} paper-root`}>
        <div className="paper-header">
          {icon && !iconFont && (
            <SVG
              className="card-phone-icon"
              src={icon}
              alt={'icone'}
              preProcessor={(code) => code.replace(/fill=".*?"/g, `fill="var(--${type})"`)}
            />
          )}

          {iconFont && <i className={`icone-${icon}`} />}
        </div>

        <div className="paper-body">
          <div className={`max-line-${maxLine}`}>{ReactHtmlParser(description)}</div>
        </div>

        <div className="paper-footer">{text}</div>
      </div>
    </a>
  )
}

CardPhone.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.string,
  iconFont: PropTypes.bool,
  maxLine: PropTypes.number,
  text: PropTypes.string,
  type: PropTypes.string,
}

export default CardPhone
