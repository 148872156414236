import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* setToken({ payload }) {
  try {
    if (payload) {
      payload.last_request = new Date()
    }
    yield put({ type: constants.RESULT_TOKEN, payload: payload })
  } catch (e) {
    yield put({ type: constants.GET_TOKEN_ERROR, payload: e })
  }
}
function* getToken({ payload }) {
  try {
    yield put({ type: constants.LOADING_TOKEN, payload: true })
    const res = yield call(api.getToken, payload)
    yield put({ type: constants.RESULT_TOKEN, payload: res?.token || null })
    yield put({ type: constants.LOADING_TOKEN, payload: false })
    yield put({ type: constants.GET_TOKEN_ERROR, payload: false })
  } catch (error) {
    yield put({ type: constants.GET_TOKEN_ERROR, payload: error })
    yield put({ type: constants.LOADING_TOKEN, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.SET_TOKEN, setToken)
  yield takeLatest(constants.GET_TOKEN, getToken)
}
