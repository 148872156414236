import apiClient from 'api/config/apiClient'
import { sortBy } from 'lodash'

export default {
  getBillingReport(payload) {
    return apiClient.request('billing_report', payload)
  },

  orderByBillingReport(query) {
    const { order, values } = query

    if (values.applications && order.direction == 'asc') {
      let asc = sortBy(values.applications?.billingReport.billingReportsList.reports, order.column).reverse()
      values.applications.billingReport.billingReportsList.reports = asc
      return values
    } else if (values.applications && order.direction == 'desc') {
      let desc = sortBy(values.applications?.billingReport.billingReportsList.reports, order.column)
      values.applications.billingReport.billingReportsList.reports = desc
      return values
    }
  },
}
