import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getQuickAccess({ payload }) {
  try {
    yield put({ type: constants.QUICK_ACCESS_LOADING, payload: true })
    yield put({ type: constants.QUICK_ACCESS_ERROR, payload: null })
    const res = yield call(api.getQuickAccess, payload)
    yield put({
      type: constants.QUICK_ACCESS_DATA,
      payload: res,
    })
    yield put({ type: constants.QUICK_ACCESS_LOADING, payload: false })
  } catch (e) {
    const error = JSON.parse(JSON.stringify(e, undefined, 2))
    yield put({ type: constants.QUICK_ACCESS_ERROR, payload: error.message })
    yield put({ type: constants.QUICK_ACCESS_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_QUICK_ACCESS_DATA, getQuickAccess)
}
