export default `
fragment ParagraphPlanOption on ParagraphPlanOption {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
}
`