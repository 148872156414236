export default `
fragment ParagraphTagTransparentBanner on ParagraphTagTransparentBanner {
  id
  uuid
  entityBundle
  title
  subtitle
  icon {
    entity {
      name
      media {
        entity {
          ...Media
        }
      }
    }
  }
}
`