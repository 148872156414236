export default `
fragment ParagraphNewsWidget on ParagraphNewsWidget {
  uuid
  entityBundle
  category {
    entity {
      tid
      name
    }
  }
  news {
    pager {
      items_per_page
      total_pages
      total_items
      current_page
    }
    items {
      title
      promote
      body {
        value
        summary
      }
      url {
        path
      }
      media {
        entity {
          ...Media
        }
      }
      created
      updated
    }
  }  
}
`
