export default `
fragment ParagraphCountdown on ParagraphCountdown {
  id
  uuid
  entityBundle
  title
  datetime
  theme {
    entity {
      name
      classes
    }
  }
  background_color {
    color
  }
  media {
    entity {
      ...Media
    }
  }
}
`
