import apiClient from 'api/config/apiClient'

export default {
  getCards(payload) {
    return apiClient.request('cards', payload)
  },
  getCardRecipient(payload) {
    return apiClient.request('card_recipient', payload)
  },
  updateCardRecipient(payload) {
    return apiClient.request('update_card_recipient', payload)
  },
  searchZipCode(payload) {
    return apiClient.request('zipcode_list', payload)
  },
}
