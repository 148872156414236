import {startsWithHttp} from 'helpers/strings'
import React from 'react'
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import NavMobileList from 'components/molecules/NavMobileList'
import { get } from 'lodash'

function NavMobileLink(props) {
  const url = get(props, 'url')
  const label = get(props, 'label')
  const links = get(props, 'links')
  const history = useHistory()

  if (Array.isArray(links) && links.length > 0) {
    return  <NavMobileList links={links} label={label} />
  }

  return startsWithHttp(url) ?
    (
      <a
        className="navbar-menu-link"
        href={url}
        title={label}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="max-line-2">{label}</span>
        <i className="icone-diagonal-arrow-right-up" />
      </a>
    ) : (
      <button
        className="navbar-menu-link"
        title={label}
        onClick={() => history.push(url)}
      >
        <span className="max-line-2">{label}</span>
      </button>
    )
}

NavMobileLink.propTypes = {
  label: PropTypes.string.isRequired,
  url: PropTypes.string,
  items: PropTypes.array,
}

export default NavMobileLink