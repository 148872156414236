import {
  FAQ_CATEGORY_DATA, GET_FAQ_CATEGORY_ERROR, LOADING_FAQ_CATEGORY,
} from 'store/constants'

export const initialState = {
  faqCategory: {},
  loadingFaqCategory: true
}

export default (state = initialState, action) => {
  switch (action.type) {

    case FAQ_CATEGORY_DATA:
      return { ...state, faqCategory: action.payload }
    case GET_FAQ_CATEGORY_ERROR:
      return { ...state, faqCategoryError: action.payload }
    case LOADING_FAQ_CATEGORY:
      return { ...state, loadingFaqCategory: action.payload }
    default:
      return state
  }
}
