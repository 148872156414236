import React, { Fragment, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import Helmet from 'react-helmet'
import isEqual from 'lodash.isequal'
import { isClient } from 'helpers/tools'
import LandingPages from 'components/templates/LandingPages'
import ErrorPages from 'components/templates/ErrorPages'

const LandingPage = ({ user, refs }) => {
  const { data, dataError, loading } = useSelector(({ customPage }) => customPage, isEqual)
  const location = useLocation()
  const metaTags = data?.route?.entity?.metaTags
  let metaTitle = ''

  const getMeta = () => {
    let metaOgTitle = ''
    let metaDescription = ''
    let metaOgDescription = ''
    let twitterCreator = ''
    let twitterTitle = ''
    let twitterDescription = ''
    let meta = []

    setMetaTitleAndDescription()

    return [
      {
        name: `description`,
        content: metaDescription,
      },
      {
        property: `og:title`,
        content: metaOgTitle,
      },
      {
        property: `og:description`,
        content: metaOgDescription,
      },
      {
        property: `og:type`,
        content: `website`,
      },
      {
        name: `twitter:card`,
        content: `summary`,
      },
      {
        name: `twitter:creator`,
        content: twitterCreator,
      },
      {
        name: `twitter:title`,
        content: twitterTitle,
      },
      {
        name: `twitter:description`,
        content: twitterDescription,
      },
    ].concat(meta)
  }

  useEffect(async () => {
    getMeta()
  }, [location])

  const setMetaTitleAndDescription = () => {
    metaTags?.map((item) => {
      if (item.key === 'title') metaTitle = item?.value
      if (item.key === 'description') metaDescription = item?.value
    })
  }

  if (loading) return <Spinner animation="border" />
  if (dataError) return <ErrorPages errors={dataError} user={user} refs={refs} />
  if (data.route) {
    const { status_code, redirect_url } = data.route
    if (status_code?.match(/30[0-9]/)) {
      return <Redirect to={redirect_url} />
    }
    if (status_code?.match(/40[0-9]/)) {
      return <ErrorPages status={status_code} errors={dataError} user={user} refs={refs} />
    }
    return (
      <Fragment>
        {isClient() && loading && (
          <div className="loading-page">
            <Spinner className="loading-page__spinner" size="lg" animation="border" variant="white" />
          </div>
        )}
        <Helmet defaultTitle={metaTitle} meta={getMeta()} />
        <LandingPages data={data} user={user} refs={refs} />
      </Fragment>
    )
  }
  return <ErrorPages error="404" errors={dataError} user={user} refs={refs} />
}

export default LandingPage
