import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getEmployeeCards({ payload }) {
    try {
        yield put({ type: constants.EMPLOYEE_CARDS_LOADING, payload: true })

        const res = yield call(api.getEmployeeCards, payload)

        yield put({
            type: constants.EMPLOYEE_CARDS_DATA,
            payload: res,
        })

        yield put({ type: constants.EMPLOYEE_CARDS_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.EMPLOYEE_CARDS_ERROR, payload: e.message })
        yield put({ type: constants.EMPLOYEE_CARDS_LOADING, payload: false })
    }
}


export default function* saga() {
    yield takeLatest(constants.GET_EMPLOYEE_CARDS, getEmployeeCards)
}