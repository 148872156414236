export default `
fragment ParagraphLinkListLeftImage on ParagraphLinkListLeftImage {
  id
  uuid
  entityBundle
  title
  links {
    entity {
      ...ParagraphLinkWithTitleAndSubtitle
    }
  }
}
`