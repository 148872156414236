import React from 'react'
import LinkHandler from 'components/atoms/LinkHandler'
import PropTypes from 'prop-types'
import { startsWithHttp } from 'helpers/strings'
import get from 'lodash.get'

import './Links.scss'

function Links({ ulClass, links, liClass, linkClass, idNav }) {
  const label = (links && links.length > 0) ? links[0]?.label : ''
  return (
    <nav aria-label={idNav || label} title={idNav}>
      <ul className={ulClass}>
        {Array.isArray(links) &&
          links.map((item) => {
            const linkUrl = get(item, 'url.path', '') || get(item, 'link.url.path')
            return (
              <li className={liClass} key={item.label}>
                {startsWithHttp(linkUrl) ? (
                  <a
                    id={item.id}
                    className={linkClass}
                    href={linkUrl}
                    title={item.label}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.label} <i className="icone-diagonal-arrow-right-up" role="presentation" />
                  </a>
                ) : (
                  <LinkHandler id={item.id} className={linkClass} to={linkUrl} key={item.label}>
                    {item.label}
                  </LinkHandler>
                )}
              </li>
            )
          })}
      </ul>
    </nav>
  )
}

Links.propTypes = {
  idNav: PropTypes.string,
  ulClass: PropTypes.string,
  liClass: PropTypes.string,
  linkClass: PropTypes.string,
  links: PropTypes.array,
}

export default Links
