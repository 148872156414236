import React from 'react'
import Helmet from 'react-helmet'
import get from 'lodash.get'
import { Col, Container, Row } from 'react-bootstrap'
import Typography from 'components/atoms/Typography'
import LinkHandler from 'components/atoms/LinkHandler'
import IconPeople from 'assets/img/people.svg'
import perfilBarStyles from './PerfilBar.scss'

function PerfilBar({ user }) {
  const menu = get(user, 'menu')
  const name = get(user, 'name')
  const id = get(user, 'id')
  const account = get(user, 'account')
  const contract = get(user, 'contract')
  const original_user = get(user, 'original_user')
  const profileMap = {
    mainmenu: 'Área Logada',
    client: 'Canal do(a) Cliente',
    clientepj: 'Canal de Cliente Empresa',
    cooperado: 'Canal do(a) Médico(a) Cooperado(a)',
    secretaria: 'Canal de Secretárias(os)',
    prestador: 'Canal de Prestadores', 
    sinapse: 'Canal Sinapse', 
    tasyprestador: 'Canal de Prestadores',
    webprestadorautorizado: 'Canal de Prestadores',
    fornecedor: 'Canal de Fornecedores',
    simulador_de_acesso: 'Canal de Simulação de Acesso',
    auditor: 'Canal de Auditores',
    webresponsavelfinpf: 'Responsável Financeiro PF',
    webresponsavelfinpj: 'Responsável Financeiro PJ',
    empresacobranca: 'Empresa de cobrança',
  }

  return (
    <div className={`PerfilBar perfil-bar__wrapper ${menu}`}>
      <Helmet>
        <style type="text/css">{perfilBarStyles.toString()}</style>
      </Helmet>
      <div className={`PerfilBar__perfil-bar-detail perfil-bar__detail ${menu}`} />
      <Container className="d-flex flex-column" backgroundcolor="green-dark-100">
        <Row>
          <Col xs={12} md={2}>
            <img className="PerfilBar__perfil-bar-icon perfil-bar__icon" src={IconPeople} />
          </Col>
          {user && (
            <>
              <Col xs={7}>
                <p className="PerfilBar__perfil-bar-intro perfil-bar__intro">
                  Olá <strong>{account || name}</strong>, você está em
                </p>
                <h2 className="PerfilBar__perfil-bar-title perfil-bar__title">{profileMap[menu]}</h2>
                {original_user && original_user?.id != id && (
                  <p className="pl-4">
                    Simulado por <strong>{original_user.name}</strong>
                  </p>
                )}
                <div className="PerfilBar__profile-wrapper">
                  <LinkHandler className="PerfilBar__profile align-items-center" to="/change-profile">
                    <i className="icone-change mr-1" />
                    Trocar perfil
                  </LinkHandler>
                </div>
              </Col>
              <Col xs={5} md={3}>
                <Typography className="PerfilBar__perfil-bar-intro perfil-bar__intro  perfil-bar__cod" variant="p">
                  <b>Perfil de Usuário(a): </b>
                  <br /> {name}
                  <br />
                  <br />
                  <b>Código Acesso: </b>
                  <br /> {contract}
                </Typography>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </div>
  )
}

export default PerfilBar
