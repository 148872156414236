import { all, fork } from 'redux-saga/effects'
import auth from './modules/auth/saga'
import token from './modules/token/saga'
import faq from './modules/faq/saga'
import customPage from './modules/customPage/saga'
import paymentStatement from './modules/paymentStatement/saga'
import paymentStatementCopan from './modules/paymentStatementCopan/saga'
import unlockCards from './modules/unlockCards/saga'
import medicalSchedule from './modules/medicalSchedule/saga'
import webformSubmissions from './modules/webformSubmissions/saga'
import publicCivilAction from './modules/publicCivilAction/saga'
import billingReport from './modules/billingReport/saga'
import billingReportFinancialResponsible from './modules/billingReportFinancialResponsible/saga'
import readjustmentStatement from './modules/readjustmentStatement/saga'
import employeeCards from './modules/employeeCards/saga'
import quickAccess from './modules/quickAccess/saga'
import codeCBO from './modules/codeCBO/saga'
import getOutpatientAuthorizationConsultation from './modules/outpatientAuthorizationConsultation/saga'
import benefitEligibility from './modules/benefitEligibility/saga'
import pinSSCadastral from './modules/pinSSCadastral/saga'
import pinSSUtilization from './modules/pinSSUtilization/saga'
import outpatientAuthorizationsProviderResult from './modules/outpatientAuthorizationsProviderResult/saga'
import atos from './modules/atos/saga'
import referencedList from './modules/referencedList/saga'
import recipientRegisterData from './modules/recipientRegister/saga'

export default function* root() {
  yield all([
    fork(auth),
    fork(token),
    fork(unlockCards),
    fork(paymentStatement),
    fork(paymentStatementCopan),
    fork(faq),
    fork(customPage),
    fork(medicalSchedule),
    fork(webformSubmissions),
    fork(publicCivilAction),
    fork(billingReport),
    fork(quickAccess),
    fork(readjustmentStatement),
    fork(codeCBO),
    fork(employeeCards),
    fork(getOutpatientAuthorizationConsultation),
    fork(benefitEligibility),
    fork(pinSSCadastral),
    fork(pinSSUtilization),
    fork(outpatientAuthorizationsProviderResult),
    fork(atos),
    fork(referencedList),
    fork(recipientRegisterData),
    fork(billingReportFinancialResponsible),
  ])
}
