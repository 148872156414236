import { gql } from 'graphql-request'

export default gql`
  query getSearch(
    $terms: String!
    $type: [String!]
    $doctype: [String!]
    $limit: Int
    $page: Int
    $sort_by: String
    $sort_order: String
  ) {
    searchQuery(
      terms: $terms
      limit: $limit
      page: $page
      sort_by: $sort_by
      sort_order: $sort_order
      type: $type
      doctype: $doctype
    ) {
      content_types {
        home
        news
        plan
        institutional
        download
      }
      rows {
        id
        search_api_relevance
        title
        type
        vapn
        summary
        doctype
        url
        thumbnail
      }
      pager {
        total_items
        current_page
        total_pages
        items_per_page
      }
    }
  }
`
