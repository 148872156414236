import React, { useState, useEffect } from 'react'
import { isClient } from 'helpers/tools'

function FontControl() {
  const html = isClient() && document.querySelector('html')
  const htmlFontSize = isClient() && parseInt(getComputedStyle(html).fontSize)
  const userFontSize = isClient() && JSON.parse(localStorage.getItem('user-font-size'))
  const [baseFontSize, setBaseFontSize] = useState(userFontSize ? userFontSize : htmlFontSize)

  useEffect(() => {
    html.style.fontSize = `${baseFontSize}px`
    localStorage.setItem('user-font-size', baseFontSize)
  }, [baseFontSize])

  function handleChangeMinus() {
    if (baseFontSize - 2 > 10) {
      setBaseFontSize(baseFontSize - 2)
    }
  }

  function handleChangePlus() {
    setBaseFontSize(baseFontSize + 2)
  }

  return (
    <li>
      <p className="d-none d-sm-inline">Tamanho do texto:</p>
      <ul>
        <li>
          <button id="fc_a_plus" onClick={handleChangePlus}>
            A+
          </button>
        </li>
        <li>
          <button id="fc_a_minus" onClick={handleChangeMinus}>
            A-
          </button>
        </li>
      </ul>
    </li>
  )
}

export default FontControl
