import {
  GET_RECIPIENT_REGISTER_DATA,
  RECIPIENT_REGISTER_DATA,
  RECIPIENT_REGISTER_ERROR,
  RECIPIENT_REGISTER_LOADING,
  UPDATE_RECIPIENT_REGISTER_DATA,
  UPDATE_RECIPIENT_REGISTER_ERROR,
  UPDATE_RECIPIENT_REGISTER_LOADING,
  SEARCH_ZIPCODE_REGISTER_DATA,
  SEARCH_ZIPCODE_REGISTER_ERROR,
  SEARCH_ZIPCODE_REGISTER_LOADING,
} from 'store/constants'

export const initialState = {
  recipient: null,
  errorRecipent: null,
  loadingRecipent: null,
  updateRecipient: null,
  errorUpdateRecipient: null,
  loadingUpdatedRecipient: null,
  searchZipCode: null,
  errorSearchZipCode: null,
  loadingSearchZipCode: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RECIPIENT_REGISTER_DATA:
      return { ...state, recipient: action.payload?.applications?.registrationUpdate?.cardRecipient }
    case RECIPIENT_REGISTER_DATA:
      return { ...state, recipient: action.payload.applications.registrationUpdate?.cardRecipient }
    case RECIPIENT_REGISTER_ERROR:
      return { ...state, errorRecipent: action.payload }
    case RECIPIENT_REGISTER_LOADING:
      return { ...state, loadingRecipent: action.payload }
    case UPDATE_RECIPIENT_REGISTER_DATA:
      return { ...state, updateRecipient: action.payload?.applications?.registrationUpdate?.cardRecipientUpdate }
    case UPDATE_RECIPIENT_REGISTER_ERROR:
      return { ...state, errorUpdateRecipient: action.payload }
    case UPDATE_RECIPIENT_REGISTER_LOADING:
      return { ...state, loadingUpdatedRecipient: action.payload }
    case SEARCH_ZIPCODE_REGISTER_DATA:
      return { ...state, searchZipCode: action.payload.applications.cardUnlock }
    case SEARCH_ZIPCODE_REGISTER_ERROR:
      return { ...state, errorSearchZipCode: action.payload }
    case SEARCH_ZIPCODE_REGISTER_LOADING:
      return { ...state, loadingSearchZipCode: action.payload }
    default:
      return state
  }
}
