export default `
fragment ParagraphCards on ParagraphCards {
  id
  uuid
  entityBundle
  title
  sectionTitle
  description {
    value
  }
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  items {
    entity {
      ...ParagraphCard
    }
  }
}
`