export const getNameColorFromHex = (hex) => {
  if (typeof hex !== 'string') return 'primary'

  switch (hex.toLowerCase()) {
    case '#00995d':
    case '#008752':
      return 'primary'
    case '#411564':
      return 'secondary'
    case '#35124d':
      return 'secondary-100'
    case '#280d39':
      return 'secondary-200'
    case '#f5f3f5':
      return 'secondary-300'
    case '#b1d34b':
      return 'success'
    case '#ff4136':
      return 'danger'
    case '#ffcb08':
      return 'warning'
    case '#17a2b8':
      return 'info'
    case '#f8f9fa':
      return 'light'
    case '#343a40':
      return 'dark'
    case '#210202':
      return 'dark-contrast-color'
    case '#fff':
    case '#ffffff':
      return 'white'
    case '#fff0c7':
      return 'yellow-light'
    case '#ed1651':
      return 'pink'
    case '#a3238e':
      return 'purple'
    case '#0a5f55':
      return 'green-dark-100'
    case '#00401a':
      return 'green-dark-200'
    case '#f26d0c':
      return 'orange'
    case '#682d00':
      return 'orange-dark'
    case '#c4cbcf':
      return 'gray-light'
    case '#ddd':
      return 'gray-000'
    case '#5b5c65':
      return 'gray-100'
    case '#222222':
      return 'gray-200'
    case '#e6e7e8':
    case '#e6e6e6':
      return 'gray-300'
    case '#f2f3f4':
      return 'gray-400'
    default:
      return 'primary'
  }
}

export function getElementAsync(id) {
  return new Promise((resolve) => {
    function getElement() {
      const element = document.querySelector(id)
      if (element) resolve(element)
      else requestAnimationFrame(getElement)
    }
    getElement()
  })
}

export const printElement = (contentSelector, title) => {
  const elementToPrint = document.querySelector(contentSelector)
  const head = document.querySelector('head')
  const content = `
  <html>
    <head>
      ${head.innerHTML}
    </head>
    <body onload="window.print()">
      ${title ? `<h2 class="title-semibold-lg title-text text-primary mb-3">${title}</h2>` : ''}
      ${elementToPrint ? elementToPrint.outerHTML : 'não encontrado'}
    </body>
  </html>`

  const windowToPrint = window.open('', 'Print-Window')
  windowToPrint.document.open()
  windowToPrint.document.write(content)
  windowToPrint.document.close()
}

/**
 * Validate whether the colour given is a light colour
 * @param {string} color  An hex number representing a colour
 * @returns {boolean}     Whether the colour brightness is low
 */
export const isHexLight = (color) => {
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substr(0, 2), 16)
  const c_g = parseInt(hex.substr(2, 2), 16)
  const c_b = parseInt(hex.substr(4, 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness > 155
}

export const userCheckMobileScreen = () => {
  if (isClient()) return window.innerWidth <= 768
}

export const isClient = () => {
  return typeof window !== 'undefined' && window.document
}

export const renderIndexItemClass = (firstItemRender, lastItemRender) => {
  if (firstItemRender) {
    return 'section-spacing-first'
  } else if (lastItemRender) {
    return 'section-spacing-last'
  } else {
    return 'section-spacing'
  }
}

export const getRemoteVideo = (url) => {
  if (!url) return false
  const isYoutube = url.includes('youtube') || url.includes('youtu.be')
  const urlRegex = /^.*((youtu.be\/|vimeo.com\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
  const urlId = url.match(urlRegex).pop()
  const videoUrl = isYoutube ? `http://youtube.com.br/embed/${urlId}` : `https://player.vimeo.com/video/${urlId}`
  const remoteThumb = isYoutube
    ? `https://img.youtube.com/vi/${urlId}/hqdefault.jpg`
    : `https://i.vimeocdn.com/video/${urlId}_1200x500.jpg`

  return { url: videoUrl, thumb: remoteThumb }
}

export const linkIconHandler = (linkType) => {
  // ref _variables.scss
  switch (linkType) {
    case 'internal':
      return 'icone-arrow-right'
    case 'external':
      return 'icone-arrow-right-up'
    case 'anchor':
      return 'icone-arrow-down'
    case 'download':
      return 'icone-download'
    case 'phone':
      return 'icone-phone'
    case 'email':
      return 'icone-email'
    case 'whatsapp':
      return 'icone-whatsapp'
    case 'smile':
      return 'icone-smile'
    case 'edit':
      return 'icone-edit'

    default:
      return 'icone-arrow-right'
  }
}

export const isFirefox = () => {
  return navigator.userAgent.indexOf('Firefox') != -1
}

export const checkKeyPress = ({ keyCode, altKey, shiftKey, crtlKey }, customKey, element, callback) => {
  if (
    (altKey && keyCode == customKey) ||
    (altKey && shiftKey && keyCode == customKey) ||
    (crtlKey && shiftKey && keyCode == customKey)
  ) {
    if (callback) callback()
    if (element) {
      element.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
      element.current.focus()
    }
  }
}

export const profileNamesForLoggedArea = (profile) => {
  const dictionary = {
    client: 'cliente',
    clientepj: 'empresa',
  }

  return dictionary[profile] || profile
}

// function to convert comma separated list of IPs to array without spaces
export const convertIP = (ip) => {
  let ipArray = ip.split(',')
  ipArray = ipArray.map((ip) => ip.trim())
  return ipArray
}

// function to compare lists of IPs against whitelist
export const checkIfIPIsInWhitelist = (ip, whitelist) => {
  const ipList = !Array.isArray(ip) ? convertIP(ip) : ip
  const ipWhitelist = !Array.isArray(whitelist) ? convertIP(whitelist) : whitelist
  let isIPInWhiteList = false

  ipList.forEach(ipItem => {
    if (ipWhitelist.includes(ipItem)) {
      isIPInWhiteList = true
    }
  })

  return isIPInWhiteList
}

export const isDebugMode = ({ host, search }) => {
  const process_env_prod = process?.env?.HOST_ENV?.toLocaleLowerCase()?.includes('prod') ?? false
  const accessibleHost = host ? host : process?.env?.S3FS_DOMAIN?.toLocaleLowerCase() ?? ''
  const host_prod = accessibleHost === 'www.unimedcuritiba.com.br' || accessibleHost === 'unimedcuritiba.com.br'
  const isTestEnvironment = !process_env_prod && !host_prod
  const query = new URLSearchParams(search)
  
  if (isTestEnvironment && query.has('debug') && query.get('debug') === 'true') {
    return true
  }
  return false
}

export default {
  profileNamesForLoggedArea,
  getNameColorFromHex,
  userCheckMobileScreen,
  renderIndexItemClass,
  linkIconHandler,
  isHexLight,
  getRemoteVideo,
  checkKeyPress,
  convertIP,
  checkIfIPIsInWhitelist,
  isDebugMode,
}