import { gql } from 'graphql-request'

export default gql`
  query getRecipientDetails($handle: String!) {
    applications {
      pinSSData {
        recipientDetails(handle: $handle) {
          data {
            name
            birth_date
            cns_number
            plan_number
            plan_segmentation
            registration_number
            ans_registration_number
            operator_contact
            ans_contact
            partial_coverage_end_date
            acommodation_standard
            contract_type
            coverage_area
            product_name
            operator_fantasy_name
            plan_validity_start_date
            legal_entity_contracting_plan
            benefits_administrator
            private_plan_name
            contract_policy_number
            regulamentation_info
            plan_contracting_date
            contract_coverage_start_date
            outpatient_procedures_term
            hospital_procedures_term
            delivery_procedures_term
            anual_adjustment
            adjustment_by_age_group
            validity_start_date
            validity_end_date
            company_destination
            contract_exchange
            share_risk_start_date
          }
        }
      }
    }
  }
`
