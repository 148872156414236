import { gql } from 'graphql-request'

export default gql`
    mutation approveReferencedList($files: [String]!) {
        applications {
			referencedList {
                referencedListUpdate(files: $files) {
                    success
                    message
                }
            }
        }
    }    
`
