export default `
fragment ParagraphFaqWidget on ParagraphFaqWidget {
  id
  uuid
  entityBundle
  title
  description {
    value
    format
  }
  category {
    entity {
      name
      tid
    }
  }
    theme {
        entity {
          name
          classes
        }
    }  
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
}
`