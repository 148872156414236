export default `
fragment ParagraphCard on ParagraphCard {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  link {
    entity {
      ...ParagraphButton
    }
  }
  image {
    entity{
      ...Media
    }
  }
}
`