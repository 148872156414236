import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Typography from 'components/atoms/Typography'
import CardPhone from 'components/molecules/CardPhone'
import FormSearch from 'components/molecules/FormSearch'

function PhoneTags() {
  return (
    <Container fluid="fluid" className="attendance-bg">
      <div className="overlay" />
      <Container className="py-5">
        <Row className="mt-1 mt-md-3">
          <Col sm={12} className="">
            <Typography
              variant="h3"
              color="white"
              cssClass="title-bold-md text-center"
            >
              Atendimento 24h
            </Typography>
          </Col>
        </Row>
        <Row className="py-3 py-md-4">
          <Col sm={12} md={4} className="clear-height">
            <CardPhone
              type="white"
              iconFont
              icon="talk"
              description="Ficou alguma dúvida sobre liberação de procedimentos e assuntos relacionados?"
              maxLine={3}
              text="41 3019-2000"
            />
          </Col>

          <Col sm={12} md={4} className="clear-height">
            <CardPhone
              type="white"
              iconFont
              icon="credit-card"
              description="Tire suas dúvidas, faça solicitações, desbloqueie seu cartão e envie suas críticas e sugestões:"
              maxLine={3}
              text="0800 642 2002"
            />
          </Col>

          <Col sm={12} md={4} className="clear-height">
            <CardPhone
              type="white"
              iconFont
              icon="phone-call"
              description="Serviço de atendimento especial para deficientes auditivos e de fala:"
              maxLine={3}
              text="0800 642 5005"
            />
          </Col>
        </Row>
        <Row className="py-md-5 mb-1">
          <Col sm={12}>
            <Typography
              variant="p"
              component=""
              color="white"
              cssClass="text-book text-center mb-4"
            >
              Procurando algo a mais?
            </Typography>
            <FormSearch placeholder="Digite sua dúvida..." />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default PhoneTags
