import {
    GET_OUTPATIENT_AUTHORIZATION_CONSULTATION,
    OUTPATIENT_AUTHORIZATION_CONSULTATION_LOADING,
    OUTPATIENT_AUTHORIZATION_CONSULTATION_DATA,
    OUTPATIENT_AUTHORIZATION_CONSULTATION_ERROR
} from 'store/constants'

export const initialState = {
    outpatientAuthorizationConsultationData: {},
    outpatientAuthorizationConsultationError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_OUTPATIENT_AUTHORIZATION_CONSULTATION:
            return { ...state, outpatientAuthorizationConsultationData: action.payload }
        case OUTPATIENT_AUTHORIZATION_CONSULTATION_DATA:
            return { ...state, outpatientAuthorizationConsultationData: action.payload }
        case OUTPATIENT_AUTHORIZATION_CONSULTATION_LOADING:
            return { ...state, loading: action.payload }
        case OUTPATIENT_AUTHORIZATION_CONSULTATION_ERROR:
            return { ...state, outpatientAuthorizationConsultationError: action.payload }
        default:
            return state
    }
}