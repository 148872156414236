import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { Form, Button, Spinner } from 'react-bootstrap'
import { get } from 'lodash'
import SVG from 'react-inlinesvg'
import IconGoogleColor from 'assets/img/icon-google-color.svg'
// import IconFacebook from 'assets/img/icon-facebook.svg'
import API_AUTH from 'store/modules/auth/api'
import sadSmile from 'assets/img/sad-smile-chat-box.svg'
import { useHistory, Redirect } from 'react-router-dom'

// Components
import Typography from 'components/atoms/Typography'
import CustomModal from 'components/molecules/CustomModal'
import EmailSuccess from '../EmailSuccess'

// Styles
import './LoginRegister.scss'

function LoginRegister(props) {
  const changeStep = get(props, 'changeStep')
  const history = useHistory()
  const [formValidated, setFormValidated] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [requiredPolicyPrivacy, setRequeridPolicyPrivacy] = useState(false)
  const [userRegister, setUserRegister] = useState(false)
  const [userRegisterLoading, setUserRegisterLoading] = useState(false)
  const [openModalError, setOpenModalError] = useState(false)
  const [error, setError] = useState('')
  const [openModalResendEmail, setOpenModalResendEmail] = useState(false)

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const form = e.currentTarget

    if (form.checkValidity()) {
      registerUser()
    }
    setFormValidated(true)
  }

  const sendOther = () => {
    setUserRegister(false)
  }

  const handleSendOtherEmail = () => {
    history.go(0)
  }

  const registerUser = async (resend = false) => {
    setError('')
    setUserRegisterLoading(true)
    const payload = {
      email: userEmail,
      name: userName,
      resend: resend,
      policyPrivacy: requiredPolicyPrivacy,
    }
    try {
      const response = await API_AUTH.registerUser(payload)
      if (response.addUser && response.addUser.message) {
        setError(response.addUser.message)
        setOpenModalError(true)
        setUserRegisterLoading(false)
      } else {
        setUserRegister(true)
        setUserRegisterLoading(false)
      }
    } catch (err) {
      // setError(err.response.errors[0])
      setOpenModalError(true)
      setUserRegisterLoading(false)
    }
  }

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    return !email ? false : regex.test(email) === false
  }

  return (
    <section className="login-register">
      {!userRegister ? (
        <div className="content">
          <div className="login-header">
            <div className="container">
              <Typography variant="p" cssClass="title">
                Fazer Cadastro
              </Typography>
            </div>
          </div>
          <div className="login-register-body">
            <div className="container">
              <Form className="login-register-area" noValidate validated={formValidated} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Nome Completo:</Form.Label>
                  <Form.Control
                    type="name"
                    id="name"
                    name="name"
                    aria-label="name"
                    required
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Informe seu nome.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Digite o seu e-mail:</Form.Label>
                  <Form.Control
                    type="email"
                    id="email"
                    name="email"
                    isInvalid={emailValidation(userEmail)}
                    aria-label="email"
                    required
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                  <Form.Control.Feedback type="invalid">Informe um e-mail válido.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="policyPrivacy">
                  <Form.Check
                    type="checkbox"
                    checked={requiredPolicyPrivacy}
                    onChange={({ target }) => setRequeridPolicyPrivacy(target.checked)}
                    required
                    invalid={!requiredPolicyPrivacy}
                    label={
                      <span>
                        Eu li, estou ciente das condições de tratamento dos meus dados pessoais e dou consentimento,
                        quando aplicável conforme descrito nesta{' '}
                        <HashLink to="/politica-de-privacidade" target="_blank">
                          Política de Privacidade
                        </HashLink>{' '}
                        e{' '}
                        <HashLink to="/termos-de-uso" target="_blank">
                          Termos de Uso
                        </HashLink>
                      </span>
                    }
                  ></Form.Check>
                  <Form.Control.Feedback type="invalid">
                    É necessário estar de acordo com a Política de Privacidade e os Termos de Uso.
                  </Form.Control.Feedback>
                </Form.Group>

                <Button
                  type="submit"
                  className="submit-button"
                  variant="primary"
                  size="lg"
                  block
                  disabled={userRegisterLoading}
                >
                  {userRegisterLoading ? <Spinner animation="border" size="sm" /> : 'Entrar'}
                </Button>
              </Form>
              <div className="forgot">
                <HashLink to="#" onClick={() => changeStep('ForgotAccess')}>
                  Esqueceu seu e-mail ou senha?{' '}
                </HashLink>
              </div>
            </div>
          </div>
          <div className="login-footer">
            <div className="divider">
              <span className="line left"></span>
              <p>ou</p>
              <span className="line right"></span>
            </div>
            <div className="container">
              <section className="social-login">
                <a className="login google" href="/user/login/google">
                  <SVG
                    className="icon"
                    src={IconGoogleColor}
                    preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                    width="100%"
                    height="100%"
                  />
                  <p className="text">Continuar com o Google</p>
                </a>
                {/* <a className="login facebook" href="/user/login/facebook">
                  <SVG
                    className="icon"
                    src={IconFacebook}
                    preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                    width="100%"
                    height="100%"
                  />
                  <p className="text">Continuar com o Facebook</p>
                </a> */}
              </section>
              <div className="register">
                <p>
                  Já tem cadastro?{' '}
                  <HashLink
                    to="#"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      history.push('/login')
                    }}
                  >
                    Entrar
                  </HashLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <EmailSuccess
          title="Verifique seu e-mail"
          changeStep={changeStep}
          handleResendEmail={() => {
            registerUser(true)
          }}
          handleSendOtherEmail={sendOther}
          email={userEmail}
        />
      )}
      <CustomModal
        open={openModalError}
        closeModal={() => setOpenModalError(false)}
        size="sm"
        title="Erro - Não foi possível concluir o cadastro."
      >
        <div className="d-flex justify-content-center mt-3 mb-2">
          <SVG src={sadSmile} />
        </div>
        <div className="ModalLogin__modal-container">
          {!error && (
            <>
              <p>Não foi possível concluir a operação, por favor tente novamente.</p>
              <p>Caso o erro persista entre em contato com nossos atendimentos:</p>
              <p>
                <ul>
                  <li>Cliente PF: 0800-642 2002</li>
                  <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
                  <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
                  <li>Prestador: Entre em contato pelo 3021-9420</li>
                  <li>Fornecedor: 3021-9420</li>
                </ul>
              </p>
              <p>Agradecemos a compreensão.</p>
            </>
          )}
          {error && <p>{error}</p>}
        </div>
      </CustomModal>
      <CustomModal
        open={openModalResendEmail}
        closeModal={() => setOpenModalResendEmail(false)}
        size="sm"
        title="Enviamos um novo link para você"
      >
        <div className="ModalLogin__modal-container">
          <p>
            Verifique novamente seu e-mail, dentro de alguns minutos, incluindo as caixas de spam, lixo eletrônico ou
            mala direta. Alguns provedores e prestadores de serviços da internet (ISPs) podem filtrar ou bloquear nossas
            mensagens.
          </p>
          <p>Caso você não tenha recebido, entre em contato com nossos atendimentos:</p>
          <p>
            <ul>
              <li>Cliente PF: 0800-642 2002</li>
              <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
              <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
              <li>Prestador: Entre em contato pelo 3021-9420</li>
              <li>Fornecedor: 3021-9420</li>
            </ul>
          </p>
          <Button
            type="button"
            variant="primary"
            size="lg"
            className="send-another-email"
            onClick={() => {
              handleSendOtherEmail()
            }}
          >
            <span className="mr-2">Fechar</span>{' '}
            <span>
              <i className="icone-arrow-right" />
            </span>
          </Button>
        </div>
      </CustomModal>
    </section>
  )
}

export default LoginRegister
