import { gql } from 'graphql-request'

export default gql`
  query getQuickAccess($role: String!) {
    quickAccessesQuery(role: $role) {
      index
      user {
        id
      }
      role {
        role
      }
      link {
        title
        url {
          path
        }
      }
    }
  }
`
