export default `
fragment ParagraphWebform on ParagraphWebform {
    entityBundle
    uuid
    form {
      id
    }
    formType {
      entity {
        ... on TermFormType {
          name
          type
        }
      }
    }
    theme {
      entity {
        name
        classes
      }
    } 
  }
`