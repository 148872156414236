export default `
fragment ParagraphExtendedImagesGrid on ParagraphExtendedImagesGrid {
  id
  uuid
  entityBundle
  section_title
  title
  description {
    value
  }
  theme {
      entity {
        name
        classes
      }
  }
  items {
    entity {
      ...ParagraphExtendedImage
    }
  }
}
`