export default `
fragment ParagraphExternalApplication on ParagraphExternalApplication {
    uuid
    entityBundle
    url
    title
    description {
      value
    }
    redirect
    formMethod
    type
  }
`
