import React from 'react'
import PropTypes from 'prop-types'
import Links from 'components/molecules/Links'
import { HashLink } from 'react-router-hash-link'
import get from 'lodash.get'
import ReactHtmlParse from 'react-html-parser'

import './Accordion.scss'


const Accordion = ({ type, items, className }) => {
  const renderAccordion = () => {
    switch (type) {
      case 'faq':
        return (
          <section className={`accordion-${type} ${className || ''}`}>
            {Array.isArray(items) &&
              items?.map((c, index) => (
                <details className="accordion-wrapper" key={index}>
                  <summary className="accordion-title-category" title="Clique para abrir">
                    {get(c, 'entity.name') && <h3>{get(c, 'entity.name')}</h3>}
                  </summary>

                  {Array.isArray(c?.entity.faqs?.entities) &&
                    c?.entity.faqs?.entities.map((item, index) => (
                      <div className="accordion-item" key={index}>
                        <HashLink to={get(item, 'url.path')} className="accordion-item-link">
                          <h4 className="accordion-item-text">{get(item, 'title')}</h4>
                        </HashLink>
                      </div>
                    ))
                  }

                </details>
              ))}
          </section>
        )
        case 'faq-2':
          return (
            <section className={`accordion-${type} ${className || ''}`}>
              {Array.isArray(items) &&
                items?.map((c, index) => (
                  <details className="accordion-wrapper" key={index}>
                    <summary className="accordion-title-category" title="Clique para abrir">
                      {get(c, 'name') && <h3>{get(c, 'name')}</h3>}
                      {get(c, 'title') && <h3>{get(c, 'title')}</h3>}
                    </summary>
  
                    {get(c, 'body.value') && ReactHtmlParse(get(c, 'body.value'))}
                    {get(c, 'text') && ReactHtmlParse(get(c, 'text'))}
  
                    {Array.isArray(c.items) &&
                      c.items.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <HashLink to={get(item, 'link.path')} className="accordion-item-link">
                            <h4 className="accordion-item-text">{get(item, 'title')}</h4>
                          </HashLink>
                        </div>
                      ))
                    }
  
                  </details>
                ))}
            </section>
          )
      case 'contact':
        return (
          <section className={`accordion-faq ${className || ''}`}>
            {Array.isArray(items) &&
              items.map((c, index) => (
                <details className="accordion-wrapper" key={index}>
                  <summary className="accordion-title-category" title="Clique para abrir">
                    {get(c, 'title') && <h3>{get(c, 'title')}</h3>}
                  </summary>
                  { get(c, 'body.value') && ReactHtmlParse(get(c, 'body.value'))}
                  { get(c, 'phone') && 
                    <div className="accordion-item">
                      <a href={get(c, 'phone')?.startsWith('0800') ? 'tel: ' + get(c, 'phone') : 'tel: +55' + get(c, 'phone')} className="accordion-item-link">
                        <h4 className="accordion-item-text">{get(c, 'phone')}</h4>
                      </a>
                    </div>
                  }
                </details>
              ))}
          </section>
        )
      default:
        return (
          <section className={`accordion-${type} ${className || ''}`}>
            {Array.isArray(items) &&
              items.map((item) => (
                <details key={get(item, 'label')}>
                  <summary title="Clique para abrir">{get(item, 'label')}</summary>
                  <Links links={get(item, 'links')} idNav={get(item, 'label')} />
                </details>
              ))}
          </section>
        )
    }
  }

  return renderAccordion()
}

Accordion.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.shape({
        label: PropTypes.string,
        links: PropTypes.array,
        body: PropTypes.shape({
          value: PropTypes.string,
        }),
        phone: PropTypes.string,
        items: PropTypes.shape({
          link: PropTypes.object,
          title: PropTypes.string,
        }),
        name: PropTypes.string,
        text: PropTypes.string,
        title: PropTypes.string,
      }),
    })
  ),
  type: PropTypes.oneOf(['faq', 'faq-2', 'footer', 'contact', undefined]),
}

export default Accordion
