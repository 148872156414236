import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NavDesktopLink from 'components/atoms/NavDesktopLink'
import get from 'lodash.get'

function NavDesktopList(props) {
  const { idMenu, idMenuActive, links, onItemClick, cssClass, showAllItems } = props
  const [itemActive, setItemActive] = useState('')

  const handleClick = (item) => {
    setItemActive(get(item, 'label') === itemActive ? '' : get(item, 'label'))
    if (onItemClick) onItemClick(item)
  }

  useEffect(() => {
    if (idMenu !== idMenuActive) {
      setItemActive('')
    }
  })

  return (
    <ul className="navbar-dropdown-container">
      {Array.isArray(links) &&
        links.map((item, index) =>
          !showAllItems ? (
            <li
              key={get(item, 'label') + index}
              className={`${cssClass ? cssClass : 'navbar-dropdown-list-one'} ${
                itemActive === get(item, 'label') ? 'active' : ''
              }`}
            >
              <NavDesktopLink
                id={get(item, 'id')}
                url={get(item, 'url.path')}
                name={get(item, 'label')}
                links={get(item, 'links')}
                onClick={handleClick}
                cssClass={cssClass ? cssClass : 'navbar-dropdown-list-one'}
              />
            </li>
          ) : (
            <NavDesktopLink
              key={get(item, 'label') + index}
              id={get(item, 'id')}
              url={get(item, 'url.path')}
              name={get(item, 'label')}
              links={get(item, 'links')}
              onClick={handleClick}
              cssClass={cssClass ? cssClass : 'navbar-dropdown-list-one'}
              showAllItems
            />
          )
        )}
    </ul>
  )
}

NavDesktopList.propTypes = {
  idMenu: PropTypes.string.isRequired,
  idMenuActive: PropTypes.string.isRequired,
  links: PropTypes.array.isRequired,
  onItemClick: PropTypes.func,
  cssClass: PropTypes.string,
}

export default NavDesktopList
