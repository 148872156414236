import tagTransparentBannerFragment from 'api/queries/fragments/tagTransparentBannerFragment'

export default `
fragment ParagraphTagTransparentBanners on ParagraphTagTransparentBanners {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  items {
    entity {
      ...ParagraphTagTransparentBanner
    }
  }
  color {
    entity {
      name
      fieldColor {
        color
      }
    }
  }
}


${tagTransparentBannerFragment}
`