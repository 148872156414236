export default `
fragment ParagraphBigButtonsWithBanner on ParagraphBigButtonsWithBanner {
  id
  uuid
  entityBundle
  title
  media {
    entity {
      ...Media
    }
  }
  items {
    entity {
      ...ParagraphBigButton
    }
  }
}
`
