import { gql } from 'graphql-request'

export default gql`
  query getMenus($name: String!) {
    menuQuery(name: $name) {
      entity {
        ...AccessMenu
        ...MainMenu
        ...FooterMainMenu
        ...FooterSubMenu
        ...CooperadoMenu
        ...ClientMenu
        ...AuditorMenu
        ...FornecedorMenu
        ...PrestadorMenu
        ...PrestadorAutorizadoMenu
        ...TasyPrestadorMenu
        ...SinapseMenu
        ...AdministratorMenu
        ...ClientePJMenu
        ...SecretariaMenu
        ...ResponsavelFinanceiroPJ
        ...ResponsavelFinanceiroPF
        ...EmpresaCobranca
      }
    }
  }

  fragment AccessMenu on AccessMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment MainMenu on MainMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment FooterMainMenu on FooterMainMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment FooterSubMenu on FooterSubMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment AdministratorMenu on AdministratorMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment CooperadoMenu on CooperadoMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment AuditorMenu on AuditorMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment PrestadorMenu on PrestadorMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment PrestadorAutorizadoMenu on PrestadorAutorizadoMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment TasyPrestadorMenu on TasyPrestadorMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment SinapseMenu on SinapseMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment FornecedorMenu on FornecedorMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }
  fragment ClientMenu on ClientMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment ClientePJMenu on ClientePJMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment SecretariaMenu on SecretariaMenu {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment ResponsavelFinanceiroPJ on ResponsavelFinanceiroPJ {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment ResponsavelFinanceiroPF on ResponsavelFinanceiroPF {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment EmpresaCobranca on EmpresaCobranca {
    id
    name
    title
    description
    links {
      id
      label: title
      description
      link {
        url {
          path
        }
      }
      links {
        id
        label: title
        description
        url {
          path
        }
        links {
          id
          label: title
          description
          url {
            path
          }
          links {
            id
            label: title
            description
            url {
              path
            }
          }
        }
      }
      media {
        entity {
          ...Media
        }
      }
    }
  }

  fragment Media on Media {
    ... on MediaImage {
      entityBundle
      images {
        alt
        title
        size32: size32x32 {
          url
        }
        size48: size48x48 {
          url
        }
        small: thumbnail {
          url
        }
        medium {
          url
        }
        large {
          url
        }
        wide {
          url
        }
      }
    }
    ... on MediaDocument {
      entityBundle
      fieldMediaDocument {
        entity {
          filename
          url
        }
      }
    }

    ... on MediaRemoteAudio {
      entityBundle
      fieldMediaOembedAudio
    }

    ... on MediaAudio {
      entityBundle
      fieldMediaAudioFile {
        entity {
          filename
          url
        }
      }
    }

    ... on MediaVideo {
      entityBundle
      title
      fieldMediaVideoFile {
        entity {
          filename
          url
        }
      }
    }

    ... on MediaRemoteVideo {
      entityBundle
      fieldMediaOembedVideo
    }

    ... on MediaSvg {
      entityBundle
      fieldMediaFile {
        entity {
          filename
          url
        }
      }
    }
  }
`
