export default `
fragment ParagraphHighlightedNews on ParagraphHighlightedNews {
  id
  uuid
  entityBundle
  title
  promote
  colorPalette {
    entity {
      colors {
        color
      }
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  link {
    title
    url {
      path
    }
  }
  category {
    entity {
      name
      tid
    }
  }
  news {
    items {
      title
      promote
      body {
        value
        summary
      }
      url {
        path
      }
      media {
        entity {
          ...Media
        }
      }
      created
      updated
    }
  }  
}
`
