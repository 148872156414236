import React from 'react'

function Typography(props) {
  const variantsMapping = {
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6',
    p: 'p',
    span: 'span',
  }

  const { variant, component, color, children, cssClass, ...otherprops } = props

  const Component = variant ? variantsMapping[variant] : 'p'
  const componentDefault = component || ''
  const classname = cssClass || ''

  return (
    <Component className={`${componentDefault} ${classname} ${color ? `text-${color}` : ''}`} {...otherprops}>
      {children}
    </Component>
  )
}

export default Typography
