import halfBannerFragment from 'api/queries/fragments/halfBannerFragment'

export default `
fragment ParagraphHalfBanners on ParagraphHalfBanners {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  colorPalette {
    entity {
    name
      colors {
        color
      }      
    }
  }
  theme {
    entity {
      name
      classes
    }
  }  
  items {
    entity {
      ...ParagraphHalfBanner
    }
  }
  additionalInformation {
    value
  }  
}

${halfBannerFragment}
`