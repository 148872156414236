export default `
fragment ParagraphInformationTagWrapper on ParagraphInformationTagWrapper {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  theme {
    entity {
      name
      classes
    }
  }  
  colorPalette {
    entity {
      name
      colors {
        color
      }
    }
  }
  items {
    entity {
      ...ParagraphInformationTag
    }
  }
}
`