import { GET_USER_ERROR, RESULT_USER, LOADING_USER, RESULT_IP } from 'store/constants'

export const initialState = {
  user: {},
  ip: null,
  userError: false,
  loadingUser: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RESULT_USER:
      return { ...state, user: action.payload }
    case GET_USER_ERROR:
      return { ...state, userError: action.payload }
    case LOADING_USER:
      return { ...state, loadingUser: action.payload }
    case RESULT_IP:
      return { ...state, ip: action.payload }
    default:
      return state
  }
}
