import apiClient from 'api/config/apiClient'

export default {
  getRecipientRegister(payload) {
    return apiClient.request('recipient_register', payload)
  },
  updateRecipientRegister(payload) {
    return apiClient.request('update_recipient_register', payload)
  },
  searchZipCodeRegister(payload) {
    return apiClient.request('zipcode_list', payload)
  },
}
