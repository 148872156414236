import bigButtonFragment from 'api/queries/fragments/bigButtonFragment'

export default `
fragment ParagraphCarouselBigButton on ParagraphCarouselBigButton {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  items{
    entity{
      ...ParagraphBigButton
    }
  }
}

${bigButtonFragment}
`