import phoneTagFragment from 'api/queries/fragments/phoneTagFragment'

export default `
fragment ParagraphPhoneTags on ParagraphPhoneTags {
  id
  uuid
  entityBundle
  title
  items {
    entity {
      ...ParagraphPhoneTag
    }
  }
}


${phoneTagFragment}
`