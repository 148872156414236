import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { get } from 'lodash'
import { Container, Row, Col } from 'react-bootstrap'
import AccessibilityBar from 'components/organisms/AccessibilityBar'
import NavBar from 'components/organisms/NavBar'
import Footer from 'components/organisms/Footer'
import Breadcrumbs from 'components/molecules/Breadcrumbs'
import SVG from 'react-inlinesvg'
import Logotipo from 'assets/img/logotipo-unimed-curitiba.png'
import {
  AccessDenied,
  ChangePassword,
  FeedbackSuccess,
  ForgotAccess,
  LoginForm,
  LoginRegister,
  LoginEmailValidated,
  SomethingWrong,
  AccountCreated,
  ChangeProfile,
  AccessSimulation,
  ActivateProfile,
} from './components'

// Styles
import './LoginPages.scss'

const LoginPages = (props) => {
  const { user, refs } = props
  const history = useHistory()
  const initialStep = get(props, 'step')
  const [step, setStep] = useState(initialStep || 'LoginForm')
  const [stepProps, setStepProps] = useState(props || '')
  const [userType, setUserType] = useState('')
  const stepsComponent = {
    AccessDenied,
    ChangePassword,
    FeedbackSuccess,
    ForgotAccess,
    LoginForm,
    LoginRegister,
    LoginEmailValidated,
    SomethingWrong,
    AccountCreated,
    ChangeProfile,
    AccessSimulation,
    ActivateProfile,
  }
  const StepComponent = stepsComponent[step]

  useEffect(() => {
    if (!user && step === 'ChangeProfile') {
      history.push('/login')
      history.go(0)
    }
  }, [])

  function changeStep(step, stepProps) {
    refs.content.current.scrollIntoView()
    setStep(step)
    setStepProps(stepProps)
  }

  const componentWithBoxError = ['SomethingWrong', 'AccessDenied']

  const [showHeader, setShowHeader] = useState(true)
  function changeHeaderVisibility() {
    setShowHeader(!showHeader)
  }

  const [showBreadcrumb, setShowBreadcrumb] = useState(true)
  function changeBreadcrumbVisibility() {
    setShowBreadcrumb(!showBreadcrumb)
  }

  const [showFooter, setShowFooter] = useState(true)
  function changeFooterVisibility() {
    setShowFooter(!showFooter)
  }

  const [userLogged, setUserLogged] = useState(false)
  function changeUserLogged() {
    setUserLogged(!userLogged)
  }

  function handleChangeUserType(type) {
    setUserType(type)
  }

  return (
    <>
      <header ref={refs.header}>
        <AccessibilityBar refs={refs} />
        {showHeader && <NavBar className="bg-green-dark-500" logged={userLogged} user={user} />}
      </header>
      <main ref={refs.content}>
        <Container fluid="fluid" className="login-page">
          {showBreadcrumb && (
            <Container className="login-page-breadcrumb">
              <Row>
                <Col>
                  <Breadcrumbs
                    breadColor="gray-100"
                    items={{
                      items: [
                        {
                          page: 'Home',
                          url: '/',
                        },
                        {
                          page: 'Acesse sua área',
                          url: '/login',
                        },
                      ],
                    }}
                  />
                </Col>
              </Row>
            </Container>
          )}

          {!showHeader && (
            <div className="logo">
              <SVG
                className="icon"
                src={Logotipo}
                preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                width="100%"
                height="100%"
              />
            </div>
          )}
          <Container id="main-content" className="login-page content">
            <div className={classNames('box my-5 pb-4', { 'box--error': componentWithBoxError.includes(step) })}>
              <StepComponent
                changeStep={changeStep}
                changeHeaderVisibility={changeHeaderVisibility}
                changeBreadcrumbVisibility={changeBreadcrumbVisibility}
                changeFooterVisibility={changeFooterVisibility}
                changeUserLogged={changeUserLogged}
                data={stepProps}
                handleChangeUserType={handleChangeUserType}
                userType={userType}
              />
            </div>
          </Container>
        </Container>
      </main>
      {showFooter && (
        <footer id="footer">
          <Footer id="main-footer" logged={userLogged} refs={refs} />
        </footer>
      )}
    </>
  )
}

export default LoginPages
