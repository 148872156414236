import { gql } from 'graphql-request'

export default gql`
	query getQueryCBO($provider_code: String, $council_code: String, $regional_council: String, $state: String) {
		applications {
		  cbo {
			cboList (
			  	provider_code: $provider_code
				council_code: $council_code
			  	regional_council: $regional_council
				state: $state) {
				cbo {
					provider_name
					provider_code
					cbo_code
					council_code
					council_initials
					cbo_description
					state
				}
			}
		  }
		}
	}
`