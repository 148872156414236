import React, { useEffect, useContext } from 'react'
import { ReactReduxContext, useDispatch } from 'react-redux'
import sadSmile from 'assets/img/sad-smile-chat-box.svg'
import SVG from 'react-inlinesvg'
import { Button, Row, Col } from 'react-bootstrap'
import Typography from 'components/atoms/Typography'
import ReactHtmlParser from 'react-html-parser'
import { getApplicationMenus } from 'store/modules/customPage/actions'
import { invalidateUser } from 'helpers/auth'

// Styles
import './SessionExpired.scss'

const SessionExpired = ({ data, history }) => {
  const dispatch = useDispatch()
  const { store } = useContext(ReactReduxContext)
  useEffect(async () => {
    await invalidateUser(store)
    dispatch(getApplicationMenus(null))
  }, [])
  const { title, message } = data
  return (
    <Row>
      <Col>
        <div className="session-expired">
          <div className="session-expired__header mt-4">
            <Typography variant="p" cssClass="title" color="danger">
              {title}
            </Typography>
          </div>
          <div className="session-expired__body">
            <SVG
              className="icon"
              src={sadSmile}
              preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
              width="100%"
              height="100%"
            />
            <div className="text">
              <Typography variant="span" color="gray-100">
                {ReactHtmlParser(message)}
              </Typography>
            </div>
            <div className="session-expired__actions">
              <Button
                type="button"
                variant="outline-primary"
                size="lg"
                className="login mx-2"
                onClick={() => {
                  history.push('/')
                }}
              >
                <span>Voltar para home</span>
              </Button>

              <Button
                type="button"
                variant="primary"
                size="lg"
                className="login mx-2"
                onClick={() => {
                  history.push('/login')
                }}
              >
                <span>Fazer login</span>
                <span>
                  <i className="icone-arrow-right" />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

export default SessionExpired
