import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware, { END } from 'redux-saga'
import reduxCookiesMiddleware, { getStateFromCookies } from 'redux-cookies-middleware'
import rootReducer from './combineReducers'
import rootSaga from './combineSagas'

let initialState = {
  auth: {},
  token: {},
}

// state to persist in cookies
const paths = {
  'auth.user': { name: 'auth' },
  'token.token': { name: 'token' },
}

const configureStore = (preloadedState = {}, req, res) => {
  const sagaMiddleware = createSagaMiddleware()
  const defaultDeleteCheck = (val) => {
    return val === null || val === 'null'
  }
  let cookiesMiddleware

  //if server side
  if (req) {
    // reads a cookie from the express request object.
    const getCookieOnServer = (request, name) => {
      return request.cookies[name]
    }

    cookiesMiddleware = reduxCookiesMiddleware(paths, {
      defaultDeleteCheck,
    })

    initialState = getStateFromCookies(initialState, paths, (name) => getCookieOnServer(req, name) || '')
    preloadedState = {
      ...preloadedState,
      ...initialState,
    }
  } else {
    // else if client side
    cookiesMiddleware = reduxCookiesMiddleware(paths, {
      defaultDeleteCheck,
    })
  }

  const store = createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware, cookiesMiddleware))
  )
  store.saga = sagaMiddleware.run(rootSaga)

  store.stopSaga = async () => {
    if (!store.saga) return
    store.dispatch(END)
    await store.saga.toPromise()
    store.saga = undefined
  }

  if (module.hot) {
    module.hot.accept('./combineReducers.js', () => {
      const nextRootReducer = require('./combineReducers.js')
      store.replaceReducer(nextRootReducer)
    })
  }

  return store
}

export default configureStore
