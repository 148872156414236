export default `
  fragment ParagraphPublicApp on ParagraphPublicApp {
    id
    uuid
    entityBundle
    title
    description{
        value
    }
    application {
      entity {
        tid
        name
        component
      }
    }
  }
`
