import { capitalize } from 'lodash'

import {
  PAYMENT_DEMONSTRATIVE_COPAN_DATA,
  GET_PAYMENT_DEMONSTRATIVE_COPAN_ERROR,
  PAYMENT_DEMONSTRATIVE_COPAN_LOADING,
  GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS,
  GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS_ERROR,
  PAYMENT_DEMONSTRATIVE_COPAN_LOGS_DATA,
  PAYMENT_DEMONSTRATIVE_COPAN_LOGS_LOADING
} from 'store/constants'

export const initialState = {
  status: {},
  docs: [],
  reportLogs: {},
  errorReportLogs: false,
  loadingReportLogs: false,
  loadingPaymentDemonstrativeCopan: false,
  errorPaymentDemonstrativeCopan: false,
  reportsBeingGeneratedCopan: [],
  datesWithNoDataCopan: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS:
      return { ...state, reportLogs: action.payload }
    case PAYMENT_DEMONSTRATIVE_COPAN_LOGS_DATA:      
      return { ...state, reportLogs: action.payload }
    case PAYMENT_DEMONSTRATIVE_COPAN_DATA:
      const pwbusPayloadId = action.payload.applications.paymentDemonstrative.reportsPerDay.pwbus_correlation_id
      const existingItemIndex = state.docs.findIndex(
        (item) => item.applications.paymentDemonstrative.reportsPerDay.pwbus_correlation_id === pwbusPayloadId
      )

      if (existingItemIndex >= 0) {
        let docs = state.docs
        docs[existingItemIndex] = action.payload

        return { ...state, docs }
      } else {
        return { ...state, docs: [...state.docs, action.payload] }
      }

    case GET_PAYMENT_DEMONSTRATIVE_COPAN_ERROR:
      return { ...state, errorPaymentDemonstrativeCopan: action.payload }

    case GET_PAYMENT_DEMONSTRATIVE_COPAN_LOGS_ERROR:
      return { ...state, errorReportLogs: action.payload }
    
    case PAYMENT_DEMONSTRATIVE_COPAN_LOADING:
      return { ...state, loadingPaymentDemonstrativeCopan: action.payload }

    case PAYMENT_DEMONSTRATIVE_COPAN_LOGS_LOADING:
      return { ...state, loadingReportLogs: action.payload }

    default:
      return state
  }
}
