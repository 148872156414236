import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getCodeCBO({ payload }) {
    try {
        yield put({ type: constants.CODE_CBO_LOADING, payload: true })

        const res = yield call(api.getCodeCBO, payload)

        yield put({
            type: constants.CODE_CBO_DATA,
            payload: res,
        })

        yield put({ type: constants.CODE_CBO_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.CODE_CBO_ERROR, payload: e.message })
        yield put({ type: constants.CODE_CBO_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_CODE_CBO, getCodeCBO)
}