import { gql } from 'graphql-request'
import mediaFragment from './fragments/mediaFragment'

export default gql`
  query getFooter {
    footerQuery {
      entityBundle
      linksWithIcons {
        entity {
          ...LinksWithIcons
        }
      }
      linksSocialMedia {
        entity {
          ...LinksWithIcons
        }
      }
      imageLink {
        entity {
          ...ImageLink
        }
      }
    }
  }

  fragment LinksWithIcons on ParagraphLinkWithIcon {
    uuid
    link {
      title
      url {
        path
      }
    }
    icon {
      entity {
        media {
          entity {
            ...Media
          }
        }
      }
    }
    linkType {
      entity {
        name
        token
      }
    }
  }

  fragment ImageLink on ParagraphImageLink {
    uuid
    entityBundle
    id
    link {
      title
      url {
        path
      }
    }
    linkType {
      entity {
        name
        token
      }
    }
    media {
      entity {
        ...Media
      }
    }
  }

  ${mediaFragment}
`
