import apiClient from 'api/config/apiClient'

export default {
  getReadjustmentStatement(payload) {
    return apiClient.request('readjustment_statement', payload)
  },

  orderReadjustmentStatement(query) {
    const { order, values } = query
    if (values.applications && order.direction == 'asc') {
      let asc = sortBy(
        values.applications?.readjustmentExtract.readjustmentExtractList.extracts,
        order.column
      ).reverse()
      values.applications.readjustmentExtract.readjustmentExtractList.extracts = asc
      return values
    } else if (values.applications && order.direction == 'desc') {
      let desc = sortBy(values.applications?.readjustmentExtract.readjustmentExtractList.extracts, order.column)
      values.applications.readjustmentExtract.readjustmentExtractList.extracts = desc
      return values
    }
  },
}
