import { gql } from 'graphql-request'
import mediaFragment from './fragments/mediaFragment'

export const getPlansBySegment = gql`
  query getPlansBySegment($segment: Int!) {
    plansBySegmentQuery(segment: $segment) {
      entities {
        title
        body {
          value
          summary
        }
        segment {
          entity {
            tid
            name
          }
        }
        category {
          entity {
            tid
            name
          }
        }
        link {
          path
        }
        simple_descriptions
        media {
          entity {
            ...Media
          }
        }
        tag {
          entity {
            ...Media
          }
        }
      }
    }
  }

  ${mediaFragment}
`

export const getPlansByCategory = gql`
  query getPlansByCategory($segment: Int!, $categories: [String]!) {
    plansByCategoryQuery(segment: $segment, categories: $categories) {
      entities {
        title
        body {
          value
          summary
        }
        segment {
          entity {
            tid
            name
          }
        }
        category {
          entity {
            tid
            name
          }
        }
        link {
          path
        }
        simple_descriptions
        media {
          entity {
            ...Media
          }
        }
        tag {
          entity {
            ...Media
          }
        }
      }
    }
  }

  ${mediaFragment}
`

export const getPlansFilters = gql`
  query getPlansFilters($limit: Int!) {
    plansFiltersQuery(limit: $limit) {
      entities {
        id
        name
      }
    }
  }
`
