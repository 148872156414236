export default `
  fragment ParagraphAtosApplication on ParagraphAtosApplication {
    uuid
    entityBundle
    url
    title
    description {
      value
    }
  }
`