export default `
fragment ParagraphAudioGrid on ParagraphAudioGrid {
  id
  uuid
  entityBundle
  items {
  entity {
      ...ParagraphAudio
    }
  }
}
`