import { gql } from 'graphql-request'

export default gql`
  mutation deleteQuickAccess($index: Int!, $role: String!) {
    deleteQuickAccess(index: $index, role: $role) {
      index
      user {
        id
      }
      role {
        role
      }
      link {
        title
        url {
          path
        }
      }
    }
  }
`
