import { gql } from 'graphql-request'

export default gql`
	query getOutpatientAuthorizationsProviderResult($providerCode: String!) {
    applications {
      outpatientAuthorizations {
        outpatientAuthorizationsProviderResult(providerCode: $providerCode) {
          provider {
              code
              name
            }
          }
        }
      }
    }
`