import { gql } from 'graphql-request'

export default gql`
  query getCardRecipient($handle: Int!) {
    applications {
      cardUnlock {
        cardRecipient(handle: $handle) {
          name
          handleRecipient
          handleCard
          handleRegistration
          code
          type
          dependentType
          blocked
          nameMother
          cpf
          email
          address {
            zipcode
            handleResidential
            handleMail
            street
            neighborhood
            city
            state
            number
            complement
            handleState
            handleCity
            phoneDDD
            phonePrefix
            phoneNumber
            cellPhoneDDD
            cellPhonePrefix
            cellPhoneNumber
          }
        }
      }
    }
  }
`
