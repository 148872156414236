export default `
  fragment ParagraphScheduleWidgetProtocols on ParagraphScheduleWidgetProtocols {
    uuid
    entityBundle
    title
    fieldSchedule {
      entity {
        ...NodeSchedule
      }
    }
  }
`
