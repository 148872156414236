import React, { useEffect } from 'react'
import Typography from 'components/atoms/Typography'
import { get } from 'lodash'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import IconOpenEmail from 'assets/img/icon-open-email.svg'
import IconLock from 'assets/img/icon-lock.svg'
import ReactHtmlParser from 'react-html-parser'

// Styles
import './AccountCreated.scss'

function AccountCreated({ data, changeStep, changeBreadcrumbVisibility, changeHeaderVisibility }) {
  const history = useHistory()

  useEffect(() => {
    changeHeaderVisibility(false)
    changeBreadcrumbVisibility(false)
  }, [])

  return (
    <section className="feedback-success">
      <div className="content">
        <div className="login-header">
          <Typography variant="p" cssClass="title">
            Seu cadastro foi concluído com sucesso!
          </Typography>
        </div>
        <div className="feedback-success-body">
          <SVG
            className="icon"
            src={IconLock}
            preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
            width="100%"
            height="100%"
          />
          {get(data, 'inactiveMessage') && <span className="text mb-4">{ReactHtmlParser(data.inactiveMessage)}</span>}
          {!get(data, 'inactiveMessage') && (
            <p className="text mb-4">
              Faça o login no Portal Unimed Curitiba e utilize nossos serviços online disponíveis para você.
            </p>
          )}
          <div className="cta">
            <Button
              type="button"
              variant="outline-primary"
              size="lg"
              className="done mx-2"
              onClick={() => {
                history.push('/home')
              }}
            >
              <span>Concluir</span>
            </Button>
            <Button
              type="button"
              variant="primary"
              size="lg"
              className="login mx-2"
              onClick={() => {
                history.push('/login')
              }}
            >
              <span>Login</span>
              <span>
                <i className="icone-arrow-right" />
              </span>
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AccountCreated
