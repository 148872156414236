import linkWithIconFragment from 'api/queries/fragments/linkWithIconFragment'

export default `
fragment ParagraphCarouselLinks on ParagraphCarouselLinks {
  id
  uuid
  entityBundle
  carousel
  links {
    entity {
      ...ParagraphLinkWithIcon
    }
  }
}

${linkWithIconFragment}
`