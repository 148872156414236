import { gql } from 'graphql-request'

export default gql`
  query customApplications($date: String!, $pwbusId: String!, $userCode: String!, $reportCode: String, $debugMode: String) {
    applications {
      paymentDemonstrative {
        reportsPerDay(
          date: $date
          userCode: $userCode
          accessType: "copan"
          pwbusCorrelationId: $pwbusId
          reportCode: $reportCode
          debugMode: $debugMode
        ) {
          total
          total_success
          total_processing
          total_waiting
          total_errors
          logs {
            success {
              request_date
              request_task
              pwbus_correlation_id
              response {
                error
                data_loaded
                data_loaded_time
                pdf_report
                pdf_report_time
                xls_report
                xls_report_time
                xml_report
                xml_report_exchange
                xml_report_time
                total_time
              }
              created_at
              updated_at
            }
            waiting {
              request_date
              request_task
              pwbus_correlation_id
              response {
                error
                data_loaded
                data_loaded_time
                pdf_report
                pdf_report_time
                xls_report
                xls_report_time
                xml_report
                xml_report_exchange
                xml_report_time
                total_time
              }
              created_at
              updated_at
            }
            processing {
              request_date
              request_task
              pwbus_correlation_id
              response {
                error
                data_loaded
                data_loaded_time
                pdf_report
                pdf_report_time
                xls_report
                xls_report_time
                xml_report
                xml_report_exchange
                xml_report_time
                total_time
              }
              created_at
              updated_at
            }
            errors {
              request_date
              request_task
              pwbus_correlation_id
              response {
                error
                data_loaded
                data_loaded_time
                pdf_report
                pdf_report_time
                xls_report
                xls_report_time
                xml_report
                xml_report_exchange
                xml_report_time
                total_time
              }
              created_at
              updated_at
            }
          }
        }
      }
    }
  }
`