import React from 'react'
import './SocialMedia.scss'
import Typography from 'components/atoms/Typography'
import { get } from 'lodash'
import SVG from 'react-inlinesvg'
import IconSmile from 'assets/img/icon-smile.svg'

function SocialMedia(props) {
  const links = get(props, 'links')

  return (
    <section className="SocialMedia">
      <Typography variant="p" color="white" cssClass="SocialMedia__title">
        Siga-nos
      </Typography>
      <div className="SocialMedia__icons-wrapper icons-wrapper">
        {links && links.map(link => {
          const title = get(link, 'title')
          const url = get(link, 'url')
          const icon = get(link, 'icon')
          const uuid = get(link, 'uuid')
          return (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="SocialMedia__no-classname mr-2"
              href={url}
              title={title}
              key={uuid}
            >
              <SVG className="icon" src={icon || IconSmile} preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
                width="100%"
                height="100%" />
              <span className="sr-only">{title}</span>
            </a>
          )
        })}
      </div>
    </section>
  )
}

export default SocialMedia
