export default `
fragment ParagraphGlossaryWidget on ParagraphGlossaryWidget {
  id
  uuid
  entityBundle
  title
  description {
    value
  }
  grouped
  category {
    entity {
      tid
      name
      glossaries {
        entities {
          ...on NodeGlossary {
            uuid
            view
            title
            body {
              value
            }
            created
          }
        }
      }
    }
  }
} 
`
