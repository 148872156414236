import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getReferencedList({ payload }) {
    try {
        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: true })
        const res = yield call(api.getReferencedList, payload)

        yield put({
            type: constants.REFERENCED_LIST_DATA,
            payload: res,
        })

        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.REFERENCED_LIST_ERROR, payload: e.message })
        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: false })
    }
}

function* approveReferencedList({ payload }) {
    try {
        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: true })
        const res = yield call(api.approveReferencedList, payload)

        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.REFERENCED_LIST_ERROR, payload: e.message })
        yield put({ type: constants.REFERENCED_LIST_LOADING, payload: false })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_REFERENCED_LIST, getReferencedList)
    yield takeLatest(constants.APPROVE_REFERENCED_LIST, approveReferencedList)
}