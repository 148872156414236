import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getCities({ payload }) {
  try {
    yield put({ type: constants.CITIES_LOADING, payload: true })

    const res = yield call(api.getCities, payload)

    yield put({
      type: constants.CITIES_DATA,
      payload: res,
    })

    yield put({ type: constants.CITIES_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.CITIES_ERROR, payload: e.message })
    yield put({ type: constants.CITIES_LOADING, payload: false })
  }
}

function* getSpecialties({ payload }) {
  try {
    yield put({ type: constants.SPECIALTIES_LOADING, payload: true })

    const res = yield call(api.getSpecialties, payload)

    yield put({
      type: constants.SPECIALTIES_DATA,
      payload: res,
    })

    yield put({ type: constants.SPECIALTIES_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.SPECIALTIES_ERROR, payload: e.message })
    yield put({ type: constants.SPECIALTIES_LOADING, payload: false })
  }
}

function* getMedicalSchedule({ payload }) {
  try {
    yield put({ type: constants.MEDICAL_SCHEDULE_LOADING, payload: true })

    const res = yield call(api.getMedicalSchedule, payload)

    yield put({
      type: constants.MEDICAL_SCHEDULE_DATA,
      payload: res,
    })

    yield put({ type: constants.MEDICAL_SCHEDULE_LOADING, payload: false })
  } catch (e) {
    console.error(`${e.message}`)

    yield put({ type: constants.MEDICAL_SCHEDULE_ERROR, payload: e.message })
    yield put({ type: constants.MEDICAL_SCHEDULE_LOADING, payload: false })
  }
}

export default function* saga() {
  yield takeLatest(constants.GET_CITIES, getCities)
  yield takeLatest(constants.GET_SPECIALTIES, getSpecialties)
  yield takeLatest(constants.GET_MEDICAL_SCHEDULE, getMedicalSchedule)
}
