import get from 'lodash.get'
import { getElementAsync } from 'helpers/tools'

export default async function (data_ga) {

  if (get(data_ga, 'data_ga_category') === null)
    return

  // body
  document.querySelector('body').setAttribute('data-ga-category', get(data_ga ,'data_ga_category.data_ga_name'))

  const data_ga_components = get(data_ga, 'data_ga_category.components')

  _.toArray(data_ga_components).map(async(item, i) => {

    // if(item.component === 'content') {
    //   item.component = 'main-content' // rename
    // }
    const masterElement = await getElementAsync('#' + item.component)

    const subComponents = item.components

    Object.keys(item).map(async(key, j) => {
      if (key !== 'component' && key !== 'components') {

        masterElement.setAttribute(key.replace(/_/g,'-'), item[key])

        // subComponents
        subComponents.map(async (subComponent) => {

          const subElement = await getElementAsync('#' + subComponent.component)
          const subSubComponents = subComponent.components

          if (subElement) {

            Object.keys(subComponent).map(async (attribute, k) => {
              if (attribute !== 'component' && attribute !== 'components') {

                if(subComponent[attribute] !== '') // attribute must have a value
                  subElement.setAttribute(attribute.replace(/_/g,'-'), subComponent[attribute])

                subSubComponents.map(async (subSubComponent) => {

                  const subSubElement = await getElementAsync('#' + subSubComponent.component)
                  const infraComponents = subSubComponent.components

                  if (subSubElement) {

                    Object.keys(subSubComponent).map(async (sub_attribute, k) => {
                      if (sub_attribute !== 'component' && sub_attribute !== 'components') {
                        if(subSubComponent[attribute] !== '') // attribute must have a value
                          subSubElement.setAttribute(sub_attribute.replace(/_/g,'-'), subSubComponent[sub_attribute])

                        if(Array.isArray(infraComponents)) {
                          infraComponents.map(async (infraComponent) => {

                            const infraElement = await getElementAsync('#' + infraComponent.component)

                            if (infraElement) {

                              Object.keys(infraComponent).map(async (infra_attribute, k) => {
                                if (infra_attribute !== 'component' && infra_attribute !== 'components') {
                                  if (infraElement[infra_attribute] !== '') // attribute must have a value
                                    infraElement.setAttribute(infra_attribute.replace(/_/g,'-'), infraComponent[infra_attribute])
                                }
                              })
                            }
                          })
                        }
                      }
                    })
                  }
                })
              }
            })
          }
        })
      }
    })
  })
}
