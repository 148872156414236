import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import SeloMob from 'assets/img/selo-mob.png'
import SeloDesk from 'assets/img/selo-desk.png'
import Typography from 'components/atoms/Typography'
import Links from 'components/molecules/Links'
import PropTypes from 'prop-types'

import './Copyright.scss'
function Copyright(props) {
  return (
    <Container fluid="fluid" className="bg-green-dark-300 py-3">
      <Container id="footersubmenu">
        <Row>
          <Col xs={9} sm={9} md={10}>
            <Links links={props.links} liClass="Links__item" ulClass="Links__list" linkClass="Links__link" />
            <Row>
              <Col sm={9}>
                <Typography variant="p" component="p" color="white" cssClass="mt-2 copyright">
                  Copyright © {new Date().getFullYear()} Unimed Curitiba. Todos os direitos reservados.
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col xs={3} sm={3} md={2} className="d-flex justify-content-end align-items-start">
            <img src={SeloMob} alt="anvisa" className="d-block d-md-none" />
            <img src={SeloDesk} alt="anvisa" className="Copyright__anvisa d-none d-md-block" />
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

Copyright.propTypes = {
  links: PropTypes.array,
}

export default Copyright
