import componentsMapping from 'helpers/componentsMapping'

export default (componentName) => {
  let RenderComponent = 'div'

  RenderComponent =
    componentName && componentsMapping[componentName] ? componentsMapping[componentName] : RenderComponent

  return RenderComponent
}
