export default `
fragment ParagraphTagBanner on ParagraphTagBanner {
  id
  uuid
  entityBundle
  title
  image {
    entity{
      ...Media
    }
  }
  link {
    title
    url{
      path
    }
  }
  theme {
    entity {
      classes
    }
  }
}
`