import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Container, Form, Button } from 'react-bootstrap'
import MaskedInput from 'react-maskedinput'
import SVG from 'react-inlinesvg'
import { cpf as cpfValidator } from 'cpf-cnpj-validator'
import { get } from 'lodash'
import { numberMask, cnpjMask } from 'helpers/strings'
import API_AUTH from 'store/modules/auth/api'
import questionMark from 'assets/img/question-mark.svg'
import FaceRecognition from 'assets/img/face-recognition.svg'

import ModalUnimedCard from 'components/templates/LoginPages/components/ModalUnimedCard'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Typography from 'components/atoms/Typography'
import Input from 'components/atoms/Input'

function SomethingWrong({ changeStep, data }) {
  const history = useHistory()
  const [formValidated, setFormValidated] = useState(false)
  const [unimedCardModal, setUnimedCardModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [formSent, formFormSent] = useState(false)
  const [topMessage, setTopMessage] = useState('Ops, algo deu errado com seu cadastro.')
  const [submittedError, setSubmittedError] = useState(false)

  const [formData, setFormData] = useState({
    name: data?.data?.name || '',
    birthday: data?.data?.birthday || data?.data?.birth_date || '',
    cpf: data?.data?.cpf || '',
    code: data?.data?.code || data?.data?.card_number || data?.data?.login || '',
    type: data?.data?.type || '',
    cnpj: data?.data?.cnpj || '',
    crm: data?.data?.crm || '',
  })

  async function handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()

    const form = event.currentTarget
    if (form.checkValidity()) {
      formData.code = numberMask(formData.code)
      formData.cpf = formData.cpf ? numberMask(formData.cpf) : numberMask(formData.cnpj)
      formData.birthday = formData.birthday ? formData.birthday.split('/').reverse().join('-') : ''
      formData.cnpj = formData.cnpj ? numberMask(formData.cnpj) : ''

      formData.contract = data?.data?.contract
      formData.id = data?.data?.id

      formData.old_name = data?.data?.name || ''
      formData.old_code = data?.data?.code || data?.data?.card_number || data?.data?.login || ''
      formData.old_cpf = data?.data?.cpf || ''
      formData.old_cnpj = data?.data?.cnpj || ''
      formData.old_birthday = data?.data?.birthday || data?.data?.birth_date || ''
      formData.old_crm = data?.data?.crm || ''
      formData.old_type = data?.data?.type || ''

      setIsLoading(true)
      try {
        const response = await API_AUTH.sendFormSomethingWrong(formData)
        const message = get(response, 'somethingWrong.message')
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0)
        }
        formFormSent(true)
        setTopMessage(message)
        setSubmittedError(false)
      } catch (error) {
        setIsLoading(false)
        formFormSent(true)
        setSubmittedError(true)
      }
    }

    setFormValidated(true)
  }

  function handleClickQuestionMark() {
    setUnimedCardModal(true)
  }

  function handleChangeField({ target }, valid) {
    if (valid) {
      const field = target.name
      setFormData((current) => ({ ...current, [field]: target.value }))
    }
  }

  function handleMessage() {
    if (!submittedError) {
      return (
        <>
          <Typography cssClass="mb-4 text-center" color="gray-100">
            Obrigado! Logo entraremos em contato via email.
          </Typography>
          <CustomButton
            className="submit-button justify-content-center"
            text="Ir para home"
            noIcon
            onClick={() => {
              history.push('/')
            }}
          />
        </>
      )
    } else {
      return (
        <>
          <Typography cssClass="mb-4 text-center" color="gray-100">
            Não foi possivel enviar email. Tente novamente ou entre em contato com nossos atendimentos:
          </Typography>
          <p>
            <ul>
              <li>Cliente PF: 0800-642 2002</li>
              <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
              <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
              <li>Prestador: Entre em contato pelo 3021-9420</li>
              <li>Fornecedor: 3021-9420</li>
            </ul>
          </p>
          <div className="d-flex justify-content-around">
            <Button
              type="button"
              variant="outline-primary"
              size="lg"
              onClick={() => {
                history.push('/')
              }}
            >
              <span>Voltar para home</span>
            </Button>

            <CustomButton
              text="Tentar novamente"
              noIcon
              onClick={() => {
                formFormSent(false)
              }}
            />
          </div>
        </>
      )
    }
  }

  return (
    <>
      <div className="login-header">
        <Typography variant="p" cssClass="title" color={formSent && !submittedError ? 'primary' : 'danger'}>
          {topMessage}
        </Typography>
      </div>
      <div className="p-3 px-lg-5 py-lg-4">
        <div className="d-flex justify-content-center mb-4">
          <SVG src={FaceRecognition} />
        </div>

        {!formSent ? (
          <>
            <Typography cssClass="mb-4 text-center" color="gray-100">
              Ocorreu um erro com o seu cadastro, por favor entre em contato conosco por um dos nossos canais de atendimento:
            </Typography>
            <br />
            <p>
              <ul class="text-left">
                <li>Cliente PF: 0800-642 2002</li>
                <li>Cliente PJ: Entre em contato pelo 0800-642 4004</li>
                <li>Médico Cooperado e Secretária: Entre em contato pelo 3021-7000</li>
                <li>Prestador: Entre em contato pelo 3021-9420</li>
                <li>Fornecedor: 3021-9420</li>
              </ul>
            </p>
          </>
        ) : (
          handleMessage()
        )}
        <ModalUnimedCard open={unimedCardModal} onClose={() => setUnimedCardModal(false)} />
      </div>
    </>
  )
}

SomethingWrong.propTypes = {}

export default SomethingWrong
