export default `
fragment ParagraphVideoGrid on ParagraphVideoGrid {
  id
  uuid
  entityBundle
  section_title
  title
  description {
    value
  }
  theme {
      entity {
        name
        classes
      }
  }  
  items {
    entity {
      ...ParagraphVideo
    }
  }
}

`