export default `
  fragment ParagraphServiceWidget on ParagraphServiceWidget {
    id
    uuid
    entityBundle
    title
    description {
      value
    }
    theme {
      entity {
        name
        classes
      }
    }
    services {
      entity {
        ...on Service {
          uuid
          title
          body {
            value
          }
        }
      }
    }
  }
`