export default `
  fragment ParagraphSetBigButton on ParagraphSetBigButton {
    id
    title
    entityBundle
    description {
      value
    }
    items {
      entity {
        ...ParagraphBigButton
      }
    }
    theme {
      entity {
        name
        classes
      }
    } 
  }
`