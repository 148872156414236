import { gql } from 'graphql-request'

export default gql`
  query getPinSSUtilization($date: String!, $username: String!) {
    applications {
      pinSSData {
        recipientUsage(username: $username, date: $date) {
          data {
            expense_category
            ans_register
            operator_name
            executor_cnes
            executor_cnpj_cpf
            executor_name
            executor_ibge_city_code
            executor_city_name
            recipient_code
            recipient_name
            cns_number
            plan_register
            plan_name
            authorization_type_guide_model
            date_hospitalization_service
            discharge_date
            appointment_type
            executor_cbo_code
            executor_cbo_description
            service_caracter
            hospitalization_type
            attendance_type
            companion_daily_quantity
            uti_daily_quantity
            discharge_reason
            procedure_group
            procedure_code
            procedure_description
            tooth_identification
            tooth_face_identification
            procedures_quantity
            peg_receivement_date
            remittance_letter
            peg
            guide
            authorization
            event_degree
            event
            attendance_date
            quantity_paid
            value_paid
          }
        }
      }
    }
  }
`
