import { takeLatest, call, put } from 'redux-saga/effects'
import * as constants from 'store/constants'
import api from './api'

function* getReadjustmentStatement({ payload }) {
    try {
        yield put({ type: constants.READJUSTMENT_STATEMENT_LOADING, payload: true })

        const res = yield call(api.getReadjustmentStatement, payload)

        yield put({
            type: constants.READJUSTMENT_STATEMENT_DATA,
            payload: res,
        })

        yield put({ type: constants.READJUSTMENT_STATEMENT_LOADING, payload: false })
    } catch (e) {
        console.error(`${e.message}`)

        yield put({ type: constants.READJUSTMENT_STATEMENT_ERROR, payload: e.message })
        yield put({ type: constants.READJUSTMENT_STATEMENT_LOADING, payload: false })
    }
}

function* orderReadjustmentStatement({ payload }) {

    try {
        const res = yield call(api.orderReadjustmentStatement, payload)
        yield put({
            type: constants.READJUSTMENT_STATEMENT_DATA,
            payload: res,
        })

    } catch (e) {
        console.error(`${e.message}`)
        yield put({ type: constants.READJUSTMENT_STATEMENT_ERROR, payload: e.message })
    }
}

export default function* saga() {
    yield takeLatest(constants.GET_READJUSTMENT_STATEMENT, getReadjustmentStatement)
    yield takeLatest(constants.ORDER_READJUSTMENT_STATEMENT, orderReadjustmentStatement)
}