export default`
fragment ParagraphLinkWithIcon on ParagraphLinkWithIcon {
  id
  uuid
  entityBundle
  link {
    title
    url {
      path
    }
  }
  icon {
    entity {
      media {
        entity {
          ...Media
        }
      }
    }
  }
  linkType {
    entity {
      name
      token
      icon {
        entity {
          name
          media {
            entity {
              ...Media
            }
          }
        }
      }
    }
  }
}
`